import React, { useEffect, useState } from 'react';
import { Modal, Checkbox } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import { debounce } from 'lodash';
import { setSwapCourses } from '../../redux/slices/swapcourses';
import { triggerNotification, useGetApiRequests } from '../../common/CommonExport';
import { useLocation } from 'react-router-dom';

const AddCourseModal = (props: any) => {
  const { modalOpen, setAddCourseModal, selectedCourseList, setSelectedCourseList, mainArray, setMainArray, indexValue, certificateId, elmsPathDetails } = props;
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(modalOpen);
  const [loader, setLoader] = useState(false);
  const unassignedCourses = useSelector((state: any) => state?.courseList?.unassignedCourses);
  const recentlyAddedCourses = useSelector((state: any) => state?.courseList?.recentlyAddedCourses);
  const frequentlyAssignedCourses = useSelector((state: any) => state?.courseList?.frequentlyAssignedCourses);
  const [courseList, setCourseList] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<any>('');
  const [swapQuestion, setSwapQuestion] = useState<any>('');
  const learningLevelApi = useGetApiRequests('createLearningLevel', 'POST');
  const learningPathId: any = new URLSearchParams(search).get("learningPathId");
  const isEdit: any = new URLSearchParams(search).get("isEdit") === 'true';

  useEffect(() => {
    const totalCourseList = [...unassignedCourses, ...recentlyAddedCourses, ...frequentlyAssignedCourses];
    const extractedCourses = mainArray.reduce((accumulator: any, currentValue: any) => {
      accumulator.push(...currentValue.learningPathLevelCourse);
      return accumulator;
    }, []);
    const reformedCourseList = totalCourseList.filter(item => {
      // Check if the courseID of the item does not exist in extractedCourses
      return !extractedCourses.some(
        (extracted: any) => extracted?.course?.courseId === item?.courseID
      )
    }).map(item => ({
      value: item?.courseID,
      label: item?.courseName,
      name: item?.courseName,
      courseId: item?.courseID,
      checked: false,
      ...item
    }));

    setCourseList(reformedCourseList)
    if (searchTerm !== undefined) {
      const searchWord = searchTerm.toLowerCase();
      const filteredData = reformedCourseList.filter((record: any) => record.label.toLowerCase().includes(searchWord));
      setCourseList(filteredData)

    }
    dispatch(setSwapCourses([]))
  }, [searchTerm])

  const handelClose = () => {
    setIsOpenModal(false);
    setAddCourseModal(false);
  }
  const handleSelectCourseList = (item: any, index: any) => {
    const updatedCourseList = [...courseList];
    updatedCourseList[index].checked = !updatedCourseList[index].checked;
    const newCheckedCourses = updatedCourseList.filter((course) => course?.checked)
    setCourseList(updatedCourseList)
    setSwapQuestion(newCheckedCourses)
  };
  const handleSearchTermChange = debounce((serachName: any) => {
    setSearchTerm(serachName);
  }, 200);

  const handleCourseAdd = async () => {
    setSelectedCourseList(swapQuestion);
    setLoader(true);
    const levelNumber = indexValue + 1 < 10 ? `0${indexValue + 1}` : indexValue + 1
    const name: string = 'Level ' + levelNumber;
    const payload: any = {
      "levelName": name,
      "isLevelCertificateRequired": mainArray?.[indexValue]?.learningLevel?.isLevelCertificateRequired === true,
    }
    if (mainArray?.[indexValue]?.learningLevel?.isLevelCertificateRequired === true) {
      payload.certificate = { certificateId: certificateId }
    }

    await learningLevelApi(payload).then((res: any) => {
      if (res?.data?.data !== null) {
        const sampleArray = [...mainArray];
        // const lastIndexIs = sampleArray[sampleArray.length - 1]?.learningLevel?.learningLevelId === undefined ? 0 : sampleArray[sampleArray.length - 1]?.learningLevel?.learningLevelId
        const learningLevelId = res?.data?.data?.learningLevelId
        const initialPathLevel = {
          learningPathId: isEdit ? parseInt(learningPathId) : elmsPathDetails?.learningPathId
        }
        sampleArray[indexValue].learningLevel.learningLevelId = learningLevelId;
        sampleArray[indexValue].learningPath = initialPathLevel;
        if (swapQuestion && swapQuestion.length > 0) {
          if (mainArray[indexValue]?.learningPathLevelCourse.length === 0) {
            const newObjects = swapQuestion.map((item: any, index: number) => ({
              course: {
                ...item
              },
              courseHierarchy: index + 1
            }));
            sampleArray[indexValue].learningPathLevelCourse = newObjects;
            sampleArray[indexValue].levelHierarchy = swapQuestion.length;
            setLoader(false)
          } else {
            const newObjects = swapQuestion.map((item: any, index: number) => ({
              course: {
                ...item
              },
              courseHierarchy: sampleArray[indexValue].learningPathLevelCourse.length + index + 1
            }));
            newObjects.map((item: any) => {
              sampleArray[indexValue].learningPathLevelCourse.push(item)
            })
            sampleArray[indexValue].levelHierarchy = sampleArray[indexValue].learningPathLevelCourse.length;
            setLoader(false)
          }
          setMainArray(sampleArray)
          handelClose();
          setLoader(false);
        } else {
          setLoader(false)
          triggerNotification('warning', '', 'Please select atleast one course', 'topRight');
        }
      } else {
        setLoader(false);
        triggerNotification('error', '', res?.data?.message, 'topRight');
      }
    })

  }
  return (
    <div>
      <Modal title='Assign Course' className='employee-addcourse w-full employee_addCourse_modal' centered open={isOpenModal} footer={null} onCancel={handelClose} maskClosable={false} >
        <div className="px-6 pb-[25px]">
          <div className="search-box mt-6 mr-5 flex">
            <button className="search-btn mr-5">
              <span className="material-symbols-outlined" onChange={(e: any) => handleSearchTermChange(e)}>
                search
              </span>
            </button>
            <input className="search-txt  text-black" type="text" name="" value={searchTerm}
              placeholder="Search list here" onChange={(e: any) => handleSearchTermChange(e.target.value)} />
          </div>
          <div className='pt-4 '>
            <div>
              <p className='text-[22px] font-medium text-[#D34A7C] pb-5'>List of Course</p>
              <div className='LearningList max-h-[50vh] overflow-y-auto'>
                {courseList && courseList.length > 0 ? courseList.map((item: any, index: any) => (
                  <div key={item?.courseId}>
                    <ul>
                      <li>
                        <Checkbox checked={item?.checked} onChange={() => handleSelectCourseList(item, index)}></Checkbox>
                        {item?.courseName}

                      </li>
                    </ul>
                    <br />
                  </div>
                )) : (
                  <div>No Courses found</div>
                )}
              </div>
            </div>
            <div>
              <button className='h-12 addButton  primary px-5 confirmElms float-right' type="submit" onClick={() => handleCourseAdd()}>Add</button>
            </div>
          </div>
        </div>
      </Modal>
      {loader && <Loader />}
    </div>
  )
}
export default AddCourseModal