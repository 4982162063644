import React, { useEffect, useState } from 'react';
import ImagesComponent from '../../../components/Images/Images';
import birthdayImg from '../../../assets/images/new-dashboard/birthday.svg';
import celebration from '../../../assets/images/new-dashboard/celebration.svg';
import appraisalImg from '../../../assets/images/new-dashboard/appraisal.svg';
import probation from '../../../assets/images/new-dashboard/probation.svg';
import { getCurrentMonth, sortEventData } from '../DashBoardHelper';
import noRecordFound from '../../../assets/images/empty-folder.png'
import apprasialEmp from '../../../assets/images/employee/appraisal.svg'
import birthdayEmp from '../../../assets/images/employee/birthday.svg'
import celebrationEmp from '../../../assets/images/employee/celebration.svg'
import { useNavigate } from 'react-router-dom';
import { setAppraisalTab } from '../../../redux/slices/updateProfileSlice';
import { useDispatch } from 'react-redux';
import EventLoader from '../../../components/Loader/EventLoader';

function BirthDayAnniversary(props: any) {
    const imageBaseUrl = process.env.REACT_APP_IMAGE_VIEW;
    const { dashboardData, title } = props;
    const [employeeEvents, setEmployeeEvents] = useState<any>();
    const [currMonth, setCurrMonth] = useState<any>();
    const [loader, setLoader] = useState<any>(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        setCurrMonth(getCurrentMonth());
        setEmployeeEvents(sortEventData(dashboardData));
        setTimeout(() => {
            setLoader(false)
        }, 3500);
    }, []);

    const getImageForEvent = (status: any) => {
        if (status === 'Work Anniversary') return { mainImg: celebration, empImage: celebrationEmp };
        if (status === 'Birthday') return { mainImg: birthdayImg, empImage: birthdayEmp };
        if (status === 'Probation') return { mainImg: probation, empImage: probation };
        if (status === 'Appraisal') return { mainImg: appraisalImg, empImage: apprasialEmp };
    };
    const handleNavigateToAppraisal = (item: any) => {
        dispatch(setAppraisalTab(true));
        navigate(`/employee/details/${item?.employeeDashboardDto?.employeeId}?employeeTypeId=${item?.employeeDashboardDto?.employeeTypeId}&employeeName=${item?.employeeDashboardDto?.firstName} ${item?.employeeDashboardDto?.lastName}`);
    }

    const formatDate = (dateString: any) => {
        const date = new Date(dateString);
        const options: any = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(date).replace(' ', '-');
    };
    function findNearestDate(dates: any, currentDate: any) {
        const currentDateNum = new Date(currentDate).getDate();
        const datesNum = new Date(dates).getDate();
        const difference = datesNum - currentDateNum
        // if (difference === 1 || difference === 2) {
        const element: any = document.getElementById(currentDate);
        element?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        // }
    }
    const scroll = (element: any) => {
        setTimeout(() => {
            element?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }, 2000);
    }
    const renderEmployee = (item: any) => {
        const imageSet: any = getImageForEvent(item?.employeeEvent)
        return (
            <div className={`birth-anniversary__list-cont ${item?.employeeEvent === 'Appraisal' || item?.employeeEvent === 'Probation' ? "cursor-pointer hover-text" : ""}`} onClick={() => {
                if (item?.employeeEvent === 'Appraisal' || item?.employeeEvent === 'Probation') {
                    handleNavigateToAppraisal(item)
                }
            }}>

                <div className='birth-anniversary__profile-img flex justify-center items-center'>
                    <ImagesComponent alt='profile' src={item?.employeeDashboardDto?.profileUrl !== null ?
                        `${imageBaseUrl}${item?.employeeDashboardDto?.profileUrl}`
                        : imageSet?.empImage} className='logo' />
                </div>
                <div className=' birth-anniversary__emp-hooray flex'>
                    <div className={`flex items-center ${item?.employeeEvent === 'Appraisal' || item?.employeeEvent === 'Probation' ? 'cursor-pointer' : ''}`}>
                        <img src={imageSet?.mainImg} alt='cake' className='img-fluid' />
                        <span data-testid='employee-name' className='employee-name' >{item?.employeeEvent}</span>
                    </div>
                    <span className={`emp-name ${item?.employeeEvent === 'Appraisal' || item?.employeeEvent === 'Probation' ? 'cursor-pointer' : ''}`}>
                        <p>{item?.employeeDashboardDto?.firstName}</p>
                    </span>
                </div>

            </div>
        )
    }

    return (
        <div className='birth-anniversary relative'>
            {/* {loader && <EventLoader />} */}
            <div className='birth-anniversary__tab flex justify-between relative top-4'>
                <div className='card-title justify-start'>
                    <h4>{title}</h4>
                </div>
                <div className='expand expand-icon justify-end text-primary'>
                    {/* <span className='material-symbols-outlined text-lg'>open_in_full</span> */}
                </div>
            </div>
            {
                employeeEvents?.length > 0 ? (
                    <div className=' overflow-y-auto pt-6 mt-5'>
                        <ul className='birth-anniversary__timeline' id="anniversary">
                            {employeeEvents?.map((item: any, index: any) => {
                                const preDate = new Date(employeeEvents[index - 1]?.date)?.getDate();
                                const currDate = new Date(item?.date)?.getDate();
                                const activateItem = formatDate(item?.date);
                                const activeDate: any = activateItem.split(',')[0];
                                return (
                                    <li key={`${item?.employeeDashboardDto?.employeeId}+${index + 1}`} className='birth-anniversary__list relative' id={activeDate}>
                                        <div>{renderEmployee(item)}</div>
                                        {currDate !== preDate && (
                                            <div>
                                                <span className='birth-anniversary__date absolute items-center flex justify-center '>{currDate}</span>
                                                <span className='birth-anniversary__month absolute'>{currMonth} </span>
                                            </div>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                ) : (
                    <div className="no-record salary">
                        <p className='flex justify-center items-center text-xl'> <img src={noRecordFound} alt="" />
                        </p>
                    </div>
                )
            }
        </div >
    );
}

export default BirthDayAnniversary;
