import React, { useState, useEffect } from 'react';
import { notification, Upload, Modal } from 'antd';
import { UploadFile, RcFile } from 'antd/lib/upload/interface';

export const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });

const ImageUpload = ({
    imageUrl,
    onImageUpload,
    onImageDelete,
    maxFileSize, // Maximum file size in bytes
    allowedTypes, // An array of allowed file types, e.g., ['image/jpeg', 'image/png', 'application/pdf']
    multiple = false,
    allowedWidth,
    allowedHeight
}: {
    imageUrl?: string;
    onImageUpload: (file: File) => void;
    onImageDelete: (file: File) => void;
    maxFileSize: number;
    allowedTypes: string[];
    multiple?: boolean;
    allowedWidth?: number;
    allowedHeight?: number;
}) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [fileSizeExceeded, setFileSizeExceeded] = useState(false);

    useEffect(() => {
        if (imageUrl) {
            setFileList([
                {
                    uid: '-1',
                    name: 'Image',
                    status: 'done',
                    url: imageUrl,
                },
            ]);
        }
    }, [imageUrl]);

    const handleCancel = () => setPreviewOpen(false);

    const handleRemove = (file: UploadFile) => {
        const updatedFileList = fileList.filter(item => item.uid !== file.uid);
        setFileList(updatedFileList);
        onImageDelete(file.originFileObj as File);
    };

    const customRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
            onImageUpload(file);
        }, 1000);
    };
    return (
        <>
            <Upload
                customRequest={customRequest}
                listType='picture-card'
                fileList={fileList}
                onPreview={(e) => handlePreview(e, setPreviewImage, setPreviewOpen, setPreviewTitle)}
                onChange={(e) => handleChange(e, maxFileSize, setFileSizeExceeded, allowedTypes,
                    setFileList, onImageUpload, multiple, allowedWidth, allowedHeight)}
                onRemove={handleRemove}
                multiple={multiple}
            >
                {!multiple && fileList.length >= 1 ? null : (
                    <div className='image-upload justify-center items-center flex-wrap p-4'>
                        <div className='upload-document-image'>
                            <p className='flex items-center flex-col'><span className="material-symbols-outlined">
                                cloud_upload
                            </span><span className='ml-2'> Browse to Select files or Drag & Drop here </span></p>
                        </div>
                        {allowedTypes[0] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? (
                            <p> Only xlsx files. {`${maxFileSize / 1048576}`} MB max file size</p>
                        ) : (
                            <div><p> Only {`${allowedTypes.map(type => type.split('/')[1].toUpperCase())}`} files. {`${maxFileSize / 1048576}`} MB max file size</p>
                                {allowedWidth && (<p>  Accepted dimensions: {allowedWidth} width and {allowedHeight} height.</p>)}  </div>)}
                    </div>
                )}
            </Upload >
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel} maskClosable={false}>
                <img alt='example' style={{ width: '100%' }} src={previewImage} />
            </Modal>
            {
                fileSizeExceeded && (
                    <div className="file-size-warning">
                        <p className='error-message'>File size exceeds the maximum allowed size of {maxFileSize / (1024 * 1024)} MB.</p>
                    </div>
                )
            }
        </>
    );
};
export const handlePreview = async (file: UploadFile, setPreviewImage: any, setPreviewOpen: any, setPreviewTitle: any) => {
    if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url ?? (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
};

export const handleChange = async (
    { fileList: newFileList }: { fileList: UploadFile[] },
    maxFileSize: number,
    setFileSizeExceeded: (state: boolean) => void,
    allowedTypes: any,
    setFileList: (fileList: UploadFile[]) => void,
    onImageUpload: (file: File) => void,
    multiple: boolean,
    allowedWidth?: number,
    allowedHeight?: number
) => {
    const filteredFileList: UploadFile[] = [];
    for (const file of newFileList) {
        const fileSize: any = file?.originFileObj?.size;

        // Check file size
        if (fileSize === null || fileSize > maxFileSize) {
            setFileSizeExceeded(true);
            continue;
        } else {
            setFileSizeExceeded(false);
        }

        // Check file type
        if (!allowedTypes.includes(file?.type)) {
            const typeNames = allowedTypes.map((type: any) => type.split('/')[1].toUpperCase());
            notification.error({
                message: 'Invalid File Type',
                description: `Only ${typeNames.join(', ')} files are allowed.`,
            });
            continue;
        }

        // Check dimensions if specified
        if (allowedWidth || allowedHeight) {
            const filePreview = await getBase64(file.originFileObj as RcFile);
            const img = new Image();

            try {
                await new Promise((resolve, reject) => {
                    img.onload = () => {
                        const isValidWidth = allowedWidth ? img.width === allowedWidth : true;
                        const isValidHeight = allowedHeight ? img.height === allowedHeight : true;

                        if (isValidWidth && isValidHeight) {
                            resolve(true);
                        } else {
                            notification.error({
                                message: 'Invalid Image Dimensions',
                                description: `Images must be ${allowedWidth || 'any'}px width and ${allowedHeight || 'any'}px height.`,
                            });
                            reject(false);
                        }
                    };
                    img.onerror = reject;
                    img.src = filePreview;
                });
            } catch {
                continue; // Skip invalid files
            }
        }

        // Add valid file to the filtered list
        filteredFileList.push(file);
    }

    // Update the file list state
    setFileList(filteredFileList);

    setFileList(filteredFileList);
    if (filteredFileList.length > 0) {
        if (multiple) onImageUpload(filteredFileList as any);
        else onImageUpload(filteredFileList[0]?.originFileObj as File);
    }
};

export default ImageUpload;
