import React from 'react';
import { Avatar, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import setFilter from "../../assets/images/set-filter.png"


const LeaveReportTable = ({ leavePolicyReports, isSubmit, loader }: any) => {
  const getReportLeaveType = (name: string): string => {
    const words = name?.split(' ');
    if (words?.length >= 2) {
      const firstNameInitial = words[0][0].toUpperCase();
      const lastNameInitial = words[words?.length - 1][0].toUpperCase();
      return `${firstNameInitial}${lastNameInitial}`;
    } else if (words?.length === 1) {
      return words[0][0].toUpperCase();
    } else {
      return '';
    }
  };
  const columns: ColumnsType<any> = [
    {
      title: 'Leave Types',
      dataIndex: 'policyType',
      width: "12%",
      render: (text) => (
        <div className='flex items-center leave-type'>   <Avatar.Group
          maxCount={3}
          maxPopoverTrigger="click"
          size="large"
          maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}
        >
          <Avatar className={"leave-type-" + text} >{getReportLeaveType(text)}</Avatar>
        </Avatar.Group>
          <p className='ml-2'>{text}</p>
        </div>
      ),
    },
    {
      title: 'Employee Name',
      dataIndex: 'employeeName',
      width: "12%",
    },
    {
      title: 'From ',
      dataIndex: 'formattedFromDate',
      width: "9%",

    },
    {
      title: 'To ',
      dataIndex: 'formattedToDate',
      width: "9%",
    },
    {
      title: 'Duration ',
      dataIndex: 'duration',
      width: "9%",
      render: (text, record) => (
        <div className='flex space-x-2'>
          <p className='ml-2'>{text}</p>
          {record.lopCount > 0 && (
            <p className='text-red-400 text-[13px]'>(LOP-<span>{record.lopCount}</span>)</p>
          )}
        </div>
      ),
    },
    {
      title: 'Status ',
      dataIndex: 'status',
      width: "9%",

    },
    {
      title: 'Reason ',
      dataIndex: 'reason',
      width: "14%",
    },]
  const customNoDataMessage = () => {
    return <div className="absolute bottom-[-100%] left-[-37px] w-[25vw]">
      <div className='relative'>
        <img src={setFilter} alt="setFilter" />
        <p className='filter-section font-rubik text-[16px]'>Set filter to view and download the report</p>
      </div>
    </div>;
  };

  return (
    <div className='leave-report leave-table head relative'>
      {!isSubmit ? (
        <Table columns={columns} dataSource={leavePolicyReports} locale={{ emptyText: customNoDataMessage }} loading={loader} />) :
        (<Table columns={columns} dataSource={leavePolicyReports} loading={loader} />)}


    </div>
  )
}

export default LeaveReportTable