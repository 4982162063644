import DOMPurify from "dompurify";
import moment from 'moment';

// Format date to check if it's today, yesterday, or show the full date
export const formatDate = (isoDate: string): string => {
  const date = new Date(isoDate);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return 'Today';
  }

  if (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  ) {
    return 'Yesterday';
  }

  const day = String(date.getUTCDate()).padStart(2, '0'); // UTC day
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // UTC month (0-indexed)
  const year = date.getUTCFullYear(); // UTC year

  return `${day}-${month}-${year}`;
};

// Format time to UTC
export const formatTimeUTC = (isoDate?: string) => {
  if (!isoDate) return '';
  const date = new Date(isoDate);
  return date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'UTC',
  });
};
// Sanitize email body
export const getBodyData = (data: string) => {
  const sanitizedBody = data ? DOMPurify.sanitize(data) : '';
  return sanitizedBody;
};
// Function to adjust and format time
export const formatTime = (serverTime: string, format: string = 'DD-MM-YYYY hh:mm A'): string => {
  const localTime = moment(serverTime).utcOffset(330); // Adjust for UTC+5:30
  const now = moment().utcOffset(330);
  const yesterday = moment().utcOffset(330).subtract(1, 'day');

  // If the time is today
  if (localTime.isSame(now, 'day')) {
    return `Today, ${localTime.format('hh:mm A')}`; // Format as "Today, HH:mm AM/PM"
  }

  // If the time was yesterday
  if (localTime.isSame(yesterday, 'day')) {
    return `Yesterday, ${localTime.format('hh:mm A')}`; // Format as "Yesterday, HH:mm AM/PM"
  }

  // For any other time, return formatted date with time
  return localTime.format(format); // Format as "DD-MM-YYYY hh:mm AM/PM"
};
// Render file preview based on file extension
export const renderFilePreview = (fileExtension: string, filePath: string, fileName: string) => {
  fileExtension = fileExtension?.toLowerCase();
  if (['jpg', 'jpeg', 'png', 'gif', 'ico'].includes(fileExtension)) {
    return (
      <img
        src={filePath}
        alt={fileName}
        className="w-[100px] h-[100px] object-cover rounded-md"
        onError={(e) => e.currentTarget.src = 'path_to_junk_image_placeholder'}
      />
    );
  } else if (fileExtension === 'pdf') {
    return (
      <div className="flex items-center justify-center w-[100px] h-[100px] bg-red-100 rounded-md">
        <span className="material-symbols-outlined text-red-500 text-5xl">picture_as_pdf</span>
      </div>
    );
  } else if (['doc', 'docx'].includes(fileExtension)) {
    return (
      <div className="flex items-center justify-center w-[100px] h-[100px] bg-blue-100 rounded-md">
        <span className="material-symbols-outlined text-blue-500 text-5xl">description</span>
      </div>
    );
  } else if (['xls', 'xlsx'].includes(fileExtension)) {
    return (
      <div className="flex items-center justify-center w-[100px] h-[100px] bg-green-100 rounded-md">
        <span className="material-symbols-outlined text-green-500 text-5xl">table_chart</span>
      </div>
    );
  } else {
    return (
      <div className="flex items-center justify-center w-[100px] h-[100px] bg-gray-100 rounded-md">
        <span className="material-symbols-outlined text-gray-500 text-5xl">attachment</span>
        <div className="text-xs text-gray-600 mt-2">Unsupported file</div>
      </div>
    );
  }
};

export const AnnouncementHeader = ({ announcementData, handleNavigate }: { announcementData: any, handleNavigate: Function }) => {
  return (
    <div className='singleAnnouncement--header'>
      <div className=' flex justify-between items-center'>
        <h4 className='flex items-center'>
          <span className="material-symbols-outlined pr-4 cursor-pointer" onClick={() => handleNavigate()}>
            keyboard_backspace
          </span>
          <span className='singleAnnouncement--header--title'>Announcement</span>
        </h4>
        <div className=' mr-5'>
          {announcementData?.status === 'SCHEDULED' && (
            <>
              <div className=' flex flex-row-reverse'>
                <p className=' text-[18px] font-rubik text-[#707070]'>Scheduled on</p>
              </div>
              <div className=' flex items-center space-x-1'>
                <span className="material-symbols-outlined text-[#D34A7C] font-light">calendar_clock</span>
                <p className="dateStyle">
                  {announcementData?.scheduledTime && formatTime(announcementData.scheduledTime)}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export const AnnouncementActions = ({ announcementData, handleEditComposeMail, handleSendNow }: any) => {
  return (
    <div className='flex flex-row-reverse bg-[#F5F5F5]'>
      {announcementData?.isActive && announcementData?.status && (
        (announcementData.status === 'DRAFT' || announcementData.status === 'SCHEDULED') && (
          <div className='flex justify-end mb-7 mt-1 mr-8'>
            <button
              className='cursor-pointer text-[#D34A7C] bg-[#FFF5F9] border border-[#D23B7D] w-[142px] h-[45px] rounded-3xl text-[18px] mr-4 secondary hover:opacity-60 hover:transition-opacity'
              onClick={handleEditComposeMail}>
              Edit
            </button>
            <button
              className='cursor-pointer text-white bg-[#D23B7D] w-[142px] h-[45px] rounded-3xl text-[17px] hover:opacity-60 hover:transition-opacity'
              onClick={handleSendNow}>
              Send Now
            </button>
          </div>
        )
      )}
    </div>
  );
};