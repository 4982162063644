import React from 'react';
import ManageLeaveTable from './ManageLeaveTable';

interface ManageLeaveProps {
  searchQuery: string;
}

export default function ManageLeave({ searchQuery }: ManageLeaveProps) {
  return (
    <div>
      <ManageLeaveTable searchQuery={searchQuery} />
    </div>
  );
}
