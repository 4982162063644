import React, { useEffect, useState } from 'react'
import AddComponentModal from './AddComponentModal';
import SalaryDeleteModal from '../SalaryDeleteModal';
import { storeData } from '../../../storage/Storage';
import { Tooltip } from 'antd';

export default function SalaryParticulars(salaryStructure: any) {
  const [modalOpen, setModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [salaryParticulars, setSalaryParticulars] = useState<any>([]);
  const [editParticulars, setEditParticulars] = useState<any>([]);
  const [groupName, setgroupName] = useState<any>([]);


  useEffect(() => {
    const getData = salaryStructure.salaryStructure
    if (getData) {
      setSalaryParticulars(getData)

      setLoader(true)
    }
  }, [salaryStructure])
  const handleAdd = (item: any) => {
    if (item.employeeTypeId) {
      setEditParticulars(item?.salaryComponent)
    }
    else {
      setEditParticulars(item)
    }
    setModalOpen(true)

  }
  const handleClose = () => {
    setModalOpen(false);
  }
  const handleClosed = () => {
    setIsOpen(false);
  }
  const handleDelete = (item: any) => {
    if (item.employeeTypeId) {
      setEditParticulars(item?.salaryComponent)
    }
    else {
      setEditParticulars(item)
    }
    setIsOpen(true)
  }
  const handleAddParticular = (elm: any) => {
    setModalOpen(true)
    setgroupName(elm)


    storeData("iSnewItem", true, true)
  }

  return (
    <>
      {loader && editParticulars ?
        <div className='flex justify-between bg-white p-8 salaryStructure_bg'>
          <div>
            <div><p className='text-primary'>{salaryParticulars[0]?.groupName ? salaryParticulars[0]?.groupName : salaryParticulars[0]?.salaryComponent?.groupName}</p></div>
            <ul className='salaryStructure_list'>
              {salaryParticulars.map((item: any, index: any) => (
                <li key={`${index + 1}`}>{item.name ? item.name : item.salaryComponent.name}</li>
              ))}
            </ul>
          </div>
          <div>
            <div>
              <div className='text-end'>
                <p className='text-primary  font-bold '>
                  <Tooltip title='Add item'>
                    <span className='cursor-pointer' onClick={() => { handleAddParticular(salaryParticulars[0]?.groupName ? salaryParticulars[0]?.groupName : salaryParticulars[0]?.salaryComponent?.groupName) }}> Add item +</span>
                  </Tooltip>
                </p>

              </div>
            </div>
            <ul className='salaryStructure_value'>
              {salaryParticulars.map((item: any, index: any) => (
                <li key={`${index + 1}`} className="flex">{item?.salaryComponent ? <span className='salary-value p-2 mr-3'> {item.salaryComponent.amount != 0 ? item.salaryComponent.amount : item.salaryComponent.percent + "%"} </span> : <span className='salary-value p-2 mr-3'> {(item.percent && item.percent !== 0) ? `${item.percent}  ${item.type}` : `${item.amount} ${item.type}`}
                </span>}
                  <Tooltip placement="top" title="Edit">
                    <span className=" mr-3 material-symbols-outlined cursor-pointer" onClick={() => { handleAdd(item) }}> edit </span>
                  </Tooltip>
                  <Tooltip placement="top" title="Delete">
                    <span className="material-symbols-outlined cursor-pointer" onClick={() => { handleDelete(item) }}>delete </span>
                  </Tooltip>
                </li>
              ))}
            </ul>
          </div>
          {modalOpen && <AddComponentModal open={modalOpen} handleClose={handleClose} editParticulars={editParticulars} groupName={groupName} />}
          {isOpen && <SalaryDeleteModal open={isOpen} handleClosed={handleClosed} editParticulars={editParticulars} />}
        </div >
        :
        null
      }
    </>
  )
}
