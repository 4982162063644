import { Button, Modal } from 'antd'
import React, { useEffect, useState } from 'react'

const ThankyouModal = (props: any) => {
  const { thankyou, handelClose } = props;
  const [isOpenModal, setisOpenModal] = useState(true)
  useEffect(() => {
    setisOpenModal(thankyou)
  }, [props])

  const handelClosed = () => {
    setisOpenModal(false)
    handelClose()

  }
  return (
    // <Modal className=' w-[600px]' centered open={true} footer={null} onCancel={handelClosed} maskClosable={false} >
    //   <h2 className='text-center text-[40px] font-bold pb-5'>Thank You!</h2>
    //   <p className='text-center text-[20px] pb-4' > Thank you for reaching out. We have  received your inquiry.</p>
    //   <span className='text-center text-[18px] block pb-8'>One of our experts will get in touch with you shortly.</span>
    // </Modal>

    <Modal className=' w-[600px]' centered open={true} footer={null} onCancel={handelClosed} maskClosable={false} >
      <h2 className='text-center text-[40px] font-bold pb-5'>Thank You!</h2>

      <span className='text-center text-[18px] block pb-8'>You have successfully submitted the exit interview</span>
      <Button>Default Button</Button>

    </Modal>
  )
}

export default ThankyouModal
