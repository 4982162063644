import { Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useEffect, useState } from 'react'
import { retrieveData, triggerNotification, useGetApiRequests } from '../../../../../../../common/CommonExport';
import { useSelector } from 'react-redux';
import ModalLoader from '../../../../../../../components/Loader/ModalLoader';

export default function ExitFormModalForm() {
  const getQuestions = useGetApiRequests('getExitFormFillDetails', 'GET');

  const employeeId = retrieveData("currentEmployeeId", true);
  const [loader, setLoader] = useState<boolean>(false);
  const [exitFormFillDetails, setExitFormFillDetails] = useState([])

  useEffect(() => {
    getExitFormFillDetails();
  }, []);

  const getExitFormFillDetails = async () => {
    setLoader(true);
    const pathParams = {
      employeeId: `${employeeId}/exit-interview`
    };
    await getQuestions('', {}, pathParams)
      .then((response: any) => {
        const data = response?.data?.data;

        const exitInterviewAttributes = data?.performanceQuestionsList

        setExitFormFillDetails(exitInterviewAttributes)
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
        triggerNotification('error', '', err?.message, 'topRight');
      });
  }
  return (

    <div className="salary-date review-modal">
      {loader && <ModalLoader />}
      <div className='grid grid-cols-3 gap-x-[7.5rem] gap-y-4 mt-4 w-full form-item'>
        <label>Status Type
          <Input value="Exit Interview Form" disabled />
        </label>
      </div>
      <h4 className='general-assessment-subtitle py-4'>Exit Interview Form Review</h4>

      <div className="appraisal-comments mb-5 mr-3">
        <div className="grid grid-cols-2 gap-5 ">
          {
            exitFormFillDetails?.length > 0 && exitFormFillDetails.map((item: any) => (
              <div className="appraisal-comment">
                <h3 className='general-assessment-header py-4 exitForm'>{item.question}</h3>
                <TextArea disabled value={item.answer} rows={3} style={{ resize: 'none' }}
                />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}
