import { Button, Tooltip } from 'antd'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { triggerNotification, useGetApiRequests } from '../../../../../../common/CommonExport';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DocumentPDFView from './documentPDFView';
import DeleteConfirmationModal from '../../../../../Organization/DeleteConfirmationModal';
import UploadEmployeeDocument from './uploadEmployeeDocument';
import Loader from '../../../../../../components/Loader/Loader';
import noRecordFound from "../../../../../../assets/images/empty-folder.png"
import ModalLoader from '../../../../../../components/Loader/ModalLoader';

export default function Documents() {
  const { id }: any = useParams();
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const reduxToken = useSelector((state: any) => state?.authToken?.token);
  const documentReload = useSelector((state: any) => state?.profileReload?.documentReload);
  const employeeDocumentList = useGetApiRequests('documentStatus', 'GET');
  const employeeDocumentDelete = useGetApiRequests('documentDownload', 'DELETE');
  const employeeDocumentUpload = useGetApiRequests('documentUpload', 'POST');
  const [documentDetails, setDocumentDetails] = useState<any>()
  const [documentTypeDetails, setDocumentTypeDetails] = useState<any>()
  const [documentView, setDocumentView] = useState<any>(false)
  const [currentPdfDetails, setCurrentPdfDetails] = useState<any>({})
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [uploadDocument, setUploadDocument] = useState<boolean>(false);
  const [loader, setLoader] = useState<any>(false)
  const baseUrl = process.env.REACT_APP_GOOGLR_DOCUMENT_VIEW;

  const getDocumentApiData = async () => {
    setLoader(true)
    const pathParam = { id, end: 'document/list' }
    const pathParamsList = { id, end: 'documentDetail' }
    try {
      const [documentDetailsData, documentTypeDetailsData]: any = await Promise.all([
        employeeDocumentList('', { organisationId: orgId }, pathParam),
        employeeDocumentList('', { sessionToken: reduxToken }, pathParamsList)
      ]);
      setDocumentDetails(documentDetailsData?.data?.data)
      setDocumentTypeDetails(documentTypeDetailsData?.data?.data)
      setTimeout(() => {
        setLoader(false)
      }, 1000)
    } catch (error: any) {
      setTimeout(() => {
        setLoader(false)
      }, 1000)
      triggerNotification('error', '', error?.data?.message, 'topRight');
    }
  };
  useEffect(() => {
    getDocumentApiData()
  }, [documentReload])

  const finalSubmit = (formData: any, documentTypeId: any) => {
    const documentDto = { documentDto: { documentTypeId: documentTypeId, employeeId: parseInt(id), organisationId: orgId } };
    const headers = { 'Content-Type': 'multipart/form-data' };
    employeeDocumentUpload(formData, documentDto, {}, headers)
      .then((res: any) => {
        triggerNotification('success', '', res?.data?.message, 'topRight');
        getDocumentApiData()
      })
      .catch((err: any) => {
        console.error(err);
      });
  }
  const getFileExtension = (filePath: string): string | null => {
    const match = filePath.match(/\.([^.]+)$/);
    return match ? match[1].toLowerCase() : null;
  };

  const handleViewDocument = (details: any) => {
    setLoader(true)
    const extension = getFileExtension(details?.documentPath)
    setCurrentPdfDetails(details)

    if (extension === 'pdf' && baseUrl && details?.documentPath) {


      setTimeout(() => {
        setLoader(false)

        window.open(`${baseUrl}${details?.documentPath}`, '_blank')


      }, 2000)
    } else {
      setTimeout(() => {
        setLoader(false)
      }, 1000)

      setDocumentView(true)
    }
  }
  const handleDeleteDocument = (details: any) => {
    setCurrentPdfDetails(details)
    setDeletePopUp(true)
    setDeleteMessage(`Are you sure want to delete ${details?.document?.folderName}`)
  }
  const handleDeleteSelected = () => {
    const pathParams = {
      id: currentPdfDetails?.documentDetailId,
    };
    employeeDocumentDelete('', {}, pathParams)
      .then((res: any) => {
        triggerNotification('success', '', res?.data?.message, 'topRight');
        getDocumentApiData()
      })
      .catch((err: any) => {
        console.error(err);
      });
  };
  const handleCloseDelete = () => setDeletePopUp(false);
  const handlehrImage = (event: ChangeEvent<HTMLInputElement>, item: any) => {
    const file: any = event.target.files?.[0];
    const formData = new FormData();
    formData.append('files', file);
    finalSubmit(formData, item?.documentTypeId)
  };
  return (
    <div className='documents md:flex justify-between flex-col'>
      {loader && <ModalLoader />}
      <div className='flex justify-end'> <button onClick={() => setUploadDocument(true)} className='addButton'>Upload Document</button></div>
      <div className='documents-container'>
        {documentDetails?.length > 0 ? (
          <>
            {documentDetails?.map((item: any, index: any) => (
              <div key={item?.documentTypeId} className="documents-item document mb-7">
                <div className="document-title mb-4">
                  <h3 className='font-rubik text-base'>{`${index + 1}. ${item?.folderName}`}</h3>
                </div>
                {documentTypeDetails?.map((innerItem: any, innerIndex: any) => (
                  <>
                    {innerItem?.document?.documentId === item?.documentId && (
                      <div>
                        <div key={innerItem?.document?.documentTypeId} className="document-view flex justify-between items-center md:max-w-xl w-full" >
                          <p className="document-fileName font-rubik">
                            {innerItem?.name}
                          </p>
                          <div className="document-action flex items-center">
                            <Tooltip placement="top" title={'View'}>
                              <div onClick={() => handleViewDocument(innerItem)} className="document-action__icon">
                                <span className="material-symbols-outlined">visibility</span>
                              </div>
                            </Tooltip>
                            <Tooltip placement="top" title={'Delete'}>
                              <div onClick={() => handleDeleteDocument(innerItem)} className="document-action__icon">
                                <span className="material-symbols-outlined">delete</span>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
                <div className="document-add">
                  <Button type="text" className='flex items-center text-primary font-rubik' icon={<span className="material-symbols-outlined"> add_circle </span>} size="large">
                    Add Files <input accept="image/*,application/pdf" type='file' className='absolute opacity-0' onChange={(event) => handlehrImage(event, item)} />
                  </Button>
                </div>

              </div>

            ))}
          </>
        ) : <div className="no-record flex justify-center items-center  no-record-documents absolute">
          <p className='flex justify-center items-center text-xl'> <img src={noRecordFound} alt="" />
          </p>
        </div>
        }

        {documentView && <DocumentPDFView open={documentView} setDocumentView={setDocumentView} currentPdfDetails={currentPdfDetails} certificate={false} mainPage={false} />}
        {deletePopUp && <DeleteConfirmationModal open={deletePopUp} handleDeleteSelected={handleDeleteSelected} handleCloseDelete={handleCloseDelete} type='Delete' deleteMessage={deleteMessage} />}
        {uploadDocument && <UploadEmployeeDocument open={uploadDocument} setUploadDocument={setUploadDocument} getDocumentApiData={getDocumentApiData} />}
      </div>
    </div>
  )
}
