import React, { useEffect, useState } from 'react'
import FinalAssessment from './FinalAssessment'
import CompletionCertificate from './CompletionCertificate'
import ChapterTable from '../assessment/chapter/ChapterTable'
import { useSelector, useDispatch } from 'react-redux'
import Loader from '../../../components/Loader/Loader'
import { useGetApiRequests } from '../../../common/CommonExport'
import { setCurrentTab } from '../../../redux/slices/courseSlice'
import { setAddChapterNewFlow, setFlowDirection } from '../../../redux/slices/currentCourseDetailSlics'
import CourseDetailsHeader from './CourseDetailsHeader'
import EditCourseDetails from './EditCourseDetails'
import "./Coursedetails.scss"
import EditChapterAndAssessmentModal from './EditChapterAndAssessmentModal'
import DeleteConfirmationModal from '../../Organization/DeleteConfirmationModal'
import { Tooltip } from 'antd'
import CourseDescription from './CourseDescription'

export default function CourseDetails() {
  const baseUrl = process.env.REACT_APP_ELMS_COURSE_LOGOURL;
  const dispatch = useDispatch()
  const currentCourseId = useSelector((state: any) => state?.currentCourse?.courseId);
  const chapterReLoad = useSelector((state: any) => state?.profileReload?.chapterLoad);
  const employeeDetail = useSelector((state: any) => state?.currentCourse?.courseDetails);
  const getCourseById = useGetApiRequests('getCourseById', 'GET');
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [loader, setLoader] = useState<any>(false)
  const [courseDetails, setCourseDetails] = useState<any>({})
  const deleteMessage = 'Course Is in Publish Mode Can not able to edit'
  const [deletePopUp, setDeletePopUp] = useState<boolean>(false);
  const [editCourseDetails, setEditCourseDetails] = useState<any>(false)
  const [description, setDescription] = useState<any>(false)


  const handelPopup = () => {
    setEditCourseDetails(true)
  }

  const handleCourseDetils = async () => {
    try {
      setLoader(true);
      const pathParams = { id: currentCourseId };
      const res: any = await getCourseById('', {}, pathParams);
      setLoader(false);
      setCourseDetails(res?.data?.data)
    } catch (err) {
      setLoader(false);
    }
  };

  const handleNewCreation = () => {
    if (employeeDetail?.courseStatus === 'publish') {
      setDeletePopUp(true)
    } else if (employeeDetail?.courseStatus !== 'publish') {
      dispatch(setAddChapterNewFlow(true))
      dispatch(setCurrentTab(6))
      setModalOpen(true)
    }
  }

  const handleClose = () => {
    dispatch(setAddChapterNewFlow(false))
    // dispatch(setFlowDirection(false))
    setModalOpen(false)
    setEditCourseDetails(false)
  }

  const handleCloseDelete = () => setDeletePopUp(false);

  const handleDeleteSelected = () => {
    handleCloseDelete()
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (currentCourseId !== 0) handleCourseDetils()
  }, [chapterReLoad])
  const handelReadMore = () => {
    setDescription(true)
  }
  const handelClose = () => {
    setDescription(false)

  }

  return (
    <div>
      {loader && <Loader />}
      <div className='page-container bg-customBg'>
        <CourseDetailsHeader />
        <div className='full-width-container course-details mt-5 pb-10'>
          <div className='pt-5'>
            <div className='courseDetailsTop-bg relative'>
              <div className='img px-12 pb-12 pt-9 flex items-center' >
                <div className='text-white w-5/12'>
                  <p className='w-[500px] absolute top-[-30px]'><img src={`${baseUrl}${courseDetails?.logoPath}`} width='30%' height='30%' alt='course' className='img-fluid rounded-md h-[90px]' /></p>
                  <h3 className=' text-[40px] font-bold mt-3 leading-[0.95]'>{courseDetails?.name}<span data-testid="addSpan" className="material-symbols-outlined cursor-pointer" onClick={handelPopup}>
                    edit
                  </span> </h3>

                  <p className=' mt-2 text-base course-description'>{courseDetails?.description}</p>
                  {courseDetails?.description?.length > 380 &&
                    <p className='text-primary underline cursor-pointer' onClick={handelReadMore}  >Read More</p>
                  }
                </div>
              </div>
            </div>
            <div className='grid grid-cols-4 gap-5 course_table'>
              <div className=' col-span-3'>
                <div className="add_btn flex justify-between  items-center rounded-md bg-white mt-[10px] pt-5 px-5">
                  <p className='text-[#D34A7C] text-[24px] font-medium'>Chapter</p>
                  <Tooltip placement="bottom" title="Add Chapter" >
                    <span data-testid="addSpan" className="material-symbols-outlined text-white p-1 bg-primary rounded-full cursor-pointer" onClick={() => handleNewCreation()}>
                      add
                    </span>
                  </Tooltip>
                </div>
                <div className="dashboard-table">
                  <ChapterTable />

                </div>
              </div>
              <div>
                <FinalAssessment />
                <CompletionCertificate />
              </div>
            </div>
            {editCourseDetails && <EditCourseDetails open={editCourseDetails} handleClose={handleClose} />}
            {modalOpen && <EditChapterAndAssessmentModal open={modalOpen} handleClose={handleClose} />}
          </div>
        </div>
      </div>
      {description && <CourseDescription description={courseDetails?.description} handelClose={handelClose} open={description} />}
      {deletePopUp && <DeleteConfirmationModal contentChange={true} open={deletePopUp} handleDeleteSelected={handleDeleteSelected} handleCloseDelete={handleCloseDelete} type='Delete' deleteMessage={deleteMessage} />}
    </div>
  )
}
