import React, { useState, useEffect } from 'react';
import { Button, Tooltip } from 'antd';
import MailLists from './layouts/mailList/MailLists';
import FilterForm from './layouts/filter/FilterForm';
import AnnouncementHOC from './layouts/Hoc/AnnouncementHOC';
import ComposeMailModal from './modal/ComposeMailModal';
import AnnouncementDeleteModal from './modal/AnnouncementDeleteModal';
import axios from 'axios';

import { useDispatch, useSelector, useGetApiRequests, triggerNotification } from '../../common/CommonExport';
import { announcementSliceRootState, clearAnnouncementIdList, fetchApiCall } from '../../redux/slices/announcementIdSlice';

import "./Announcements.scss";
import "./layouts/mailList/mail.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import composeMail from '../../assets/images/compose-email.png';
import AnnouncementSearch from './layouts/search/AnnouncementSearch';

interface FilterFormData {
  toEmails: string[] | [];
  subjects: string | null;
  selectedDate: Date | string | null
  // Add more fields based on your form's structure
}
const AnnouncementsTrash = () => {
  const [deletePopUp, setDeletePopUp] = useState<boolean>(false);
  const [singleDeletePopUp, setSingleDeletePopUp] = useState<boolean>(false);
  const [sendMail, setSendMail] = useState<boolean>(false)
  const [isActiveFilter, setIsActiveFilter] = useState<boolean>(false);
  const [searchAnnouncement, setSearchAnnouncement] = useState<string>('');
  const [filterFormData, setFilterFormData] = useState<FilterFormData | null>(null);
  const [markAll, setMarkAll] = useState<boolean>(false);

  const dispatch = useDispatch();
  const announcementSlice = useSelector((state: announcementSliceRootState) => state.announcementIdSlice);
  const { announcementIdList, isReload } = announcementSlice;

  const deleteAnnouncements = useGetApiRequests('deleteAnnouncement', 'DELETE');
  // Clear announcementIdList on component unmount

  useEffect(() => {
    return () => {
      dispatch(clearAnnouncementIdList());
    };
  }, []);

  const handleClickFilter = () => {
    setIsActiveFilter(!isActiveFilter)
  }
  const handleSendMail = () => {
    setSendMail(true)
  }
  const handleDelete = () => {
    const idsToDelete = announcementSlice?.deleteAll?.length > 0
      ? announcementSlice.deleteAll
      : announcementIdList;

    if (idsToDelete.length > 0) {
      deleteAnnouncements(idsToDelete, { deleteForever: true })
        .then((response) => {
          if (axios.isAxiosError(response)) {
            throw response;
          }
          handleSuccess(response?.data?.message);
        })
        .catch((err) => {
          handleError(err?.message);
        });
    }
  }
  const handleSuccess = (message: string) => {
    triggerNotification('success', '', message, 'topRight');
    setDeletePopUp(false);
    setSingleDeletePopUp(false);
    dispatch(fetchApiCall(!isReload));
    dispatch(clearAnnouncementIdList());
  };
  const handleError = (errorMessage: string) => {
    triggerNotification('error', '', errorMessage, 'topRight');
    setDeletePopUp(false);
    setSingleDeletePopUp(false);
  };
  const handleFormUpdate = (formData: FilterFormData, buttonType: string) => {
    if (buttonType === "search") {
      setFilterFormData(formData);
      setIsActiveFilter(false);
    }
  }
  const handleDeleteModalOpen = () => {
    setSingleDeletePopUp(true);
  }
  return (
    <>
      <div className='flex justify-between items-center'>
        <div className='announcement-search w-full relative'>
          <AnnouncementSearch setSearchAnnouncement={setSearchAnnouncement} />

          <div className='w-full announcement-search--filter'>
            <Tooltip title='Show search options'>
              <i className="fi fi-rr-settings-sliders cursor-pointer" onClick={handleClickFilter}></i>
            </Tooltip>
            {
              isActiveFilter && <FilterForm setIsActiveFilter={setIsActiveFilter} onFormUpdate={handleFormUpdate} />
            }
          </div>
        </div >
        <div className=' flex items-center space-x-3'>
          <div>
            {
              announcementIdList?.length > 0 ? (
                <div className=' flex space-x-4 items-center '>
                  <Tooltip>
                    <div className=' flex space-x-2 items-center cursor-pointer' onClick={() => { setMarkAll(!markAll) }}>
                      <span className="material-symbols-outlined cursor-pointer check-delete-all font-light">
                        mark_email_read
                      </span>
                      <p role='none' className=' cursor-pointer font-[300] text-[16px] pt-[2px] text-[#D23B7D] font-rubik'>{!markAll ? "Select All" : "Deselect All"}</p>
                    </div>
                  </Tooltip>
                  <Tooltip title='Delete'>
                    <Button
                      type="primary" shape="circle" size={'large'}
                      className='bulk-upload_dropdown flex justify-center'
                      onClick={handleDeleteModalOpen}>
                      <span className="material-symbols-outlined font-[300]">
                        delete
                      </span>
                    </Button>
                  </Tooltip>
                </div>
              ) : <></>
            }
          </div>
          <div className='compose-mail-icon'>
            <Tooltip title='Compose'>
              <button onClick={handleSendMail} className=' flex justify-center bg-[#d23b7d] composeMail-btn'>
                <img src={composeMail} alt="composeMail" className=' composeMail-icon' />
              </button>
            </Tooltip>
          </div>
        </div>
      </div >

      <div className='mailList mt-8'>
        <MailLists status={'TRASH'} searchAnnouncement={searchAnnouncement} filterFormData={filterFormData} markAll={markAll} />
      </div>

      {
        sendMail && <ComposeMailModal sendMail={sendMail} setSendMail={setSendMail} title='New Message' />
      }
      {
        deletePopUp && <AnnouncementDeleteModal
          deletePopUp={deletePopUp}
          setDeletePopUp={setDeletePopUp}
          type='Delete' handleDelete={handleDelete}
          deleteMessage={'delete this Announcements ? '}
          extraDeleteMessage={'This action cannot be undone.'} />
      }

      {
        singleDeletePopUp && <AnnouncementDeleteModal deletePopUp={singleDeletePopUp}
          setDeletePopUp={setSingleDeletePopUp}
          handleDelete={handleDelete}
          type='Delete'
          deleteMessage={markAll ? 'delete this Announcements?' : 'delete this Announcement?'}
          extraDeleteMessage={'This action cannot be undone.'} />
      }

    </>

  )
}

export default AnnouncementHOC(AnnouncementsTrash);