import React, { useEffect, useState } from 'react'
import { DataType } from './TableHelper';
import { Table, DatePicker } from 'antd';
import "./EmployeeKpi.scss"
import { triggerNotification, useGetApiRequests, useNavigate } from '../../common/CommonExport';
import Loader from '../../components/Loader/Loader';
import dayjs from 'dayjs';
import KpiTable from './KpiTable';
const { RangePicker } = DatePicker;

const EmployeeKpi = () => {
  const allEmployeeKpi = useGetApiRequests('allEmployeeKpi', 'GET');
  const [loader, setLoader] = useState<any>(false)
  const navigate = useNavigate();
  const initialData: DataType[] = [];
  const [tableData, setTableData] = useState(initialData);
  const formatDate = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const defaultStartDate = dayjs().subtract(1, 'month');
  const defaultEndDate = dayjs();
  const [selectedDates, setSelectedDates] = useState<any>([defaultStartDate, defaultEndDate]);

  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate);
    const oneMonthAgoDate = new Date();
    oneMonthAgoDate.setMonth(currentDate.getMonth() - 1);
    const formattedDateOneMonthAgo = formatDate(oneMonthAgoDate);
    employeesKpi(formattedDateOneMonthAgo, formattedDate);

  }, [])


  const employeesKpi = (startDate: any, endDate: any) => {
    setLoader(true)
    let queryParams = {};
    if (startDate) {
      queryParams = {
        "fromDate": startDate,
        "toDate": endDate,
      }
    }

    allEmployeeKpi({}, queryParams)
      .then((response: any) => {
        const employeeKpiKpr = response?.data?.data
        employeeKpiKpr.forEach((sprint: any, index: any) => {
          initialData.push({
            key: index,
            name: String(sprint.name),
            issueCount: Number(sprint?.issueCount),
            linkedBugCount: Number(sprint.linkedBugCount),
            totalOriginalEstimateHours: Number(sprint.totalOriginalEstimateHours.toFixed(2)),
            totalTimeSpentHours: Number(sprint.totalTimeSpentHours.toFixed(2)),
            linkedBugHours: Number((sprint.linkedBugHours + sprint.individualBugHours).toFixed(2)),
            jiraLinkIssuesIdCount: Number(sprint.jiraLinkIssuesIdCount),
            totalLinesOfCode: Number(sprint.totalLinesOfCode),
            bugs: Number(sprint.individualBugCount),
            codeSmells: Number(sprint.codeSmells),
            vulnerabilities: Number(sprint.vulnerabilities),
            securityHotspots: Number(sprint.securityHotspots),
            duplications: Number(sprint.duplications),
            sumOfCodeQualityIssues: Number(sprint.sumOfCodeQualityIssues),
            deviationPercentage: Number(sprint.deviationPercentage),
            bugsPercentage: Number(sprint.bugsPercentage),
            bugsScore: Number(sprint.bugsScore),
            timeDeviationScore: Number(sprint.timeDeviationScore),
            codeQualityScore: Number(sprint.codeQualityScore),
            employeeScore: Number(sprint.employeeTotalScorePercentage)
          })
          setTableData(initialData)
        })
        setTimeout(() => {
          setLoader(false)
        }, 1000)
      })
      .catch((err) => {
        setTimeout(() => {
          setLoader(false)
        }, 1000)
      });
  }
  const convertToFormattedDates = (dateStrings: any) => {
    return dateStrings.map((dateString: any) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    });
  };
  const handleStartDateChange = (e: any) => {
    setSelectedDates(e)
    const formattedDates = convertToFormattedDates(e);
    employeesKpi(formattedDates[0], formattedDates[1])
  }
  const handleRoutes = () => {
    navigate('/home/dashboard');
  }

  const percentageData = [
    { text: 'Percentage', },
    { text: '80-100%', },
    { text: '60-79%', },
    { text: '40-59%', },
    { text: '20-39%', },
  ];

  return (
    <>
      <div className='page-container bg-customBg attenance'>
        {loader && <Loader />}
        <div className=" flex justify-between pl-16 py-4" >
          <ul className='flex items-center'>
            <li className='text-[#878787] text-[16px] cursor-pointer hover:text-pink-500' onClick={handleRoutes}  >Dashboard / </li>
            <li className='text-[16px] pl-1 mr-1 text-[#ff4081] font-normal'>Employee Kpi</li>
          </ul>
        </div>
        <div className='full-width-container  employee-kpi_table'>
          <div className=' bg-white p-5 employee-table-container'>
            <div className="flex w-full justify-between">
              <div className="w-1/2 pb-4">
                <div className='card-title justify-start flex pb-5'>
                  <h4 className='px-5 font-medium'>   KPI   </h4>
                </div>
                <section className='projects-container project-board-details'>
                  <div className='employeeScore flex flex-nowrap'>
                    <ul className='flex'>
                      <li className='text-[#D23B7D] bg-[#FDF2F7] w-[135px] h-[28px] m-[5px] flex justify-center items-center'>Employee Score</li>
                      <li className='text-[#3DA101] bg-[#DFF8D1] w-[135px] h-[28px] m-[5px] flex justify-center items-center'>0-2</li>
                      <li className='text-[#C79F00] bg-[#FCEDB3] w-[135px] h-[28px] m-[5px] flex justify-center items-center'>3-5</li>
                      <li className='text-[#A15601] bg-[#FFCFA0] w-[135px] h-[28px] m-[5px] flex justify-center items-center'>6-8</li>
                      <li className='text-[#DB444B] bg-[#FFC3C6] w-[135px] h-[28px] m-[5px] flex justify-center items-center'>9-10</li>
                    </ul>
                  </div>
                  <div className='employeeScore flex flex-nowrap'>
                    <ul className='flex'>
                      {percentageData && percentageData.map((elm: any) => (
                        <li className='text-[#505050] bg-[#F3F3F3] w-[135px] h-[28px] m-[5px] flex justify-center items-center text-[14px]'>{elm.text}</li>
                      ))}

                    </ul>
                  </div>
                </section>

              </div>
              <div className="w-1/2 flex justify-end items-center">
                <p className='employee-kpi'><RangePicker defaultValue={selectedDates} onChange={(e) => handleStartDateChange(e)} /></p>

              </div>
            </div>
            {!loader &&
              <>
                <KpiTable tableData={tableData} />
              </>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default EmployeeKpi
