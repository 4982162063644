import React, { useState, useEffect } from 'react'
import { Button, Table, Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table';
import AddAttenanceModal from '../attenance/AddAttenanceModal';
import EditHoliday from './EditHoliday';
import DeleteConfirmationModal from '../../Organization/DeleteConfirmationModal';
import { triggerNotification, useGetApiRequests, useNavigate } from '../../../common/CommonExport';
import Loader from '../../../components/Loader/Loader';
import './Holiday.scss';
import { handleDeleteDesignation } from '../../Organization/OrganisationHelper';

const Holidays = () => {
  const navigate = useNavigate();
  const getHolidayListApi = useGetApiRequests('getHolidayList', "GET");
  const deleteHolidayApi = useGetApiRequests("deleteHoliday", "DELETE");
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [holidayList, setHolidayList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [holidayCount, setHolidayCount] = useState(0);
  const [editRecord, setEditRecord] = useState({})
  const [deleteMessage, setDeleteMessage] = useState('');
  const [isSingleDelete, setIsSingleDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState({})


  useEffect(() => {
    holidayApi()
  }, [])

  function getDayNameFromDate(dateString: any) {
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayName = days[dayOfWeek];

    return dayName;
  }
  const regularDateFormat = (input_value: any) => {
    const apiDate = new Date(input_value);
    const year = apiDate.getFullYear();
    const month = String(apiDate.getMonth() + 1).padStart(2, "0");
    const day = String(apiDate.getDate()).padStart(2, "0");

    const result = `${day}.${month}.${year}`;
    return result;
  }
  const holidayApi = () => {
    setLoader(true)
    getHolidayListApi()
      .then((response: any) => {
        const reformedPolicyList = response?.data?.data.map((item: any) => ({
          key: item.holidayDetailId,
          name: item.name,
          holidayOn: regularDateFormat(item.holidayOn),
          description: getDayNameFromDate(item.holidayOn),
          isActive: item.isActive,
        }));
        setHolidayList(reformedPolicyList);
        setHolidayCount(reformedPolicyList.length)
        setTimeout(() => {
          setLoader(false)
        }, 1000)
      })
      .catch((err: any) => {
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        triggerNotification('error', '', err?.message, 'topRight');
      });
  }
  const handelpopup = () => {
    setModalOpen(true);
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Holidays',
      dataIndex: 'name',
      width: "25%",
    },
    {
      title: 'Date',
      dataIndex: 'holidayOn',
      width: "25%",
    },
    {
      title: 'Day ',
      dataIndex: 'description',
      width: "30%",
      render: (_: any, record: any) => (
        <div className={"holidayDescription " + record.description}>
          {record.description}
        </div>
      )
    },
    {
      title: 'Action',
      dataIndex: 'age',
      width: "25%",
      render: (_: any, record: any) => (
        <div className='flex'>
          <Tooltip placement="top" title="Edit">
            <span className={"material-symbols-outlined mr-4 text-[18px] cursor-pointer hover:text-primary"} onClick={() => handleEditRow(record)} >edit</span>
          </Tooltip>
          <Tooltip placement="top" title="Delete">
            <span className="material-symbols-outlined mr-4 text-[18px] cursor-pointer hover:text-primary" onClick={() => handleSingleSelectDelete(record)}>delete</span>
          </Tooltip>
        </div>)
    },
  ]
  const handleEditRow = (data: any) => {
    setEditModalOpen(true);
    setEditRecord(data);
    setIsEdit(true)
  }
  const handleSingleSelectDelete = (record: any) => {
    setDeleteModal(true);
    setIsSingleDelete(true);
    const recordArray: any = [];
    recordArray.push(record);
    setSelectedRows(recordArray);
    setDeleteMessage(`Are you sure to delete ${record?.name} holiday?`);
  };
  const deleteHolidayApiCall = (record: any) => {
    const pathParams: any = {
      id: record?.key
    }
    setDeleteModal(false);
    handleDeleteDesignation(deleteHolidayApi, '', holidayApi, '', pathParams, 'elms');
  }
  const handleClose = () => {
    setModalOpen(false);
  }
  const handleClosepopup = () => {
    setEditModalOpen(false);
    setIsEdit(false)
  }
  const handleCloseDelete = () => {
    setDeleteModal(false);
  }
  const getRowClassName = (record: any) => {
    return record.key === '2' ? 'highlighted-row' : `${"highlighted" + " " + record.description}`;
  };
  const handleNavigation = () => {
    navigate('/home/dashboard');
  }

  return (

    <div className='page-container bg-customBg holiday'>
      <div className='full-width-container mt-5'>
        <div className=' bg-white p-5 h-full'>
          <div className=" flex justify-between">
            <div className='' >
              <ul className='flex'>
                <li className='text-[#878787] text-[16px] cursor-pointer hover:text-pink-500' onClick={handleNavigation}>Dashboard /</li>
                <li className='text-[16px] pl-1 text-[#ff4081] font-normal'>Holiday List</li>
              </ul>
              <div className="holiday-list flex mb-5 ">
                <p className='text-[#D34A7C] font-medium py-2 '>List of Holidays</p>
              </div>
            </div>
            <Tooltip placement='top' title='Add Holiday'>
              <div className='flex'>
                <Button type="primary" shape="circle" size={'large'} onClick={() => handelpopup()} className='mr-2'>
                  <span className="material-symbols-outlined"> add</span>
                </Button>
              </div>
            </Tooltip>
          </div>
          <div className="holiday-total h-10 flex items-center p-3 mb-5 bg-[#F5F5F5]">
            <p className='text-[#636363] font-medium  w-full'>Total number of holidays - <span>{holidayCount}</span></p>
          </div>
          <div className='leave-management holiday-management'>
            <Table columns={columns} dataSource={holidayList} rowClassName={getRowClassName} loading={loader} pagination={false} />
          </div>
        </div>
      </div>
      {modalOpen && <AddAttenanceModal open={modalOpen} handleClose={handleClose} holidayApi={holidayApi} />}
      {editModalOpen && <EditHoliday editRecord={editRecord} open={editModalOpen} handleClosepopup={handleClosepopup} isEdit={isEdit} holidayApi={holidayApi} />}
      {deleteModal && <DeleteConfirmationModal selectedRows={selectedRows} open={deleteModal} handleSingleSelectDelete={deleteHolidayApiCall} handleCloseDelete={handleCloseDelete} isSingleDelete={isSingleDelete} deleteMessage={deleteMessage} />}

      {/* {loader && <Loader />} */}
    </div>

  )
}
export default Holidays
