import React, { useEffect, useState } from 'react'
import { DatePicker, Input, Select } from 'antd'
import { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
const { RangePicker } = DatePicker;

const CourseFilter = (props: any) => {
  const { setEmployeeName, employeeName, setStartDate, setEndDate, setCourseCompletionStartDate, setCourseCompletionEndDate, setIsSubmit, setCertificateStatus, setCourseStatus, courseStatus, certificateStatus, isSubmit } = props;
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [pickerValue, setPickerValue] = useState<[Dayjs, Dayjs] | null>(null);
  const [courseCompletion, setCourseCompletion] = useState<[Dayjs, Dayjs] | null>(null);

  const currentTab = useSelector((state: any) => state?.courseFilterSlice?.eventData);
  useEffect(() => {
    setIsFilter(currentTab)
  }, [currentTab])

  const optionList = [
    { value: 'QP', label: 'Quiz Pending' },
    { value: 'IP', label: 'In Progress' },
    { value: 'YS', label: 'Yet to Start' },
    { value: 'CO', label: 'Completed' },
  ];
  const certificateStatusOptions = [
    { value: 'Downloaded', label: 'Downloaded' },
    { value: 'Not Downloaded', label: 'Not Downloaded' }

  ]
  const handleStateAndEndDate = (dates: [Dayjs, Dayjs] | null, dateStrings: [string, string]) => {
    const [startDate, endDate]: any = dates;
    setStartDate(startDate.format('YYYY-MM-DD'));
    setEndDate(endDate.format('YYYY-MM-DD'));
    setIsSubmit(false)

  };
  const handleCourseCompletion = (completionDate: [Dayjs, Dayjs] | null, completionDateStrings: [string, string]) => {
    const [startDate, endDate]: any = completionDate;
    setCourseCompletionStartDate(startDate.format('YYYY-MM-DD'));
    setCourseCompletionEndDate(endDate.format('YYYY-MM-DD'));
    setIsSubmit(false)

  };
  const handleRemoveData = () => {
    setPickerValue(null);
    setEmployeeName('');
    setCourseCompletion(null);
    setIsSubmit(!isSubmit);
    setCourseStatus('');
    setCertificateStatus('');
    setStartDate('');
    setEndDate('');
    setCourseCompletionStartDate('');
    setCourseCompletionEndDate('')
  };
  const handleApiCall = () => {
    setIsSubmit(!isSubmit);
  };
  const handleCourseStatus = (value: any, type: string) => {
    if (type === 'course') {
      setCourseStatus(value)
    } else {
      setCertificateStatus(value)
    }
  }
  return (
    <div className={`grid grid-cols-6 p-4 mt-4 leave-report-input bg-[#F5F5F5] progress-report gap-5  pt-4${isFilter ? '' : ' add-animation'}`}>
      <div >
        <p className='text-[#D34A7C] font-medium text-[14px] 2xl:text-[16px]' >Course/ Learning Path</p>
        <Input placeholder="Employee Name" value={employeeName} onChange={(e: any) => {
          setIsSubmit(false)
          setEmployeeName(e.target.value)
        }} />
      </div>
      <div>
        <p className='text-[#D34A7C] font-medium text-[14px] 2xl:text-[16px]'>Enrolled Date</p>
        <RangePicker data-testid="rangePickerEl" value={pickerValue} onChange={(dates: any, dateStrings: any) => {
          handleStateAndEndDate(dates, dateStrings);
          setPickerValue(dates);
        }} />
      </div>
      <div>
        <p className='text-[#D34A7C] font-medium text-[14px] 2xl:text-[16px]'>Course Completion Date</p>
        <RangePicker value={courseCompletion} onChange={(dates: any, dateStrings: any) => {
          handleCourseCompletion(dates, dateStrings);
          setCourseCompletion(dates);
        }} />
      </div>
      <div>
        <p className='text-[#D34A7C] font-medium text-[14px] 2xl:text-[16px]'>Course Status</p>
        <Select options={optionList} value={optionList.filter((item) => item?.value === courseStatus)} onChange={(value: any) => handleCourseStatus(value, 'course')} placeholder={'Select Status'}></Select>
      </div>
      <div>
        <p className='text-[#D34A7C] font-medium text-[14px] 2xl:text-[16px]'>Certificate Status</p>
        <Select options={certificateStatusOptions} placeholder={'Select Status'} value={certificateStatusOptions.filter((item) => item?.value === certificateStatus)} onChange={(value: any) => handleCourseStatus(value, 'certificate')}></Select>
      </div>
      <div className='flex items-center pt-3 mt-[10px] '>
        <div className=' mr-5'>
          <button type='submit' className='addButton  primary pr-4 justify-center' onClick={handleApiCall} >Submit</button>
        </div>
        <div >
          <button type='submit' className='addButton  secondary ' onClick={handleRemoveData} >Clear</button>
        </div>
      </div>
    </div>

  )
}

export default CourseFilter
