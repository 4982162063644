import React from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons';
import EditOrganisation from './EditOrgnization';
import { Link } from 'react-router-dom';

const Organisation = () => {
  return (
    <>
      <div className='page-container bg-customBg'>
        <div className="full-width-container edit-organization pt-4">
          <div className='edit-organization_header mb-7'>
            <h1 className=' organization_title flex'>
              <Link to='/home/organisation'>  <span className='organization_back-arrow pr-4 hover:text-black'><ArrowLeftOutlined />  </span> </Link>
              Edit Organisation
            </h1>
          </div>
          <div className='bg-white tab-container  w-full organisation_edit-form py-5 '>
            <EditOrganisation />
          </div>
        </div>
      </div>
    </>
  )
}

export default Organisation
