import onboard from "../../assets/images/logo.png"

export default function BasicSuccess() {
  return (
    <section className="approved-screen exitSuccess">
      <div className="loader-block">
      </div>
      <div className="header__logo" >
        <img src={onboard} alt="OnboardingLogo" />
        <span>ALL-Aboard!</span>
      </div>
      <div className="card">

        <p>
          <span className="material-symbols-outlined">check_circle</span>
        </p>
        <h2>Thank You!</h2>
        <p>You have successfully submitted the Basic Employee Information.</p>
      </div>

    </section >
  )
}
