import React, { useEffect, useState } from 'react'
import MailItem from './MailItem'
import { useGetApiRequests } from '../../../../common/CommonExport';
import { useSelector, useDispatch } from 'react-redux';
import noRecordFound from '../../../../assets/images/empty-folder.png';
import {
  EmailAnnouncement, ApiData, MailListsProps, OrganisationIdState,
  AnnouncementIdSliceState, EmailListItem, Payload, SearchEmailAnnouncementDto, FilterFormData
} from './mailListsInterface';
import axios, { AxiosResponse, AxiosError } from 'axios';
import Loader from '../../../../components/Loader/Loader';
import { addAnnouncementId, setDeleteAll } from '../../../../redux/slices/announcementIdSlice';

export default function MailLists({ status = 'all', searchAnnouncement, filterFormData, handleDeleteAll, deleteAll, markAll }: any) {

  const orgId = useSelector((state: OrganisationIdState) => state?.organisationId?.id);
  const isReload = useSelector((state: AnnouncementIdSliceState) => state?.announcementIdSlice?.isReload);
  const allEmailList = useGetApiRequests('allEmailList', 'POST');

  const [loader, setLoader] = useState<boolean>(true); // Loader is a boolean
  const [list, setList] = useState<EmailListItem[]>([]); // List is an array of EmailListItem objects
  // Replace `RootState` with your Redux state type
  const [filteredList, setFilteredList] = useState<EmailListItem[]>([]);
  const dispatch = useDispatch()
  //allEmailList
  useEffect(() => {
    init();
  }, [isReload]);

  useEffect(() => {
    // Whenever search term changes, filter the list
    if (searchAnnouncement) {
      getSearchAnnouncement(searchAnnouncement);
    } else {
      init();
    }
  }, [searchAnnouncement]);

  useEffect(() => {
    // Whenever search term changes, filter the list
    if (filterFormData) {
      handleFilter(filterFormData);
    }
  }, [filterFormData]);

  useEffect(() => {
    if (markAll) {
      const emailAnnouncementIds = list.map(item => item.emailAnnouncementId);
      dispatch(setDeleteAll(emailAnnouncementIds))
    } else {
    } dispatch(setDeleteAll([]))

  }, [markAll])
  const getSearchAnnouncement = (search: string | null) => {
    try {
      // If search term is null or empty, call init function
      if (!search) {
        init(); // Call init to fetch the full list
        return;
      }
      const isEmail = (value: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
      };
      // Prepare filter data
      const filterData: FilterFormData = {
        toEmails: isEmail(search) ? [search] : [],
        subjects: isEmail(search) ? null : search,
        selectedDate: null, // Add a value here if you want to filter by date
      };
      // Call the handleFilter function with the prepared data
      handleFilter(filterData);
    } catch (error) {
      console.error('Error in getSearchAnnouncement:', error);
    }
  };
  const handleFilter = (data: FilterFormData) => {
    try {
      let payload: any = generatePayload();
      if (data.toEmails.length != 0) {
        payload.searchEmailAnnouncementDto.emailRecipient = {
          "toEmail": data.toEmails.toString()
        }
      }
      if (data.subjects) {
        payload.searchEmailAnnouncementDto.subject = data.subjects;
      }
      // Extract only the date part from the ISO date string
      if (data.selectedDate) {
        const date = new Date(data.selectedDate);
        const formattedDate = date.getFullYear() +
          '-' +
          String(date.getMonth() + 1).padStart(2, '0') +
          '-' +
          String(date.getDate()).padStart(2, '0'); // Format as YYYY-MM-DD
        payload.searchEmailAnnouncementDto.date = formattedDate;
      }
      getFilterAnnouncementList(payload);
    } catch (error) {
      console.error('Error in handleFilter:', error);
    }
  }

  const getFilterAnnouncementList = (payload: any) => {
    try {
      allEmailList(payload)
        .then((response: AxiosResponse<ApiData> | AxiosError) => {
          // Check if the response is an AxiosResponse and contains data
          if (response && !(response instanceof AxiosError)) {
            const data = response.data;

            if (data?.data) {
              setList(data.data);
              setFilteredList(data.data); // Initialize filtered list with full data
            }
            setLoader(false);
          } else {
            // Handle AxiosError here
            console.error('API call failed:', response);
            setLoader(false); // Set loader to false if error occurs
          }
        })
        .catch((err: AxiosError) => {
          // Handle AxiosError here as well (if not handled in .then)
          console.error('Error:', err);
          setLoader(false); // Set loader to false if error occurs
        });
    } catch (err) {
      // Handle AxiosError here as well (if not handled in .then)
      console.error('Error in getFilterAnnouncementList:', err);
      setLoader(false); // Set loader to false if error occurs
    }
  }
  // Function to initialize the payload based on the status
  const generatePayload = () => {
    try {
      if (status == 'TRASH') {
        return {
          searchEmailAnnouncementDto: {
            getTrash: true,
            organisationId: orgId,
          },
          start: 0,
          limit: 500
        }
      }
      const searchDto: SearchEmailAnnouncementDto = {
        isActive: status !== 'TRASH', // Set isActive based on status
        organisationId: orgId,
      };
      // Add `status` only if it is not "all" and not "Starred"
      if (status !== 'all' && status !== 'Starred') {
        searchDto.status = status;
      }
      // Add `isStarred` if the status is "Starred"
      if (status === 'Starred') {
        searchDto.isStarred = true;
      }
      return {
        searchEmailAnnouncementDto: searchDto,
        start: 0,
        limit: 500
      };
    } catch (error) {
      console.error('Error in generatePayload:', error);
      throw error; // Re-throw the error if it's critical
    }
  };
  // Function to initialize the email list by making an API call
  const init = () => {
    setLoader(true);
    getAllAnnouncements();
  };
  const getAllAnnouncements = () => {
    try {
      setLoader(true);
      const payload = generatePayload();
      allEmailList(payload)
        .then((response: AxiosResponse<ApiData> | AxiosError) => {

          // Check if the response is an AxiosResponse and contains data
          if (response && !(response instanceof AxiosError)) {
            const data = response.data;
            if (data?.data) {
              setList(data.data);
              setFilteredList(data.data); // Initialize filtered list with full data
              setLoader(false);
            }
            setLoader(false);
          } else {
            // Handle AxiosError here
            console.error('API call failed:', response);
            setLoader(false); // Set loader to false if error occurs
          }
        })
        .catch((err: AxiosError) => {
          // Handle AxiosError here as well (if not handled in .then)
          console.error('Error:', err);
          setLoader(false); // Set loader to false if error occurs
        });
    } catch (err) {
      // Handle any unexpected errors during the process
      console.error('Error in getAllAnnouncements:', err);
    }
  }

  return (
    <>
      {loader && <Loader />}
      <div className='mail-items h-full overflow-x-hidden'>
        {list.length > 0 ? (
          list.map((object: EmailListItem, index: number) => (
            <MailItem key={index} object={object} status={status} handleDeleteAll={handleDeleteAll} markAll={markAll} setLoader={setLoader} loader={loader} />
          ))
        ) : (
          loader ? (
            <></>
          ) : (
            <div className=' flex justify-center items-center h-full'>
              <p className='flex justify-center items-center text-xl'> <img src={noRecordFound} alt="no-record" /></p>
            </div>
          )
        )}
      </div>
    </>
  )
}
