import React from 'react';
import CommonTab from '../../components/Tab/Tab';
import { ArrowLeftOutlined } from '@ant-design/icons';
import editButton from '../../assets/images/new-dashboard/edit.svg';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';

export default function Organization() {
    const orgName = useSelector((state: any) => state?.organisationName?.orgName);
    return (
        <div className='organization page-container bg-customBg '>
            <div className=' full-width-container'>
                <div className='organization_header'>
                    <h1 className='organization_title py-5 flex'>
                        <Link to='/home/dashboard'>
                            <span className='organization_back-arrow pr-4 hover:text-black'>
                                <ArrowLeftOutlined />
                            </span>
                        </Link>
                        {orgName}
                        <Tooltip title='Edit Organization'>
                            <Link to='/home/organisation/edit-organisation'>
                                <img src={editButton} alt='edit' className='pl-4 cursor-pointer' />
                            </Link>
                        </Tooltip>
                    </h1>
                </div>

                <div className='organization__Tab bg-white relative'>
                    <CommonTab />
                </div>
            </div>
        </div>
    );
}
