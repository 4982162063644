import React, { useState, useEffect } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import UploadCourseFile from './UploadCourseFile';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Select, Upload } from 'antd';
import { triggerNotification, useGetApiRequests } from '../../../../common/CommonExport';
import Loader from '../../../../components/Loader/Loader';
import chapterValidationSchema from './AddEditValidationSchema';
import { setChapterInEdit } from '../../../../redux/slices/currentCourseDetailSlics';
import ModalLoader from '../../../../components/Loader/ModalLoader';

const AddChapterContent = (props: any) => {
  const { close, getListOfChapters, isEdit, editRecord, start } = props;
  const dispatch = useDispatch()
  const flowDirection = useSelector((state: any) => state?.currentCourse?.flow);
  const [fileType, setFileType] = useState('');
  const [courseName, setCourseName] = useState('')
  const [mediaData, setMediaData] = useState<any>(new FormData());
  const [selectedFileName, setSelectedFileName] = useState('');
  const [zipFile, setZipFile] = useState<File | any>(null);
  const [loader, setLoader] = useState(false);
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const newChapterFlow = useSelector((state: any) => state?.currentCourse?.addNewChapterFlow);
  const createNewChapterApi = useGetApiRequests('createNewChapter', 'POST');
  const editChapterApi = useGetApiRequests('editChapter', "PUT");
  const options: any = [
    { value: 'CC45', label: 'xapi', },
  ];
  const [initialValues, setInitialValues] = useState<any>({
    courseName: '',
    fileType: '',
    file: '',
    chapterZipName: ''

  })
  const handleValues = (event: any, setFieldValue: any, name: any) => {
    if (name === 'courseName') {
      setFieldValue('courseName', event.target.value)
      setInitialValues({ courseName: event.target.value })
      setCourseName(event.target.value)
    }
  }
  const handleFileType = (value: any, setFieldValue: any, name: any) => {
    if (name === 'fileType') {
      setFieldValue('fileType', value)
      setFileType(value)
    }
  }

  const handleBack = () => {
    close();
  }

  useEffect(() => {
    if (!isEdit) {
      setInitialValues({ courseName: '', fileType: '', file: '', chapterZipName: '' })
      setFileType('')
      setCourseName('')
    } else {
      setInitialValues({ courseName: editRecord?.name || '', fileType: 'CC45', file: '', chapterZipName: editRecord?.chapterZipName })
    }
    setCourseName(editRecord?.name)
    setSelectedFileName(editRecord?.chapterZipName);
    setFileType('CC45')
  }, [isEdit, editRecord])

  const newChapterFlowInCourse = (data: any) => {
    const localData = {
      ...data,
      key: data?.chapterId
    }
    dispatch(setChapterInEdit(localData))
  }

  const createChapter = async () => {
    setLoader(true);
    const chapterDtoStrObject: any = {
      name: courseName,
      code: fileType,
      organisationId: orgId
    }
    if (isEdit) {
      chapterDtoStrObject['chapterId'] = editRecord?.key;
    }
    const params: object = {
      chapterDtoStr: chapterDtoStrObject,
    }
    if (!isEdit && zipFile === null || zipFile === undefined) {
      triggerNotification('warning', '', 'Please upload valid zipfile', 'topRight');
      setLoader(false);
    } else {
      let formData;
      if (zipFile === null && isEdit) {
        formData = ''
      } else {
        formData = new FormData();
        formData.append('file', zipFile);
      }
      const pathParams: object = {
        id: editRecord?.key
      }
      const headers = { 'Content-Type': 'multipart/form-data' };
      if (!isEdit) {
        try {
          await createNewChapterApi(formData, params, {}, headers).then((res: any) => {
            if (res?.data?.data !== null) {
              setLoader(false);
              triggerNotification('success', '', res?.data?.message, 'topRight');
              if (newChapterFlow) newChapterFlowInCourse(res?.data?.data)
              close();
              getListOfChapters(start);
            } else {
              setLoader(false);
              triggerNotification('success', '', res?.data?.message, 'topRight');
            }
          })
        } catch (err: any) {
          setLoader(false);
          triggerNotification('error', '', err?.response?.data?.message, 'topRight');
        }
      } else {
        try {
          if (formData === '') {
            await editChapterApi({}, params, pathParams, headers)
              .then((res: any) => {
                if (res?.data?.data !== null) {
                  setLoader(false);
                  triggerNotification('success', '', res?.data?.message, 'topRight');
                  if (newChapterFlow) newChapterFlowInCourse(res?.data?.data)
                  close();
                  getListOfChapters(start);
                } else {
                  setLoader(false);
                  triggerNotification('success', '', res?.data?.message, 'topRight');
                }
              })
          } else {
            await editChapterApi(formData, params, pathParams, headers)
              .then((res: any) => {
                if (res?.data?.data !== null) {
                  setLoader(false);
                  triggerNotification('success', '', res?.data?.message, 'topRight');
                  if (newChapterFlow) newChapterFlowInCourse(res?.data?.data)
                  close();
                  getListOfChapters(start);
                } else {
                  setLoader(false);
                  triggerNotification('success', '', res?.data?.message, 'topRight');
                }
              })
          }
        } catch (err: any) {
          setLoader(false);
          triggerNotification('error', '', err?.response?.data?.data, 'topRight');
        }
      }
    }
  };
  const propsData: any = {
    name: 'file',
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    beforeUpload: (file: File) => {
      const isZip = file.type === 'application/zip';
      if (file.type === 'application/zip' && file.size <= 50 * 1024 * 1024 && isZip && !file.name.includes(' ')) {
        setSelectedFileName(file.name)
        setZipFile(file);
      } else {
        if (file.name.includes(' ')) {
          triggerNotification('warning', '', 'File name should not contain spaces', 'topRight');
        } else {
          triggerNotification('warning', '', 'Please select a valid zip file within 50MB', 'topRight');
        }
      }
      return isZip;
    }
  };
  return (
    <div className={`${isEdit ? 'edit-responsive' : ""} chapter bg-[#ffffff] overflow-y-hidden min-h-[500px]`}>
      <div className='chapter-content  pt-16'>
        <Formik
          initialValues={initialValues}
          validationSchema={chapterValidationSchema}
          values={initialValues}
          onSubmit={createChapter}
          enableReinitialize={true}
        >
          {({ setFieldValue, values }: any) => {
            return (
              <div>
                <Form>
                  <div className='add-add_chapter'>
                    <div className="chapter-field mb-2">
                      <label className='addcourse-lable pl-2' htmlFor="courseName">Chapter Name*</label>
                      <Field id="courseName" name="courseName" placeholder="Enter Chapter Name" value={values?.courseName} onChange={(e: any) => { handleValues(e, setFieldValue, 'courseName') }} />
                    </div>
                    <div className='pl-1'>
                      <ErrorMessage name="courseName" component="div" className="error-message " />
                    </div>
                    <div className="chapter-field mb-5 mt-3">
                      <label className='addcourse-lable pl-2' htmlFor="fileType">Select File Type*</label> <br />
                      <Select className='w-full h-[40px]'
                        placeholder={'File Type*'} options={options}
                        onChange={(value: any) => { handleFileType(value, setFieldValue, 'fileType') }}
                        value={options.filter((item: any) => item.value === fileType)}>
                      </Select>
                      <div>
                        <ErrorMessage name="fileType" component="div" className="error-message" />
                      </div>
                    </div>
                    {!isEdit && (
                      <div className="chapter-media w-full">
                        <UploadCourseFile setMediaData={setMediaData} setZipFile={setZipFile} />
                      </div>)}
                    {isEdit && (
                      <div className="chapter-field not-a-delete mb-5">
                        <label data-testid="file*El" className='addcourse-lable pl-2 cursor-pointer' htmlFor="zipUpload">File*</label>
                        <br />
                        <div className="mt-1">
                          <Upload {...propsData}>
                            <Button className='m-auto px-2 w-full overflow-auto'>{selectedFileName || initialValues?.chapterZipName || ".zip"}</Button>
                          </Upload>
                        </div>
                      </div>
                    )}</div>
                  <div className={`${"footer add-chapter _chapter bg-[#ffffff] px-[15px] _footer"} ${!isEdit ? "__footer " : ""}`}>
                    <div className='my-5'>
                      <div className="btnGroup">
                        {!flowDirection && <button className='addButton mr-4 primary px-5   cancelElms' type="button" onClick={handleBack} >Back</button>}
                        <button className='addButton mr-4 primary px-5 confirmElms' type='submit'>Save & Next</button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            )
          }}
        </Formik>
      </div>
      {loader && <ModalLoader />}
    </div>
  )
}
export default AddChapterContent
