import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import FormFields from './form/FormFields';
import { deprovisionValidation } from './form/schemas/deprovisionValidationSchema';
import { triggerNotification, useGetApiRequests, useNavigate } from '../../../../../../common/CommonExport';
import dayjs from 'dayjs';

const DeprovisionFormMail = ({ exitFormFillDetails, exitFormFillData, questions, employeeApiKey, employeeId }: any) => {
  const navigate = useNavigate()
  const createExitInterView = useGetApiRequests('createExitInterView', 'POST');
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const currentDate = dayjs()
  const [initialValues, setInitialValues] = useState({
    reviewtype: '',
    salaryRevised: '',
    incrementEligible: false,
    probationExtension: '',
    interviewBy: '',
    date: currentDate,
    typeOfExit: '',
    exitReason: '',
    appraiserComments: Array(questions.length).fill(''),
  }
  )

  useEffect(() => {
    const employeeRelievingDate = exitFormFillData?.employeeRelievingDate ? exitFormFillData?.employeeRelievingDate : currentDate;
    const date = dayjs(employeeRelievingDate);
    setInitialValues({
      ...initialValues,
      typeOfExit: exitFormFillData?.exitType,
      interviewBy: exitFormFillData?.hrName,
      date: date,
      exitReason: exitFormFillData?.exitReason

    })
  }, [exitFormFillData])



  const martialStatus = [
    { label: 'Resignation', value: 'Resigned' },
    { label: 'Layoff', value: 'Layoff' },
    { label: 'Others', value: 'Others' },
    { label: 'Termination', value: 'Termination' }
  ];
  const handleSubmit = (values: any) => {

    const payload = exitFormFillDetails.map((question: any, index: number) => {
      return {
        employeeId,
        questionaire: {
          questionaireId: question?.questionaireId
        },
        answer: values?.appraiserComments[index]
      };

    });
    submitReview(payload)

  }

  const submitReview = (payload: any) => {
    const queryString = {
      employeeApiKey: employeeApiKey,
      employeeReviewId: exitFormFillData?.exitReviewId
    }
    setBtnLoading(true);
    createExitInterView(payload, queryString).then((res: any) => {
      setTimeout(() => {
        navigate('/exit-interview-success')
      }, 1000)
      setBtnLoading(false);
    })
      .catch((err: any) => {
        setBtnLoading(false);
        triggerNotification('error', '', err?.response?.data?.message, 'topRight');
      });
  }
  return (
    <>

      <div className="salary-date review-modal email-template">

        <Formik
          initialValues={initialValues}
          validationSchema={deprovisionValidation(questions)}
          onSubmit={handleSubmit} enableReinitialize
        >
          {({ values, setFieldValue, errors }) => {
            console.log(errors)
            return (
              <Form>
                <FormFields
                  questions={exitFormFillDetails}
                  martialStatus={martialStatus}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                />
                <div className="flex w-full justify-center items-center pt-10 lg:pt-20 pb-5 lg:pb-16">
                  <button
                    className="form-submit-btn text-white font-medium"

                  >
                    {
                      btnLoading ? 'Submitting...' : 'Submit Application'
                    }
                  </button>
                </div>
              </Form>)
          }}
        </Formik>
      </div>
    </>
  );
};

export default DeprovisionFormMail;
