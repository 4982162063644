import React, { useEffect, useState } from 'react';
import DynamicTable from '../../../components/CommonTable/DynamicTable';
import { ErrorMessages, debounce, triggerNotification, useGetApiRequests, useSelector } from '../../../common/CommonExport';
import UserHeader from '../layout/UserHeader';
import { Modal, Pagination, Tooltip } from 'antd';
import CreateEditUser from '../CreateEditUser/CreateEditUser';
import DeleteConfirmationModal from '../../Organization/DeleteConfirmationModal';
import { handleDeleteDesignation } from '../../Organization/OrganisationHelper';

const Designations = () => {
    const userList = useGetApiRequests('user', 'GET');
    const orgId = useSelector((state: any) => state?.organisationId?.id);
    const deleteSingleUserApi = useGetApiRequests('singleUserDelete', 'DELETE')
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [elementData, setElementData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [deleteMessage, setDeleteMessage] = useState('');
    const [isSingleDelete, setIsSingleDelete] = useState(false);
    const [deletePopUp, setDeletePopUp] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [editRecord, setEditRecord] = useState<any>();
    const [count, setCount] = useState<number>();
    const onShowSizeChange = (current: number, newSize: number) => {
        setPageSize(newSize);
        setCurrentPage(1);
    };
    const onSearch = debounce((searchTerm: string) => {
        getUsersList(searchTerm);
    }, 500);
    useEffect(() => {
        const tags: HTMLCollectionOf<Element> = document.getElementsByClassName('ant-table-content');
        if (tags[1]) {
            (tags[1] as HTMLElement).scrollTop = 0;
        }
        getUsersList();
    }, [currentPage, pageSize]);
    const getUsersList = (searchTerm = '') => {
        setLoader(true);
        const start: number = (currentPage - 1 === 0) ? 0 : currentPage - 1;
        const param: any = {
            start: start,
            limit: pageSize,
            organisationId: orgId,
            isActive: true
        }
        if (searchTerm !== '') {
            param.search = searchTerm
        }
        userList("", param)
            .then((response: any) => {
                const data = response?.data?.data
                if (data) {
                    const tempUserList: any = data.map((item: any) => {
                        let obj = {
                            Role: item?.role?.name,
                            Email: item.email,
                            Name: item.name,
                            key: item.userId,
                            roleId: item?.role?.roleId
                        };
                        return obj;
                    });
                    setCount(response?.data.totalResults)
                    setTimeout(() => {
                        setLoader(false)
                    }, 1000)
                    setElementData(tempUserList)
                }
            })
            .catch((err: any) => {
                triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
                setTimeout(() => {
                    setLoader(false)
                }, 1000)
            });

    }
    const columns = [
        {
            title: 'User Name',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: 'Role',
            dataIndex: 'Role',
            key: 'Role',
        },
        {
            title: 'Email',
            dataIndex: 'Email',
            key: 'Email',
        },

        {
            title: 'Action',
            dataIndex: 'deleteButton, editButton',
            render: (_: any, record: any) => (
                <div className='flex justify-end'>
                    <Tooltip placement="top" title="Edit">
                        <span className="material-symbols-outlined mr-4 cursor-pointer hover:text-primary" onClick={() => handleEditRow(record)} >
                            edit
                        </span>
                    </Tooltip>
                    <Tooltip placement="top" title="Delete">
                        <span className="material-symbols-outlined cursor-pointer hover:text-primary" onClick={() => handleSingleDelete(record)}>
                            delete
                        </span>

                    </Tooltip>
                </div>
            ),
        }
    ];

    const handleOpenModal = () => {
        setIsEdit(false)
        setOpen(true)
    };
    const handleCloseModal = () => {
        setOpen(false)
    };
    const handleEditRow = (record: any) => {

        setIsEdit(true)
        setOpen(true)
        setEditRecord(record);
    };
    const handleSelectedRows = (selected: any) => {
        setSelectedRows(selected);
        setIsSingleDelete(false);
        setDeleteMessage(`Are you sure you want to delete these ${selected.length} users?`);
    }
    const handleSingleDelete = (record: any) => {
        setDeletePopUp(true);
        setIsSingleDelete(true);
        const recordArray: any = [];
        recordArray.push(record);
        setSelectedRows(recordArray);
        setDeleteMessage(`Are you sure you want to delete ${record.Role} ?`);
    };
    const handleSingleSelectDelete = async () => {
        const deleteArray: any = [];
        setDeletePopUp(false);
        const roleId = { 'userId': selectedRows[0].key };
        deleteArray.push(roleId);
        handleDeleteDesignation(deleteSingleUserApi, deleteArray, getUsersList, '');
        setSelectedRows([])

    };
    const handleDeleteSelected = async () => {
        const deleteArray: any = [];
        selectedRows.map((item: any) => {
            const id = {
                'userId': item
            }
            deleteArray.push(id);
        })
        handleDeleteDesignation(deleteSingleUserApi, deleteArray, getUsersList, '');
        setSelectedRows([])
    };
    const handleCloseDelete = () => setDeletePopUp(false);

    return (

        <div className="user-table">
            <UserHeader types={"Users"} handleOpenModal={handleOpenModal} onSearch={onSearch} setDeletePopUp={setDeletePopUp} selectedRows={selectedRows} isSingleDelete={isSingleDelete} />
            <DynamicTable columns={columns} data={elementData} handleSelectedRows={handleSelectedRows} loading={loader} />
            <div className='pagination'>
                <Pagination
                    className='float-right flex flex-row p-6'
                    showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    current={currentPage}
                    total={count}
                    pageSize={pageSize}
                    onChange={(page) => setCurrentPage(page)}
                />
            </div>
            {open && (
                <Modal centered open={open} onCancel={handleCloseModal} className='modal-user' title={isEdit ? "Edit User" : "Create user"} footer={null} maskClosable={false}>
                    <CreateEditUser isEdit={isEdit} getUsersList={getUsersList} setOpen={setOpen} selectedRecord={editRecord} />
                </Modal>
            )}

            {deletePopUp && <DeleteConfirmationModal open={deletePopUp} handleSingleSelectDelete={handleSingleSelectDelete} handleDeleteSelected={handleDeleteSelected} handleCloseDelete={handleCloseDelete} selectedRows={selectedRows} type='designation' isSingleDelete={isSingleDelete} deleteMessage={deleteMessage} />}
        </div>

    );
};

export default Designations;