import React from 'react';
import { Button, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentTab } from '../../../../redux/slices/courseSlice';

const AssignChapterAndCourseFooter = ({ handleBack, handleSwitchToNextSection, backButton = true }: any) => {
  const dispatch = useDispatch()
  const reduxToken = useSelector((state: any) => state?.courseSlice?.courseTab);
  const flowDirection = useSelector((state: any) => state?.currentCourse?.flow);
  const newChapterFlow = useSelector((state: any) => state?.currentCourse?.addNewChapterFlow);

  const handleAddNewAssessment = () => {
    dispatch((setCurrentTab(10)))
  }

  const handleQuizBulkUpload = () => {
    dispatch((setCurrentTab(9)))
  }
  return (
    <div className={`pt-2 pb-2 common-footer flex justify-between absolute w-full left-0 bg-white px-[20px] ${flowDirection ? 'bottom-[-10px]' : 'bottom-[0px]'}`}>
      <div>
        {reduxToken !== 3 &&
          <div className="btnGroup flex justify-start">
            <Tooltip placement="top" title={'Bulk Upload'}>
              <Button onClick={() => handleQuizBulkUpload()} shape="circle" size={"large"} className='mr-3 add-photos-btn'>
                <span className="material-symbols-outlined text-[#727272]">
                  add_to_photos
                </span>
              </Button>
            </Tooltip>
            <Tooltip placement="top" title={'Add Question'}>
              <Button onClick={() => handleAddNewAssessment()} type="primary" shape="circle" size={"large"} >
                <span className="material-symbols-outlined">
                  add
                </span>
              </Button>
            </Tooltip>
          </div>
        }
      </div>
      <div className="btnGroup flex justify-end">
        {/* {(!flowDirection || newChapterFlow || finalAssessmentInCourse) && <button className='addButton mr-4 primary px-5  cancelElms' type="submit" onClick={handleBack} >Back</button>} */}
        {backButton && <button className='addButton mr-4 primary px-5  cancelElms' type="submit" onClick={handleBack} >Back</button>}
        <button className='addButton mr-2 primary px-5 confirmElms' type="submit" onClick={handleSwitchToNextSection}>Save & Next</button>
      </div>
    </div>
  )
}

export default AssignChapterAndCourseFooter