import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { storeData, useGetApiRequests } from '../../../common/CommonExport';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Loader from '../../../components/Loader/Loader';

const ProjectList = () => {
    const projectsList = useGetApiRequests('projectsList', 'GET');
    const navigate = useNavigate();

    const [searchQuery, setSearchQuery] = useState('');
    const [data, setData] = useState<any>([]);
    const [filteredData, setFilteredData] = useState<any>([]);
    const [loader, setLoader] = useState<any>(false)

    useEffect(() => {
        getProjectListData()

    }, []);
    const getProjectListData = () => {
        setLoader(true)
        const params = {}
        projectsList('', params)
            .then((response: any) => {

                setData(response?.data?.data);
                setFilteredData(response?.data?.data)
                setTimeout(() => {
                    setLoader(false)
                }, 2000)

            })
            .catch((err: any) => {
                setLoader(false)
                console.error(err);
            });
    };
    useEffect(() => {
        if (searchQuery === '') {
            setData(filteredData);
        } else {
            setData([])
            const filtered = filteredData.filter((project: any) => project.name.toLowerCase().includes(searchQuery.toLowerCase()));
            setData(filtered);
        }
    }, [searchQuery]);
    const handleSearch = (value: string) => {
        setSearchQuery(value);
    };
    const handelNavigate = (id: any, name: any) => {
        storeData('projectName', name, false);
        storeData('jiraProjectId', id, false);
        navigate('/home/project-list/project-board');
    };

    return (
        <div className='page-container bg-customBg'>
            {loader && <Loader />}
            <div className="full-width-container">
                <h2 className='organization_title text-primary text-2xl font-medium  py-5 flex'>
                    <Link to='/home/dashboard'>
                        <span className='organization_back-arrow pr-4 hover:text-black'>
                            <ArrowLeftOutlined />
                        </span>
                    </Link>Projects</h2>
                <div className='bg-white tab-container project-page pt-4'>
                    <div className='search-bar'>
                        <div className='lastSync'>
                            {/* <p>Last updated : </p> */}
                            <p>
                                <span className='sync-animation'>
                                    <span className='ellipsis'></span>
                                </span>
                            </p>
                        </div>
                        <div className='flex justify-end'>
                            <div className="search-box mb-4 mr-5 flex">
                                <input className="search-txt text-black" type="text" name="" placeholder="Search" value={searchQuery}
                                    onChange={(e) => handleSearch(e.target.value)} />
                                <a className="search-btn" href="#">
                                    <span className="material-symbols-outlined">
                                        search
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className='projects overflow-y-auto '>
                        <ul className='projects-list w-full flex flex-wrap mx-auto my-0'>
                            {data.map((project: any, index: any) => (
                                <li
                                    key={project.jiraProjectId}
                                    className='projects-list-card w-1/4 sm:w-1'
                                    onClick={() => handelNavigate(project.jiraProjectId, project.name)}
                                >
                                    <span className='project-name'>{project.name}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectList;
