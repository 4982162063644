import React, { useEffect, useState } from 'react'
import DeprovisionForm from './DeprovisionForm';
import NoDueApproval from './NoDueApproval';
import ResignationNotification from './ResignationNotification';
import { triggerNotification, useGetApiRequests } from '../../../../../../common/CommonExport';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from '../../../../../../components/Loader/Loader';
import "./Deprovision.scss"
import ModalLoader from '../../../../../../components/Loader/ModalLoader';

const ResginationDetails = (activeTab: any) => {
  const resginationTabsLoad = useSelector((state: any) => state?.profileReload?.deprovisionLoad);
  const getApprovalDetails = useGetApiRequests('getDeprovisionApprovalList', 'GET');
  const { id }: any = useParams();
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const certificateGeneratedReload = useSelector((state: any) => state?.profileReload?.certificateGeneratedReload);

  const [sendMail, setSendMail] = useState<boolean>(false)
  const [loader, setLoader] = useState(false)
  const [approvalDetails, setApprovalDetails] = useState<any>([])
  const handelCancel = () => {
    setSendMail(false)
  }
  const handelPopup = () => {
    setSendMail(true)

  }
  useEffect(() => {
    setLoader(true);
    const queryParams: any = {
      organisationId: orgId
    };
    const pathParams: any = {
      id: `${id}/deprovision`
    }

    getApprovalDetails('', queryParams, pathParams).then((res: any) => {
      setApprovalDetails(res?.data?.data);
      setTimeout(() => {
        setLoader(false)
      }, 1000)
    })
      .catch((err: any) => {
        triggerNotification('error', '', err?.message, 'topRight');
        setTimeout(() => {
          setLoader(false)
        }, 1000)
      })
  }, [certificateGeneratedReload, resginationTabsLoad])

  return (
    <div className=' max-h-[66vh] overflow-y-auto pr-1'>

      {approvalDetails && (
        <div>
          <DeprovisionForm formDetails={approvalDetails} />
          {/* <AssetCollect assertDetails={approvalDetails} /> */}
          <NoDueApproval noDueDetails={approvalDetails} />
        </div>)}
      <button className='hidden' onClick={handelPopup}>Send</button>
      {loader && <ModalLoader />}
      {sendMail && <ResignationNotification open={sendMail} handelCancel={handelCancel} />}

    </div>
  )
}

export default ResginationDetails
