import React, { useEffect, useState } from 'react'
import { Avatar, Table, Tooltip } from 'antd';
import "./LeaveRequest.scss";
import { useGetApiRequests } from '../../../common/CommonExport';
import { leaveRequestColumn } from './LeaveRequestStaticDatas';
import LeaveRequestConfirmation from './LeaveRequestConfirmation';
import { useSelector } from 'react-redux';
import ModalLoader from '../../../components/Loader/ModalLoader';

const LeaveRequest = () => {
  const leavePloicyApi = useGetApiRequests('getLeaveRequest', 'POST');
  const [loader, setLoader] = useState(false);
  const [closeConfirmationModal, setCloseConfirmationModal] = useState(false)
  const [leaveRequestList, setLeaveRequestList] = useState<any>([])
  const [type, setType] = useState('');
  const [approvalObject, setApprovalObject] = useState({});
  const userId = useSelector((state: any) => state?.userId?.id);

  const regularDateConversion = (value: any) => {
    const apiDate = new Date(value);
    const year = apiDate.getFullYear();
    const month = String(apiDate.getMonth() + 1).padStart(2, "0");
    const day = String(apiDate.getDate()).padStart(2, "0");

    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate;
  }
  const getLeaveNames = (fullName: string): string => {
    const words = fullName?.split(' ');
    if (words?.length >= 2) {
      const firstNameInitial = words[0][0].toUpperCase();
      const lastNameInitial = words[words?.length - 1][0].toUpperCase();
      return `${firstNameInitial}${lastNameInitial}`;
    } else if (words?.length === 1) {
      return words[0][0].toUpperCase();
    } else {
      return '';
    }
  };
  const handleApprove = (record: any, type: any) => {
    setApprovalObject(record)
    setCloseConfirmationModal(true);
    setType(type)
  }
  const handleCloseModal = () => {
    setType('')
    setCloseConfirmationModal(false)
  }
  function capitalizeFirstLetter(inputText: any) {
    if (!inputText) return '';
    return inputText.charAt(0).toUpperCase() + inputText.slice(1);
  };

  const columns: any = [
    {
      title: 'Leave Types',
      dataIndex: 'leaveType',
      key: 'leaveType',
      render: (text: any) => (
        <div className='flex items-center leave-type'>
          <Avatar.Group maxCount={3} maxPopoverTrigger="click" size="large" maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}>
            <Avatar className={"leave-type-" + text}>{getLeaveNames(text)}</Avatar>
          </Avatar.Group>
          <p className='ml-2'>{text}</p>
        </div>
      )
    },
    {
      title: 'Employee Name',
      dataIndex: 'employeeName',
      key: 'employeeName',
    },
    {
      title: 'From ',
      dataIndex: 'fromDate',
      key: 'fromDate',
    },
    {
      title: 'To ',
      dataIndex: 'toDate',
      key: 'toDate',
    },
    {
      title: 'Duration ',
      dataIndex: 'duration',
      key: 'duration',
      render: (text: any, record: any) => (
        <div className='flex space-x-2'>
          <p className='ml-2'>{text}</p>
          {record?.lopCount > 0 && (
            <p className='text-red-400 text-[13px]'>(LOP-<span>{record?.lopCount}</span>)</p>
          )}        </div>
      )

    },
    {
      title: 'Status ',
      dataIndex: 'status',
      key: 'status',
      render: (_: any, record: any) => (
        <div className='flex'>
          <p><span className={"mr-2 leave-request-" + record?.status}></span>{record?.status}</p>
        </div>
      ),
    },
    {
      title: 'Reason ',
      dataIndex: 'reason',
      key: 'reason',
      render: (_: any, record: any) => (
        <div className='flex'>
          <p><span className={" leave-request " + record?.reason}></span>{record?.reason}</p>
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: any) => (
        <div className='flex'>
          {record.status === 'pending' || record.status === 'Pending' ? (
            <div>
              <Tooltip placement="top" title="Approve">
                <span className="material-symbols-outlined  cursor-pointer hover:text-green-500" onClick={() => handleApprove(record, 'approve')}>done</span>
              </Tooltip>
              <Tooltip placement="top" title="Decline">
                <span className="material-symbols-outlined cursor-pointer hover:text-red-500" onClick={() => handleApprove(record, 'decline')}>close</span>
              </Tooltip>
            </div>) : (record.status === 'approved' || record.status === 'Approved') ? (
              <div>
                <Tooltip placement="top" title="Approved">
                  <span className="material-symbols-outlined  text-green-500">done</span>
                </Tooltip>
              </div>
            ) : (
            <div>
              <Tooltip placement="top" title="Declined">
                <span className="material-symbols-outlined  text-red-500">close</span>
              </Tooltip>
            </div>
          )}
        </div>
      )
    }];
  useEffect(() => {
    getLeavePolicyApi()
  }, [])
  const getLeavePolicyApi = () => {
    setLoader(true);
    const payload = {
      "searchEmployeeLeaveDto": {
        "employeeId": userId
      },
      start: 0,
      limit: 1000
    }
    leavePloicyApi(payload)
      .then((response: any) => {
        const reformedRequestList = response?.data?.data.map((item: any) => ({
          leaveType: item?.policyType,
          employeeName: item?.employeeName,
          fromDate: regularDateConversion(item?.fromDate),
          toDate: regularDateConversion(item?.toDate),
          duration: item?.duration,
          status: capitalizeFirstLetter(item?.status),
          reason: item?.reason,
          key: item?.employeeLeaveRequestId,
          from: item?.fromDate,
          to: item?.toDate,
          lopCount: item?.lopCount
        }));
        const reversedList = reformedRequestList.reverse();
        setLeaveRequestList(reversedList);
        setTimeout(() => {
          setLoader(false)
        }, 1000)
      })
      .catch(() => {
        setTimeout(() => {
          setLoader(false)
        }, 1000)
      });
  }
  return (
    <div className='leave-report leave-request'>
      <Table columns={columns} dataSource={leaveRequestList} loading={loader} />
      {closeConfirmationModal && <LeaveRequestConfirmation approvalObject={approvalObject} closeConfirmationModal={closeConfirmationModal} setCloseConfirmationModal={setCloseConfirmationModal} type={type} handleCloseModal={handleCloseModal} setType={setType} getLeavePolicyApi={getLeavePolicyApi} />}
    </div>
  )
}

export default LeaveRequest
