import React, { useEffect, useState } from 'react';
import { Formik, ErrorMessage } from 'formik';
import { Input, Select, Form } from 'antd';
import FloatLabel from '../../../components/FloatLabel';
import { ErrorMessages, triggerNotification, useGetApiRequests, useSelector } from '../../../common/CommonExport';
import { createUserValidationSchema } from './CreateUserValidationSchema';
import ModalLoader from '../../../components/Loader/ModalLoader';

export default function CreateEditUser(props: any) {
  const { isEdit, getUsersList, setOpen, selectedRecord } = props;
  const [initialValues, setInitialValues] = useState<any>({ roleName: '', officialEmail: '', role: '', });
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const [roleList, setRoleList] = useState([]);
  const [emailList, setEmailList] = useState<any>([]);
  const [editRoleId, setEditRoleId]: any = useState<number>();
  const [editUserId, setEditUserId]: any = useState<number>();
  const [editUserEmail, setEditUserEmail] = useState<string>();
  const [loader, setLoader] = useState(false);
  const roleTypeGetApi = useGetApiRequests('roleTypeList', 'GET');
  const roleEmailGetApi = useGetApiRequests('roleEmailList', 'GET');
  const createUsersPostApi = useGetApiRequests('createUsers', 'POST');
  const createUsersPutApi = useGetApiRequests('editUsers', 'PUT');

  const getRoleTypeList = async () => {
    const params: any = {
      organisationId: orgId,
    };
    await roleTypeGetApi('', params)
      .then((response: any) => {
        const reformedRoleList = response?.data?.data.map((item: any) => ({
          name: item.name,
          roleId: item.roleId,
          value: item.roleId,
          label: item.name

        }));
        setRoleList(reformedRoleList);
        usersEmailGetApi()
      })
      .catch((err: any) => {
        triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');

      });
  }
  const usersEmailGetApi = async () => {
    setLoader(true)
    const params: any = {
      organisationId: orgId,
      isActive: true,
    };
    await roleEmailGetApi('', params)
      .then((response: any) => {
        const reformedDepartments = response?.data?.data.map((item: any, index: number) => ({
          code: item.code,
          designation: item.designation,
          employeeId: item.employeeId,
          firstName: item.firstName,
          lastName: item.lastName,
          officialEmail: item.officialEmail,
          label: item.officialEmail,
          value: item.employeeId,
          userId: item.userId
        }));
        setEmailList(reformedDepartments);
        setLoader(false)
      })
      .catch((err: any) => {
        triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
        setLoader(false)
      });
  };
  useEffect(() => {
    getRoleTypeList()
  }, [])
  useEffect(() => {
    if (isEdit) {
      setEditRoleId(selectedRecord?.roleId)
      setEditUserEmail(selectedRecord?.Email)
      setEditUserId(selectedRecord?.key)
      setInitialValues({
        roleName: selectedRecord.Name,
        officialEmail: selectedRecord.Email,
        role: selectedRecord.Role
      })
    } else {
      setInitialValues({
        roleName: '',
        officialEmail: '',
        role: ''
      })
    }
  }, [isEdit])
  const onSave = (values: any): any => {
    setLoader(true);
    const gradeObject: any = emailList.find((item: any) => {
      if (item.officialEmail === values.officialEmail) {
        return item;
      }
    });
    const payload = {
      "name": values.roleName,
      "email": gradeObject.officialEmail,
      "employeeId": gradeObject.employeeId,
      "organisationId": orgId,
      "role": {
        "roleId": !isEdit ? values.role : editRoleId
      }
    };
    if (isEdit) {
      const pathParams: object = {
        id: editUserId,
      };
      const editPayload = {
        ...payload,
        "userId": editUserId
      }
      handleApiCall(editPayload, pathParams, ErrorMessages.designationUpdation, ErrorMessages.somethingWentWrong);
    } else {
      handleApiCall(payload, {}, ErrorMessages.userCreation, ErrorMessages.somethingWentWrong);
    }
  }
  const handleApiCall = async (payload: object, pathParams: object, successMessage: string, errorMessage: string) => {
    try {
      setLoader(false)
      await (isEdit ? createUsersPutApi(payload, {}, pathParams) : createUsersPostApi(payload));
      triggerNotification('success', '', successMessage, 'topRight');
      setOpen(false);
      getUsersList();
    } catch (err: any) {
      setLoader(false);
      triggerNotification('error', '', err?.response?.data?.message, 'topRight');
    }
  };

  return (
    <div>
      <div className='form-role mt-8 edit-user'>
        <Formik
          initialValues={initialValues}
          validationSchema={createUserValidationSchema}
          enableReinitialize
          onSubmit={onSave}
        >
          {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
            <Form onFinish={handleSubmit} className='form px-2'>
              <Form.Item className='form-item'>
                <FloatLabel label='User Name*' name='empName' value={values.roleName}>
                  <Input
                    type="text"
                    name="roleName"
                    className="float-control"
                    onChange={event => setFieldValue("roleName", event.target.value)}
                    value={values.roleName}
                  />
                </FloatLabel>
                <ErrorMessage name="roleName" component="div" className='error-message' />
              </Form.Item>
              <Form.Item className='form-item'>
                <FloatLabel label='Official Email*' name='officialEmail' value={values.officialEmail}>
                  <Select
                    className="float-control"
                    showSearch
                    onChange={value => {
                      const filteredEmailList = emailList.find((item: any) => item.employeeId === value)
                      setFieldValue("officialEmail", filteredEmailList.label)
                      setEditUserEmail(filteredEmailList.label)
                    }}
                    options={emailList}
                    value={emailList.filter((item: any) => item.officialEmail === editUserEmail)}
                    disabled={!!isEdit}
                    filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                  />
                </FloatLabel>
                <ErrorMessage name="officialEmail" component="div" className='error-message' />
              </Form.Item>
              <Form.Item className='form-item'>
                <FloatLabel label='Role*' name='role' value={values.role}>
                  <Select
                    className="float-control"
                    onChange={value => {
                      setFieldValue("role", value)
                      setEditRoleId(value)
                    }}
                    options={roleList}
                    value={roleList.filter((item: any) => item?.value === editRoleId)}
                  />
                </FloatLabel>
                <ErrorMessage name="role" component="div" className='error-message' />
              </Form.Item>
              <div className='mt-5 flex justify-end mb-[2px] '>

                <button className='addButton mr-4 secondary' onClick={() => {
                  setOpen(false);
                }}  >
                  Cancel
                </button>
                <button className='addButton mr-4 ' type='submit' >
                  {isEdit ? 'Update' : 'Add'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {loader && <ModalLoader />}
    </div>
  );
}
