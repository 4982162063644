import React from 'react'
import type { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';
const PresentTable = (props: any) => {
  const { presentList, loader } = props;

  const columns: ColumnsType<any> = [
    {
      title: 'Employee name',
      dataIndex: 'employeeName',
      key: 'name',
      width: "25%",
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'age',
      width: "25%",
    },
    {
      title: 'In-Time ',
      dataIndex: 'inTime',
      key: 'address',
      width: "30%",
    },
    {
      title: 'Out-Time',
      dataIndex: 'outTime',
      key: 'age',
      width: "25%",
    },
    {
      title: 'Duration ',
      dataIndex: 'duration',
      key: 'address',
      width: "30%",
    },
  ]
  return (
    <div className='leave-management'>
      <Table columns={columns} dataSource={presentList} loading={loader} />
    </div>
  )
}

export default PresentTable
