import { Input, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React from 'react';
import ExitFormModalForm from '../ExitFormModalForm';

interface ExitFormModalProps {
  open: boolean;
  handleClose: () => void;
}

const ExitFormModal: React.FC<ExitFormModalProps> = ({ open, handleClose }) => {
  return (
    <Modal
      title="Appraisal"
      className="salary-modal"
      centered
      open={open}
      onCancel={handleClose}
      footer={null}
      maskClosable={false}
    >
      <ExitFormModalForm />
    </Modal>
  );
};

export default ExitFormModal;
