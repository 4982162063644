import { Button, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { triggerNotification, useGetApiRequests } from '../../../../../../common/CommonExport';
import ModalLoader from '../../../../../../components/Loader/ModalLoader';
import { useDispatch } from 'react-redux';
import { setDeprovision, setProfileLoad } from '../../../../../../redux/slices/updateProfileSlice';
import ResignationNotification from '../deprovision/ResignationNotification';
import { useSelector } from 'react-redux';

const SendMail = (props: any) => {
  const { open, handelClose, sendMailType } = props;
  const sendBasicInfoMail = useGetApiRequests('sendBasicInfoMail', 'GET');
  const { id } = useParams();
  const dispatch = useDispatch()
  const [sendMail, setSendMail] = useState<boolean>(false);
  const [sendDeprovisionMail, setSendDeprovisionMail] = useState<boolean>(false);
  const employeeDetails: any = useSelector((state: any) => state?.eventData?.particularEmployeeDetails);
  const [manaualFill, setManualFill] = useState(false)
  const [loader, setLoader] = useState(false)
  useEffect(() => {
    setSendMail(open)
    if (employeeDetails?.dateOfResigned === null || employeeDetails?.dateOfResigned === '') {
      setManualFill(true)
    } else {
      setManualFill(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMail])
  const onCancel = () => {
    handelClose()
  }
  const handelSendMail = (sendMailType: boolean) => {
    if (sendMailType) {
      setSendDeprovisionMail(true)
    }
    else {
      setLoader(true)
      const queryParams: any = {
        employeeId: id
      }
      sendBasicInfoMail('', queryParams)
        .then((res: any) => {
          triggerNotification('success', '', res?.data?.status?.message, 'topRight')
          setLoader(false);
          dispatch(setProfileLoad());
          onCancel();
        })
        .catch((err: any) => {
          triggerNotification('error', '', err?.message, 'topRight')
          setLoader(false)
        })
    }
  }
  const handelCancel = () => {
    handelClose()
  }

  return (
    <Modal className='deporvision' centered open={sendMail} footer={null} maskClosable={false}>
      {sendMailType ?
        <p className='text-center text-[#636363] text-[20px] pt-3'>Are you sure to Deprovision {`(${employeeDetails?.firstName} ${employeeDetails?.lastName})`}?</p> :
        <p className='text-center  text-[#636363] text-[20px] pt-3'>Are you sure want to send basic information ?</p>
      }

      <div className="flex justify-center mt-8 pb-3">
        <Button type="primary" shape="round" className='addButton w-[140px]   _deprovision mr-4' onClick={() => onCancel()} >
          No
        </Button>
        <Button type="primary" shape="round" className='addButton w-[140px] deprovision text-[#d23b7d]' onClick={() => handelSendMail(sendMailType)} >
          Yes
        </Button>
      </div>
      {loader && <ModalLoader />}
      {sendDeprovisionMail && <ResignationNotification open={sendDeprovisionMail} handelCancel={handelCancel} manaualFill={manaualFill} />}

    </Modal>
  )
}

export default SendMail
