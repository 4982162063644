import { useState, useEffect } from "react";
import { triggerNotification, useGetApiRequests } from "../common/CommonExport";
import { AxiosError, AxiosResponse } from "axios";
import { ExitFormFillDataTypes } from "./exitFormFillData.type";
import { useLocation } from "react-router-dom";

interface ResMessage {
  message: string;
}

const useExitFormDetails = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const employeeId = queryParams.get("employeeId");
  const employeeApiKey = queryParams.get("employeeApiKey");
  const getQuestions = useGetApiRequests("getExitFormFillDetails", "GET");
  // const employeeId = "4655";
  const [exitFormFillDetails, setExitFormFillDetails] = useState<any[]>([]);
  const [resMessage, setResMessage] = useState<ResMessage | null>(null);
  const [exitFormFillData, setExitFormFillData] = useState<ExitFormFillDataTypes>({
    employeeName: "",
    employeeCode: "",
    employeeDesignation: "",
    employeeDepartment: "",
    reportingManagerName: "",
    employeeDateOfJoin: "",
    employeeRelievingDate: "",
    hrName: "",
    apiKey: "",
    exitReviewId: 0,
    exitType: "",
    exitReason: "",
    employeeResignedDate: "",
    performanceQuestionsList: [],
  });
  const [questions, setQuestions] = useState<any[]>([
    "Why are you leaving the company ?",
    "Was the job realistically presented to you when you were hired?",
    "Was your salary satisfactory for the job you were performing?",
    "How do you feel about the perk and benefits offered by the company?",
    "Do you think your current supervisor was fair and reasonable?",
    "How would you rate the training you received for your position?",
    "Did you feel your contributions were appreciated by your supervisor and others?",
    "How were the working conditions and your working environment?",
    "Do you feel you were fairly treated while with the company? If not, can you elaborate?",
    "Do you have any suggestions for improvement of the company or your department in particular?",
    "Are there any other comments you would like to make?",
    "Type of Exit",
  ]);
  const [loader, setLoader] = useState<boolean>(true);

  useEffect(() => {
    const getExitFormFillDetails = async () => {
      const pathParams = {
        employeeId: `${employeeId}/exit-interview?apikey=${employeeApiKey}`,
      };

      try {
        const response: AxiosResponse<any> | AxiosError = await getQuestions("", {}, pathParams);

        // Check if the response is of type AxiosResponse before accessing data
        if ("data" in response) {
          const data = response?.data?.data || [];
          setResMessage(response?.data?.status);
          const parsedData: any = data;
          setExitFormFillData(parsedData);
          const exitInterviewAttributes = data?.performanceQuestionsList || [];
          let question: any = [];
          setExitFormFillDetails(exitInterviewAttributes);
          if (exitInterviewAttributes.length > 0) {
            exitInterviewAttributes.forEach((item: any) => {
              question.push(item?.question);
            });
            setQuestions(question);
          }
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (err: any) {
        setLoader(false);
        triggerNotification("error", "", err?.message, "topRight");
      } finally {
        setLoader(false); // Ensures the loader is hidden after the try or catch block is executed
      }
    };

    getExitFormFillDetails();
  }, []);

  return { exitFormFillDetails, loader, exitFormFillData, questions, employeeApiKey, employeeId, resMessage };
};

export default useExitFormDetails;
