import React, { useState, useEffect } from 'react'
import './LearningManagementTab.scss';
import { Button, Tabs, Input, Tooltip } from 'antd';
import { CourseTab } from './Course/CourseTab';
import ChapterandCertificateTable from './ChapterAndCertificateTable';
import AddAssessmentModal from './assessment/AddAssessmentModal';
import LearningPathTable from '../learningPath/LearningPathTable';
import { useLocation, useNavigate } from 'react-router-dom';
import AddCertificate from './AddCertificate';
import AddCertificateMain from './assessment/chapter/AddCertificateMain';
import { setFiltertData } from '../../redux/slices/courseFilterSlice';
import { useDispatch, useSelector } from 'react-redux';
import CourseBreadCrums from './CourseDetails/CourseBreadCrums';
import { chapterNav, courseNav, learningPathNav } from '../Organization/TableColumn';
import { debounce } from 'lodash';
import BigCalender from '../Dashboard/Announcements/BigCalender';
import certificate from "../../assets/images/certificate.svg"
import Loader from '../../components/Loader/Loader';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
const { TabPane } = Tabs;

export default function LearningManagementTab() {
  const { search }: any = useLocation();
  const from: any = new URLSearchParams(search).get("from");
  const [activeTabKey, setActiveTabKey] = useState(from === 'learningPathLevel' ? '3' : '1');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCertificate, setModalCertificate] = useState<boolean>(false);
  const [certificateAddModal, setCertificateAddModal] = useState<boolean>(false);
  const [addChapterModal, setAddChapterModal] = useState<boolean>(false)
  const dispatch = useDispatch()
  const currentTab = useSelector((state: any) => state?.courseFilterSlice?.eventData);
  const navigate = useNavigate();
  const [chapterSearch, setChapterSearch] = useState('')
  const [courseSearch, setCourseSearch] = useState('')
  const [searching, setSearching] = useState('')
  const [selectedDate, setSelectedDate] = useState(new Date()); // 2024-01-12
  const [loader, setLoader] = useState<boolean>(false);

  const onChange = (date: any) => {
    setSelectedDate(date)
  }

  const items = [
    {
      key: '1',
      label: 'Course',
      children: <CourseTab chapterSearch={courseSearch} />,
    },
    {
      key: '2',
      label: 'Chapters',
      children: <ChapterandCertificateTable addChapterModal={addChapterModal} setAddChapterModal={setAddChapterModal} chapterSearch={chapterSearch} />,
    },

    {
      key: '3',
      label: 'Learning path',
      children: <LearningPathTable />,
    },
    // {
    //   key: '4',
    //   label: 'Calender',
    //   children: <BigCalender readOnly={false} selectedDate={selectedDate} onChange={onChange} />,
    // },
  ];

  const handleTabChange = (key: string) => {
    setLoader(true);
    setSearching('')
    setActiveTabKey(key);
    setTimeout(() => {
      setLoader(false);
    }, 500);

  };
  const handleClose = () => {
    setModalOpen(false);
  }
  const handleClosed = () => {
    setModalCertificate(false);
  }
  const handelpopup = (key: any) => {
    if (key === '1') {
      setModalOpen(true);
    }
    else if (key === '3') {
      navigate('/home/dashboard/newLearningPath');
    } else if (key === '2') {
      setAddChapterModal(true);
    }
  }

  const onSearch = debounce((searchTerm: string) => {
    setSearching(searchTerm)
    if (searchTerm?.length > 3 || searchTerm?.length === 0) {
      if (activeTabKey === '2') {
        setChapterSearch(searchTerm);
        setCourseSearch('')
      } else if (activeTabKey === '1') {
        setChapterSearch('');
        setCourseSearch(searchTerm)
      }
    }
  }, 100);


  const handelNavigate = () => {
    navigate('/home/dashboard/learning-management/progress');
  }
  const handelCertificate = () => {
    setCertificateAddModal(true)
  }
  const handelFilter = () => {
    dispatch(setFiltertData(!currentTab))
  }
  return (
    <div>
      {loader && <Loader />}
      <div className='page-container bg-customBg'>
        <div className='full-width-container mt-5'>
          <div className='lm-tab bg-white p-5 '>
            <div className="course-dashboard flex justify-between">
              <CourseBreadCrums naviPaths={activeTabKey === '1' ? courseNav : activeTabKey === '2' ? chapterNav : learningPathNav} />
              <div className='flex'>
                {(activeTabKey === '2' || activeTabKey === '1') && (
                  <div className="search-filter mr-4">
                    <Input
                      placeholder="Search"
                      value={searching}
                      className="search"
                      onChange={(e: any) => onSearch(e.target.value)}
                    />
                    <span className="material-symbols-outlined"> search </span>
                  </div>
                )}
                {activeTabKey === '1' && (
                  <>
                    <Tooltip placement="bottom" title="Add Certificate">
                      <Button shape="circle" size={'large'} className='mr-5 flex items-center bg-[#FFF2F8] justify-center   border-[#D34A7C]' onClick={() => handelCertificate()}>
                        <img src={certificate} alt="certificate" className='w-[25px] h-[25px]' />
                      </Button>
                    </Tooltip>
                    <Tooltip placement="bottom" title="Employee Progress">

                      <Button type="primary" shape="circle" size={'large'} onClick={() => handelNavigate()} className='mr-5'>
                        <span className="material-symbols-outlined">description</span>
                      </Button>
                    </Tooltip>
                  </>
                )}
                {activeTabKey === '3' && (
                  <Button type="primary" shape="circle" className='mr-5' size={'large'} onClick={() => handelFilter()}>
                    <span className="material-symbols-outlined">filter_alt</span>
                  </Button>
                )}
                <Tooltip placement="bottom" title={activeTabKey === '2' ? "Add Chapter" : activeTabKey === '3' ? 'Create New Learning Path' : 'Add Course'}>
                  <Button type="primary" shape="circle" size={'large'} onClick={() => handelpopup(activeTabKey)}>
                    <span className="material-symbols-outlined">
                      add
                    </span>
                  </Button>
                </Tooltip>
              </div>
            </div>
            <Tabs
              activeKey={activeTabKey}
              onChange={handleTabChange}
              className="learning-management-tab"
            >
              {items.map((item) => (
                <TabPane key={item.key} tab={item.label}>
                  <TransitionGroup>
                    {activeTabKey === item.key && (
                      <CSSTransition
                        key={item.key}
                        classNames="fade"
                        timeout={300}
                        unmountOnExit
                      >
                        <div>{item.children}</div>
                      </CSSTransition>
                    )}
                  </TransitionGroup>
                </TabPane>
              ))}
            </Tabs>
            {modalOpen && <AddAssessmentModal open={modalOpen} handleClose={handleClose} />}
            {modalCertificate && <AddCertificate open={modalCertificate} handleClosed={handleClosed} />}
            {certificateAddModal && <AddCertificateMain open={certificateAddModal} setCertificateAddModal={setCertificateAddModal} />}
          </div>
        </div>
      </div>
    </div >
  )
}