import { Yup } from "../../../../../../common/CommonExport";

export const appraisalFormValidationSchema = Yup.object().shape({
  significantAchievements: Yup.string().required("This field is required *"),
  improvementAreas: Yup.string().required("This field is required *"),
  technicalSkills: Yup.string().required("This field is required *"),
  softSkills: Yup.string().required("This field is required *"),
  appraiserComments: Yup.string().required("This field is required *"),
});
export const appraisalInitialValues = {
  significantAchievements: "",
  improvementAreas: "",
  technicalSkills: "",
  softSkills: "",
  appraiserComments: "",
};

export const ratings: any = [
  {
    value: 1,
    label: "Does not meet",
  },
  {
    value: 2,
    label: "Partially Meets",
  },
  {
    value: 3,
    label: "Meets",
  },
  {
    value: 4,
    label: "Exceeds",
  },
  {
    value: 5,
    label: "Outstanding",
  },
];
