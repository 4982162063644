import MailAction from "./MailAction";
import DOMPurify from "dompurify";

export default function MailListContent({ data }: any) {
  const cleanAndTruncateText = (text: string | null | undefined, maxLength: number = 145): string => {
    if (!text) return '';

    // Remove HTML line breaks, text line breaks, and excessive whitespace
    const cleanedText = text
      .replace(/<br\s*\/?>/gi, ' ')
      .replace(/(\r\n|\n|\r|\t)/gm, " ")
      .replace(/<[^>]*>/g, '')
      .replace(/\s\s+/g, ' ')
      .trim();

    return cleanedText.length > maxLength
      ? cleanedText.slice(0, maxLength) + "..."
      : cleanedText;
  };

  const getMailBodyData = (data: string) => {
    const sanitizedBody = data ? DOMPurify.sanitize(data) : '';
    return sanitizedBody;
  }

  const plainTextBody = data && data.body ? data.body : '';
  const truncatedMailBody = cleanAndTruncateText(plainTextBody);

  return (
    <div className='content border-right'>
      <div className="flex w-full">
        <h5 className="mr-3">{data && data.subject ? data.subject : '--'}</h5>
        {/* <MailAction data={data} /> */}
      </div>
      <p dangerouslySetInnerHTML={{ __html: getMailBodyData(truncatedMailBody) }} />
    </div>
  )
}