import React from 'react';
import { Field } from 'formik';
import { Switch } from 'antd';

interface IncrementEligibleSwitchProps {
  isEligible: boolean;
  isView: boolean;
  isPending: boolean;
  values: { incrementEligible: boolean };
  setFieldValue: (field: string, value: any) => void;
  handleEligible: (setFieldValue: (field: string, value: any) => void, values: { incrementEligible: boolean }) => void;
}

const IncrementEligibleSwitch: React.FC<IncrementEligibleSwitchProps> = ({
  isEligible,
  isView,
  isPending,
  values,
  setFieldValue,
  handleEligible,
}) => {
  return (
    <div className="review-toggle flex justify-end">
      <label htmlFor="incrementEligible" className='pr-2 pt-0'>
        Eligible for Increment?
      </label>
      <Field
        type="checkbox"
        name="incrementEligible"
        as={Switch}
        checked={isEligible}
        onChange={(event: any) => handleEligible(setFieldValue, values)}
        disabled={!!(isView === true && isPending === false)}
      />
    </div>
  );
};

export default IncrementEligibleSwitch;
