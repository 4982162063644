import React from 'react';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';

const staticToolbarPlugin = createToolbarPlugin();
const { Toolbar } = staticToolbarPlugin;

const ToolbarComponent: React.FC = () => {
  return (
    <div>
      <Toolbar />
    </div>
  );
};

export default ToolbarComponent;
export const toolbarPlugin = staticToolbarPlugin;
