import DeprovisionFormMail from './DeprovisionFormMail'
import useExitFormDetails from '../../../../../../hooks/useExitFormDetails';
import Loader from '../../../../../../components/Loader/Loader';
import onboard from "./../../../../../../assets/images/logo.png"


const EmailDeprovision = () => {
  const { loader, exitFormFillData, resMessage, exitFormFillDetails, questions, employeeApiKey, employeeId }: any = useExitFormDetails();

  return (
    <div className=" Email_Deprovision">
      {loader && <Loader />}
      {resMessage?.message === 'Employee already submitted exit form or invalid API key' ? (
        <section className="approved-screen">
          <div className="loader-block">
          </div>
          <div className="header__logo" >
            <img src={onboard} alt="OnboardingLogo" />
            <span>ALL-Aboard!</span>
          </div>
          <div className="card">
            <p>Seems Like Already Submitted</p>
          </div>
        </section >) : (
        <div className="main-container" id="formToConvert">
          <div className="probation bg-[#DFE1F1]  py-16 " >
            <section className="sheet rounded-[50px]  py-15">
              <div className="main-content pb-10 mt-10">
                <div className="logo-header">
                  <img src={exitFormFillData?.organisationLogoUrl} alt="logo" />
                </div>

                <div className="flex justify-center">
                  <h1> Exit Interview Form </h1>
                </div>
                <div className="flex justify-end">
                  <h3 className='text-[#595959] text-[18px] font-semibold '>Date : <span>{exitFormFillData?.employeeRelievingDate}</span></h3>
                </div>
                <div className=" pb-5 lg:pb-10 flex flex-col lg:flex-row">
                  <ul>
                    <li>
                      <span>Employee Name</span>
                      <span className="dept">{exitFormFillData?.employeeName}</span>
                    </li>
                    <li>
                      <span>Designation</span>
                      <span className="dept">{exitFormFillData?.employeeDesignation}</span>
                    </li>
                    <li>
                      <span>Reporting Manager</span>
                      <span className="dept">{exitFormFillData?.reportingManagerName}</span>
                    </li>
                    <li>
                      <span>Employee ID</span>
                      <span className="dept">{exitFormFillData.employeeCode}</span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span>Resignation Date
                      </span>
                      <span className="dept">{exitFormFillData.employeeResignedDate
                      }</span>
                    </li>
                    <li>
                      <span>Date Of Joining</span>
                      <span className="dept">{exitFormFillData?.employeeDateOfJoin}</span>
                    </li>
                    <li>
                      <span>Department</span>
                      <span className="dept">{exitFormFillData?.employeeDepartment}</span>
                    </li>
                    <li>
                      <span>Last Working Date</span>
                      <span className="dept">{exitFormFillData?.employeeRelievingDate}</span>
                    </li>
                  </ul>
                </div>
                <div>
                  <DeprovisionFormMail exitFormFillDetails={exitFormFillDetails} exitFormFillData={exitFormFillData} questions={questions} employeeApiKey={employeeApiKey} employeeId={employeeId} />
                </div>
              </div>
              <div>

              </div>
            </section>
          </div>
        </div>
      )}

    </div>
  )
}

export default EmailDeprovision