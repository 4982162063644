import { Tooltip } from 'antd';
import React from 'react';
import { EditorState, RichUtils } from 'draft-js'; // Assuming you're using Draft.js, adjust as necessary
import ToolbarComponent, { toolbarPlugin } from './ToolbarComponent';

export const toggleAlignment = (editorState: EditorState, alignment: string): EditorState => {
  return RichUtils.toggleBlockType(editorState, `align-${alignment}`);
};
// Helper function to check active alignment
const isAlignmentActive = (editorState: EditorState, alignment: string) => {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const blockKey = selection.getStartKey();
  const block = content.getBlockForKey(blockKey);
  const blockType = block.getType();

  return blockType === `align-${alignment}`;
};
// Define the prop types for the component
interface EditorToolbarProps {
  editorState: EditorState; // Proper type for Draft.js editorState (or replace with your custom type)
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>; // Proper type for setState function
  openCc: boolean;
  onUndo: (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => void;
  onRedo: (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => void;
  onBoldClick: (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => void;
  onItalicClick: (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => void;
  onUnderlineClick: (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => void;
  onBulletedListClick: (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => void;
  onNumberedListClick: (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => void;
  applyAlignmentPosition: (alignment: string) => void;
}

export const toggleInlineStyle = (
  editorState: EditorState,
  inlineStyle: string,
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>
): void => {
  const currentStyle = editorState.getCurrentInlineStyle();

  // Check if the inline style is already active
  const newState = currentStyle.has(inlineStyle)
    ? RichUtils.toggleInlineStyle(editorState, inlineStyle) // Remove style if active
    : RichUtils.toggleInlineStyle(editorState, inlineStyle); // Add style if inactive

  setEditorState(newState);
};
const EditorToolbar: React.FC<EditorToolbarProps> = ({
  editorState,
  setEditorState,
  openCc,
  onUndo,
  onRedo,
  onBoldClick,
  onItalicClick,
  onUnderlineClick,
  onBulletedListClick,
  onNumberedListClick,
  applyAlignmentPosition,
}) => {

  return (
    <div className={`absolute shadow-lg ${openCc ? 'bottom-[12%]' : 'bottom-[14%]'}`}>
      <div className="flex items-center">
        <Tooltip title="Undo">
          <button
            type="button"
            className="cursor-pointer text-[#757575] hover:bg-[#D34A7C24] rounded-md  px-2 pt-2"
            onClick={() => onUndo(editorState, setEditorState)}
          >
            <span className="material-symbols-outlined">undo</span>
          </button>
        </Tooltip>
        <Tooltip title="Redo">
          <button
            type="button"
            className="cursor-pointer text-[#757575] hover:bg-[#D34A7C24] rounded-md  px-2 pt-2"
            onClick={() => onRedo(editorState, setEditorState)}
          >
            <span className="material-symbols-outlined">redo</span>
          </button>
        </Tooltip>
        <ToolbarComponent />
        <Tooltip title="Bulleted list">
          <button
            type="button"
            className="cursor-pointer text-[#757575] hover:bg-[#D34A7C24] rounded-md  px-2 pt-2"
            onClick={() => onBulletedListClick(editorState, setEditorState)}
          >
            <span className="material-symbols-outlined">format_list_bulleted</span>
          </button>
        </Tooltip>
        <Tooltip title="Numbered list">
          <button
            type="button"
            className="cursor-pointer text-[#757575] hover:bg-[#D34A7C24] rounded-md  px-2 pt-2"
            onClick={() => onNumberedListClick(editorState, setEditorState)}
          >
            <span className="material-symbols-outlined">format_list_numbered</span>
          </button>
        </Tooltip>
        <Tooltip title="Align Left">
          <button
            type="button"
            // className="cursor-pointer text-[#757575] hover:bg-[#D34A7C24] rounded-md  px-2 pt-2"
            className={`cursor-pointer rounded-md  px-2 pt-2 ${isAlignmentActive(editorState, 'left') ? 'bg-[#D34A7C]' : 'text-[#757575] hover:bg-[#D34A7C24]'
              }`}
            onClick={() => applyAlignmentPosition('left')}
          >
            <span className="material-symbols-outlined">format_align_left</span>
          </button>
        </Tooltip>
        <Tooltip title="Align Center">
          <button
            type="button"
            // className="cursor-pointer text-[#757575] hover:bg-[#D34A7C24] rounded-md  px-2 pt-2"
            className={`cursor-pointer rounded-md  px-2 pt-2 ${isAlignmentActive(editorState, 'center') ? 'bg-[#D34A7C]' : 'text-[#757575] hover:bg-[#D34A7C24]'
              }`}
            onClick={() => applyAlignmentPosition('center')}
          >
            <span className="material-symbols-outlined">format_align_center</span>
          </button>
        </Tooltip>
        <Tooltip title="Align Right">
          <button
            type="button"
            // className="cursor-pointer text-[#757575] hover:bg-[#D34A7C24] rounded-md  px-2 pt-2"
            className={`cursor-pointer rounded-md  px-2 pt-2 ${isAlignmentActive(editorState, 'right') ? 'bg-[#D34A7C]' : 'text-[#757575] hover:bg-[#D34A7C24]'
              }`}
            onClick={() => applyAlignmentPosition('right')}
          >
            <span className="material-symbols-outlined">format_align_right</span>
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default EditorToolbar;
