import React, { useState } from 'react';
import { Tabs } from 'antd';
import EmployeeListOfCourse from './EmployeeListOfCourse';
import EmployeeLearningPath from './EmployeeLearningPath';
import { useLocation, useNavigate } from 'react-router-dom';
const { TabPane } = Tabs;

export default function EmployeeProgressCourseListAndLearningPath() {
  const [activeTabKey, setActiveTabKey] = useState('1');
  const { search } = useLocation();
  let items;
  const isLearningPath: any = new URLSearchParams(search).get("learningPath");
  const getNameFromUrl = (search: string): string => {
    try {
      return new URLSearchParams(search).get('name') ?? '';
    } catch (error) {
      console.error('Error parsing query parameters:', error);
      return '';
    }
  };

  const EmployeeName = {
    text: getNameFromUrl(search),
  };
  const navigate = useNavigate();
  const handleNavigate = () => navigate('/home/dashboard/learning-management');
  const handleNavigateTable = () => navigate('/home/dashboard/learning-management/progress');
  const handleNavigateDashboard = () => navigate('/home/dashboard');

  const navigationItems = [
    {
      text: 'Dashboard /',
      className: 'text-[#878787] text-[15px] cursor-pointer hover:text-pink-500',
      onClick: handleNavigateDashboard,
    },
    {
      text: 'Learning Management /',
      className: 'text-[#878787] text-[15px] cursor-pointer hover:text-pink-500',
      onClick: handleNavigate,
    },
    {
      text: 'Course /',
      className: 'text-[#878787] text-[15px] cursor-pointer hover:text-pink-500',
      onClick: handleNavigate,
    },
    {
      text: 'Employee Progress /',
      className: 'text-[#878787] text-[15px] cursor-pointer hover:text-pink-500',
      onClick: handleNavigateTable,
    },
    {
      text: EmployeeName.text,
      className: 'text-[15px] text-pink-500',
    },
  ];

  if (isLearningPath === 'false') {
    items = [
      {
        key: '1',
        label: 'Assigned Course',
        children: <EmployeeListOfCourse />,
      },]
  } else {
    items = [
      {
        key: '1',
        label: 'Assigned Course',
        children: <EmployeeLearningPath />,
      },]
  }

  const handleTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  return (
    <div>
      <div className="page-container bg-customBg">
        <div className="pl-16 pt-4">
          <ul className="flex">
            {navigationItems.map((item, index) => (
              <li
                key={index}
                className={item.className}
                onClick={item.onClick ? item.onClick : undefined}
              >
                {item.text}
              </li>
            ))}
          </ul>
        </div>
        <div className="full-width-container mt-5">
          <div className="tab-container bg-white">
            <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
              {items.map((item) => (
                <TabPane key={item.key} tab={item.label}>
                  {item.children}
                </TabPane>
              ))}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}
