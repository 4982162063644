import { Modal } from 'antd';

interface AnnouncementDeleteModalProps {
  deleteMessage: string; // The message to display in the modal
  deletePopUp: boolean; // Whether the modal is open
  setDeletePopUp: (value: boolean) => void; // Function to update modal visibility
  handleDelete: () => void; // Function to handle the delete action
  type: string;
  extraDeleteMessage: string;
}

const AnnouncementDeleteModal: React.FC<AnnouncementDeleteModalProps> = ({
  deleteMessage,
  extraDeleteMessage,
  deletePopUp,
  setDeletePopUp,
  handleDelete,
}) => {
  const confirm = () => {
    handleDelete();
  };

  const handleClose = () => {
    setDeletePopUp(false);
  };

  return (
    <div className="announcement-delete-modal-container">
      <Modal
        title=""
        centered
        open={deletePopUp}
        footer={null}
        width={600}
        closable={false}
        maskClosable={false}
        onCancel={handleClose}>
        <div className=' flex flex-col items-center justify-center m-[10px] '>
          <div className=''>
            {/* <div className='pt-4 text-[20px] mb-4  font-rubik text-[#636363]'>Are you sure want to <span className=' text-[#D34A7C]'>{deleteMessage}</span></div> */}
            <div className='pt-4 text-[20px]  font-rubik text-[#636363]'>Are you sure you want to <span >{deleteMessage}</span>
            </div>
            <div className='text-[20px] mb-4  font-rubik text-[#636363] flex items-center justify-center'>  <span>{extraDeleteMessage}</span></div>
          </div>
          <div className="mt-5 flex justify-end my-7">
            <button
              className="cursor-pointer btn-secondary text-[#D23B7D] border border-[#D23B7D] w-[140px] h-[45px] rounded-3xl text-[18px] announce-delete-btn mr-4 secondary hover:opacity-60 hover:transition-opacity"
              onClick={handleClose}
            >
              No
            </button>
            <button
              className="cursor-pointer text-white bg-[#D23B7D] w-[140px] h-[45px] rounded-3xl text-[18px] announce-delete-btn mr-4 hover:opacity-60 hover:transition-opacity"
              onClick={confirm}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AnnouncementDeleteModal;
