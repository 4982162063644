import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { ErrorMessages, triggerNotification, useGetApiRequests } from '../../../../common/CommonExport';
import FloatLabel from '../../../../components/FloatLabel';
import Loader from '../../../../components/Loader/Loader';

interface ManageLeaveModalProps {
  open: boolean;
  handelClose: () => void;
  editRecord: EmployeeRecord | null;
  getManageLeaveList: any;
}
interface EmployeeRecord {
  employeeId: string;
  firstName: string;
  lastName: string;
  employeeName: string;
  sickLeaveBalance: number;
  casualLeaveBalance: number;
  earnedLeaveBalance: number;
  permissionLeaveBalance: number;
  totalLeaves: number;
  onDuty?: number;
}

const ManageLeaveModal: React.FC<ManageLeaveModalProps> = ({ open, handelClose, editRecord, getManageLeaveList }) => {
  const [form] = Form.useForm();
  const editManageLeaveApi = useGetApiRequests('editManageLeave', 'PUT');
  const [loader, setLoader] = useState(false);

  const handleCloseModal = () => {
    handelClose();
  };

  const handleFinish = async (values: Record<string, number>) => {
    const payload = {
      employeeId: editRecord?.employeeId,
      firstName: editRecord?.firstName,
      lastName: editRecord?.lastName,
      sickLeaveBalance: Number(values.sickLeave),
      casualLeaveBalance: Number(values.casualLeave),
      earnedLeaveBalance: Number(values.earnedLeave),
      permissionLeaveBalance: Number(values.permission),

    };
    setLoader(true)
    try {
      const res: any = await editManageLeaveApi(payload);
      triggerNotification('success', '', res?.data?.status?.message, 'topRight');
      setLoader(false)
      getManageLeaveList()
    } catch (error) {
      triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
      setLoader(false)
    }
    handelClose();
  };

  return (
    <Modal
      title="Edit Leave Balance"
      centered
      open={open}
      onCancel={handleCloseModal}
      footer={null}
      maskClosable={false}
      width={780}
    >
      {loader && <Loader />}
      <Form
        form={form}
        onFinish={handleFinish}
        initialValues={{
          sickLeave: editRecord?.sickLeaveBalance,
          casualLeave: editRecord?.casualLeaveBalance,
          earnedLeave: editRecord?.earnedLeaveBalance,
          onDuty: editRecord?.onDuty,
          permission: editRecord?.permissionLeaveBalance,
        }}
      >
        <div className="flex w-full mt-8 flex-wrap ManageLeave-Modal">
          <div className="w-full flex flex-wrap px-[5px]">
            <div className="pt-4">
              <div className="form-item mr-10">
                <FloatLabel label="Sick Leave" name="sickLeave">
                  <Form.Item
                    name="sickLeave"
                    rules={[
                      { required: true, message: 'Sick Leave is required' },
                      { type: 'number', min: 0, message: 'Value must be 0 or more', transform: (value) => Number(value) },
                    ]}                  >
                    <Input className="float-control" type='number' />
                  </Form.Item>
                </FloatLabel>
              </div>
            </div>
            <div className="pt-4">
              <div className="form-item mr-10">
                <FloatLabel label="Casual Leave" name="casualLeave">
                  <Form.Item
                    name="casualLeave"
                    rules={[
                      { required: true, message: 'Sick Leave is required' },
                      { type: 'number', min: 0, message: 'Value must be 0 or more', transform: (value) => Number(value) },
                    ]}                  >
                    <Input className="float-control" type='number' />
                  </Form.Item>
                </FloatLabel>
              </div>
            </div>
            <div className="pt-4">
              <div className="form-item mr-5">
                <FloatLabel label="Earned Leave" name="earnedLeave">
                  <Form.Item
                    name="earnedLeave"
                    rules={[
                      { required: true, message: 'Sick Leave is required' },
                      { type: 'number', min: 0, message: 'Value must be 0 or more', transform: (value) => Number(value) },
                    ]}                  >
                    <Input className="float-control" type='number' />
                  </Form.Item>
                </FloatLabel>
              </div>
            </div>
            {editRecord?.onDuty && (
              <div className="pt-4">
                <div className="form-item mr-10">
                  <FloatLabel label="On Duty" name="onDuty">
                    <Form.Item
                      name="onDuty"
                      rules={[
                        { required: true, message: 'Sick Leave is required' },
                        { type: 'number', min: 0, message: 'Value must be 0 or more', transform: (value) => Number(value) },
                      ]}                    >
                      <Input className="float-control" type='number' />
                    </Form.Item>
                  </FloatLabel>
                </div>
              </div>
            )}
            <div className="pt-4">
              <div className="form-item">
                <FloatLabel label="Permission" name="permission">
                  <Form.Item
                    name="permission"
                    rules={[
                      { required: true, message: 'Sick Leave is required' },
                      { type: 'number', min: 0, message: 'Value must be 0 or more', transform: (value) => Number(value) },
                    ]}                  >
                    <Input className="float-control" />
                  </Form.Item>
                </FloatLabel>
              </div>
            </div>
            <div className="flex mt-10 mb-5 justify-end w-full">
              <Button
                type="primary"
                shape="round"
                className="addButton w-[150px] _deprovision mr-4"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                shape="round"
                className="addButton w-[150px] deprovision text-[#d23b7d]"
                htmlType="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ManageLeaveModal;
