import React, { useEffect, useState } from 'react';
import { Avatar, Switch, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { storeData, triggerNotification, useGetApiRequests, useSelector } from '../../common/CommonExport';
import { useDispatch } from 'react-redux';
import { setLeaveList } from '../../redux/slices/leaveListSlice';
import AddLeavePolicyModal from './AddLeavePolicyModal';
import ActiveDeactivePopup from './ActiveConfirmationPopup';

const ChapterTable = () => {
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const leavePolicyReload = useSelector((state: any) => state?.profileReload?.leavePolicyReload);
  const dispatch = useDispatch()
  const leavePloicyApi = useGetApiRequests('getLeavePolicy', 'POST');
  const getPolicyDetails = useGetApiRequests('getPolicyDetails', "GET");
  const [loader, setLoader] = useState(false);
  const [policyList, setPolicyList] = useState<any>([])
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [policyData, setPolicyData] = useState<any>({});
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [type, setType] = useState('');
  const [record, setRecord] = useState<any>({});

  const onChange = (record: any) => {
    setRecord(record)
    if (record?.leavePolicy?.isActive === true) {
      setType('deactivate')
    } else {
      setType('activate')
    }
    setConfirmationOpen(true);
  };

  const getLeaveType = (fullName: string | null | undefined): string => {
    try {
      if (!fullName) {
        console.warn("Invalid fullName: fullName is null or undefined");
        return '';
      }

      const words = fullName.split(' ').filter(Boolean);

      if (words.length >= 2) {
        const firstNameInitial = words[0][0]?.toUpperCase();
        const lastNameInitial = words[words.length - 1][0]?.toUpperCase();
        return `${firstNameInitial}${lastNameInitial}`;
      } else if (words.length === 1) {
        return words[0][0]?.toUpperCase() || '';
      } else {
        console.warn("Invalid fullName: fullName contains no valid words");
        return '';
      }
    } catch (error) {
      console.error("An error occurred in getLeaveType:", error);
      return '';
    }
  };
  useEffect(() => {
    getLeavePolicyApi()
  }, [leavePolicyReload])
  const getLeavePolicyApi = () => {
    setLoader(true);
    const payload = {
      searchLeavePolicyDto: {
        organisationId: orgId
      },
      start: 0,
      limit: 100
    }
    leavePloicyApi(payload)
      .then((response: any) => {
        const reformedPolicyList = response?.data?.data.map((item: any, index: number) => ({
          key: item?.leavePolicy?.leavePolicyId,
          name: item?.leavePolicy,
          details: item,
          leavePolicyMappingId: item?.leavePolicyMappingId,
          leavePolicy: item?.leavePolicy,
          units: item?.leavePolicy?.unit,
          leaveType: item?.leavePolicy?.policyName,
          employmentType: item.leavePolicy?.employmentTypeId === 1 ? 'Confirmed' : 'Intern'
        }));
        const leavesWeCantTake = response?.data?.data.map((item: any, index: number) => ({
          leavePolicyMappingId: item?.leavePolicyMappingId,
          leavePolicy: item?.leavePolicy?.policyName,
          key: item?.leavePolicy?.leavePolicyId,
          checked: false
        }));
        dispatch(setLeaveList(leavesWeCantTake))
        setPolicyList(reformedPolicyList);
        setTimeout(() => {
          setLoader(false)
        }, 1000)
      })
      .catch((err: any) => {
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        triggerNotification('error', '', err?.message, 'topRight');
      });
  };

  const setModalOpen = () => {
    setIsModalVisible(true)
  }
  const getPolicyDetailsApi = (record: any) => {
    storeData('leavePolicyMappingId', record?.leavePolicyMappingId, true);
    setLoader(true);
    const params = {
      leavePolicyMappingId: record?.leavePolicyMappingId
    }
    getPolicyDetails('', {}, params)
      .then((response: any) => {
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        setPolicyData(response?.data?.data)
        setModalOpen()
      })
      .catch((err: any) => {
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        triggerNotification('error', '', err?.message, 'topRight');
      });
  }
  const columns: ColumnsType<any> = [
    {
      title: 'Leave Type',
      dataIndex: 'leaveType',
      width: "25%",
      render: (text) => (
        <div className='flex items-center leave-type  '>
          <Avatar.Group
            maxCount={3}
            maxPopoverTrigger="click"
            size="large"
            maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}
          >
            <Avatar className={"leave-type-" + text} >{getLeaveType(text)}</Avatar>
          </Avatar.Group>
          <p className='ml-2'>{text}</p>
        </div>
      ),
    },
    {
      title: 'Employment type',
      dataIndex: 'employmentType',
      width: "25%",

    },
    {
      title: 'Units ',
      dataIndex: 'units',
      width: "30%",
    },
    {
      title: "Active status",
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: any) => (
        <div className='flex'>
          <Switch checked={record?.details?.leavePolicy?.isActive} onChange={() => onChange(record)} />
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'editButton',
      render: (_: any, record: any) => (
        <div className='flex'>
          <Tooltip placement="top" title="Edit">
            <span className="material-symbols-outlined mr-4 cursor-pointer hover:text-primary" onClick={() => getPolicyDetailsApi(record)} >Edit</span>
          </Tooltip>
        </div>
      ),
    },
  ]

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className='leave-management user-table head'>
      <Table columns={columns} dataSource={policyList} loading={loader} />
      {isModalVisible && <AddLeavePolicyModal open={isModalVisible} handelClose={handleCancel} isEdit={true} policyData={policyData} />}
      {confirmationOpen && <ActiveDeactivePopup closeConfirmationModal={confirmationOpen} setCloseConfirmationModal={setConfirmationOpen} type={type} record={record} />}
    </div>
  )
}

export default ChapterTable