import React, { useState } from 'react';
import { Tabs } from 'antd';
import RegularEmployee from './RegularEmployee/RegularEmployee';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
const { TabPane } = Tabs;

const SalaryStructure = () => {
    const [activeTabKey, setActiveTabKey] = useState('1');

    const items = [
        {
            key: '1',
            label: 'Regular Employee',
            children: <RegularEmployee activeTabKey={activeTabKey} />,
        },
        {
            key: '2',
            label: 'Intern',
            children: <RegularEmployee activeTabKey={activeTabKey} />,
        },
    ]
    const handleTabChange = (key: string) => {

        setActiveTabKey(key);
    };
    return (
        <div className='page-container bg-customBg'>
            <div className="full-width-container">
                <h1 className=' organization_title  py-5 flex'>
                    <Link to='/home/dashboard'>
                        <span className='organization_back-arrow pr-4 hover:text-black'>
                            <ArrowLeftOutlined />
                        </span>
                    </Link>Salary Structure</h1>
                <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
                    {items.map(item => (
                        <TabPane key={item.key} tab={item.label}>
                            {item.children}
                        </TabPane>
                    ))}
                </Tabs>
            </div>
        </div>

    )
}

export default SalaryStructure;