import { ErrorMessages, triggerNotification } from "../../../../common/CommonExport";

interface LeaveParams {
  start: number;
  limit: number;
  organisationId: string;
  isActive: boolean;
  searchName: string;
  search?: string;
}

interface EmployeeRecord {
  employeeId: string;
  firstName: string;
  lastName: string;
  employeeName: string;
  sickLeaveBalance: number;
  casualLeaveBalance: number;
  earnedLeaveBalance: number;
  permissionLeaveBalance: number;
  totalLeaves: number;
  totalLeaveBalance: number;
}

export const getBalancveLeaveList = async ({ setLoader, currentPage, pageSize, orgId, searchQuery, searchTerm, getBalanceLeaveApi, setLeaveDataList, setCount }: any) => {
  setLoader(true);
  try {
    const start: number = currentPage - 1 === 0 ? 0 : currentPage - 1;
    const params: LeaveParams = {
      start: start * 10,
      limit: pageSize,
      organisationId: orgId,
      isActive: true,
      searchName: searchQuery,
    };
    if (searchTerm.trim()) {
      params.search = searchTerm.trim();
    }
    const response: any = await getBalanceLeaveApi("", params);
    const data = response?.data?.data;

    if (data) {
      const manageLeaveList = data.map((item: EmployeeRecord) => {
        const sickLeaveBalance = item.sickLeaveBalance || 0;
        const casualLeaveBalance = item.casualLeaveBalance || 0;
        const earnedLeaveBalance = item.earnedLeaveBalance || 0;
        const permissionLeaveBalance = item.permissionLeaveBalance || 0;
        const employeeId = item.employeeId || "";
        const totalLeaves = item?.totalLeaveBalance || 0;
        return {
          employeeId,
          firstName: item.firstName || "",
          lastName: item.lastName || "",
          employeeName: `${item.firstName || ""} ${item.lastName || ""}`.trim(),
          sickLeaveBalance,
          casualLeaveBalance,
          earnedLeaveBalance,
          permissionLeaveBalance,
          totalLeaves,
        };
      });
      setLeaveDataList(manageLeaveList);
      setCount(response?.data?.totalResults);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } else {
      triggerNotification("error", "", "Invalid response structure.", "topRight");
    }
  } catch (error: any) {
    triggerNotification("error", "", ErrorMessages.somethingWentWrong, "topRight");
  } finally {
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }
};
