import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import onboard from "./../../../../../../assets/images/logo.png"
import { triggerNotification, useGetApiRequests } from '../../../../../../common/CommonExport';
import Loader from '../../../../../../components/Loader/Loader';


const DeprovisionApproval = () => {
  const deprovisionApprovalApi = useGetApiRequests('deprovisionApproval', 'PUT');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const designationEffectiveFrom: any = searchParams.get('designationEffectiveFrom');

  const [approvedMessage, setapprovedMessage] = useState<any>(null)
  const [alreadySubmitted, setAlreadySubmitted] = useState<boolean>(false)
  const [loader, setLoader] = useState<boolean>(true)

  useEffect(() => {
    const queryString = window.location.search;
    const isValidUrl: boolean = window.location.href.includes('/employee-deprovision-update-status');

    if (isValidUrl) {
      const urlParams = new URLSearchParams(queryString);
      const pathParams: any = {};
      urlParams.forEach((value, key) => {
        pathParams[key] = value;
      });
      deprovisionApproval(pathParams)
    }
  }, []);

  const deprovisionApproval = (pathParams: any) => {
    const employeeDeprovisionIdString = pathParams["employeeDeprovisionId"];
    const id = employeeDeprovisionIdString.split(' ');
    const filteredWords = id.filter((str: any, index: number) => index % 2 === 0);
    const resultId = filteredWords.join(' ');

    const employeeDeprovisionId = resultId;

    const payload: any = {
      employeeId: pathParams["employeeId"],
      inchargeEmployeeId: pathParams["inchargeEmployeeId"],
      employeeApiKey: pathParams["employeeApiKey"],
      statusId: pathParams["statusId"],
      roleName: pathParams["roleName"],
    }

    const pathParamsToUrl: any = {
      id: `${employeeDeprovisionId}/mail-approval`
    }
    deprovisionApprovalApi(payload, {}, pathParamsToUrl)
      .then((res: any) => {
        const message = res?.data?.status?.message;
        setAlreadySubmitted(message == 'Deprovision Approval has Already submitted' ? true : false);
        setapprovedMessage(res?.data)
        setLoader(false)
      })
      .catch((err: any) => {
        setLoader(false)
        setAlreadySubmitted(true)
      })
  }

  return (
    <>
      {loader && <Loader />}
      <section className="approved-screen">
        <div className="loader-block">
        </div>
        <div className="header__logo" >
          <img src={onboard} alt="OnboardingLogo" />
          <span>ALL-Aboard!</span>
        </div>
        {
          !loader ? <>{alreadySubmitted ? (<div className="card"><p><span>Seems Like Already Submitted</span></p></div>) : (
            <div className="card">
              {approvedMessage?.status?.message === 'Designation Approved Successfully' ||
                approvedMessage?.status?.message === 'Employee Bonus Approved Successfully' ||
                approvedMessage?.message === 'Leave approved successfully' ||
                approvedMessage?.data?.status?.message === 'Deprovision Status Updated Successfully' ||
                approvedMessage?.status?.message === 'Deprovision Status Updated Successfully'

                ? (
                  <>
                    <p><span className="material-symbols-outlined">check_circle</span> </p>
                    <p>Approved </p>
                  </>
                ) : (
                  <>
                    <p> <span className="rejected-designation material-symbols-outlined rejected">cancel</span></p>
                    <p>Cancel</p>
                  </>
                )}
            </div>
          )}</> : null
        }

      </section >
    </ >
  )
}

export default DeprovisionApproval
