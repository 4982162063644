import React, { useEffect, useState } from 'react';
import { Pagination, Input, PaginationProps, Tooltip, Button } from 'antd';
import DynamicTable from '../../../components/CommonTable/DynamicTable';
import { ErrorMessages, triggerNotification, useGetApiRequests, useSelector, debounce, Icons } from '../../../common/CommonExport';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import ImagesComponent from '../../../components/Images/Images';
import { Designationcolumns } from '../TableColumn';
import { handleDeleteDesignation } from '../OrganisationHelper';
import CreateDesignationModal from './CreateDesignationModal';
import ViewTextArea from './ViewTextArea';
import Loader from '../../../components/Loader/Loader';
import ModalLoader from '../../../components/Loader/ModalLoader';

const { Search } = Input;

const Designation: React.FC = () => {
    const deleteDesignation = useGetApiRequests('designationDelete', 'DELETE');
    const orgId = useSelector((state: any) => state?.organisationId?.id);
    const designationApi = useGetApiRequests('designation', 'GET');
    const [designation, setDesignation] = useState([]);
    const [deletePopUp, setDeletePopUp] = useState(false);
    const [selectedRows, setSelectedRows]: any = useState([]);
    const [designationPopup, setDesignationPopup] = useState(false);
    const [editRecord, setEditRecord] = useState({});
    const [editModal, setEditModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [isSingleDelete, setIsSingleDelete] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [viewQuill, setViewQuill] = useState(false);
    const [loader, setLoader] = useState(false);
    const [count, setCount] = useState<number>();

    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, size) => {
        setCurrentPage(1);
        setPageSize(size);
    };
    const onSearch = debounce((searchTerm: string) => {
        designationGetApi(searchTerm);
    }, 500);
    const handleEditRow = (record: any) => {
        setDesignationPopup(true);
        setEditRecord(record);
        setEditModal(true);
    };
    const handleSingleDelete = (record: any) => {
        setDeletePopUp(true);
        setIsSingleDelete(true);
        const recordArray: any = [];
        recordArray.push(record);
        setSelectedRows(recordArray);
        setDeleteMessage(`Are you sure you want to delete ${record.designation} ?`);
    };
    const handleQuill = (record: any) => {
        setViewQuill(true);
        setEditRecord(record);
        setEditModal(true);
    };
    const columns = [
        ...Designationcolumns,
        {
            title: 'Action',
            dataIndex: 'deleteButton, editButton',
            render: (_: any, record: any) => (
                <div className='flex'>
                    <Tooltip placement="top" title="View">
                        <img src={Icons.eyeOpen} onClick={() => handleQuill(record)} alt='Edit' className='w-5 mr-3 cursor-pointer hover:text-primary' />
                    </Tooltip>
                    <Tooltip placement="top" title="Edit">
                        <span className="material-symbols-outlined mr-4 cursor-pointer hover:text-primary" onClick={() => handleEditRow(record)} >
                            edit
                        </span>
                    </Tooltip>
                    <Tooltip placement="top" title="Delete">
                        <span className="material-symbols-outlined mr-4 cursor-pointer hover:text-primary" onClick={() => handleSingleDelete(record)}>
                            delete
                        </span>

                    </Tooltip>

                </div>
            ),
        },
    ];

    const renderRolesAndResponsibilites = (values: any) => {
        const decodeHtmlEntities = (str: any) => {
            return str.replace(/&#(\d+);/g, (match: any, dec: any) => {
                return String.fromCharCode(dec);
            });
        };

        let finalOutputArray: any = [];
        let finalOutput = values.split('</p></li>');
        finalOutput.forEach((item: any) => {
            let decodedItem = decodeHtmlEntities(item);
            let withoutHtmlTags = decodedItem.replace(/<[^>]*>/g, '');
            if (withoutHtmlTags.trim() !== '') {
                finalOutputArray.push(withoutHtmlTags.trim());
            }
        });

        let finalRolesAndResponsibilities = finalOutputArray.join('; ');
        return finalRolesAndResponsibilities;
    };

    const designationGetApi = (searchTerm = '') => {
        const start: number = (currentPage - 1 === 0) ? 0 : currentPage - 1;
        const params: any = {
            start: start,
            limit: pageSize,
            organisationId: orgId,
            isActive: true,
        };
        if (searchTerm !== '') {
            params.search = searchTerm
        }
        if (searchTerm === '') {
            setLoader(true);
        } else {
            setTimeout(() => {
                setLoader(false)
            }, 1000)
        }
        designationApi('', params)
            .then((response: any) => {
                const reformedDesignation = response?.data?.data.map((item: any, index: number) => ({
                    designation: item?.name,
                    department: item?.department?.name,
                    departmentId: item?.department?.departmentId,
                    grade: item?.grade?.name,
                    gradeId: item?.grade?.cntGradeId,
                    rolesAndResponsibilities: item?.rolesAndResponsiblities && renderRolesAndResponsibilites(item?.rolesAndResponsiblities),
                    key: item?.designationId,
                    quilldata: item?.rolesAndResponsiblities,
                }));
                setDesignation(reformedDesignation);
                setCount(response?.data?.totalResults);
                setTimeout(() => {
                    setLoader(false)
                }, 1000)
            })
            .catch((err: any) => {
                setTimeout(() => {
                    setLoader(false)
                }, 1000)
                triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
            });
    };
    useEffect(() => {
        const designationTag: any = document.getElementsByClassName("ant-table-content")
        designationTag[1].scrollTop = 0
        designationGetApi();
    }, [currentPage, pageSize])

    const handleSelectedRows = (selected: any) => {
        setSelectedRows(selected)
        setDeleteMessage(`Are you sure you want to delete these ${selected.length} designations?`);
    };
    const handleCloseDelete = () => {
        setDeletePopUp(false);
        setIsSingleDelete(false);

    }
    const handleDeleteSelected = async () => {
        handleDeleteDesignation(deleteDesignation, selectedRows, designationGetApi);
        setSelectedRows([])
    };

    const handleSingleSelectDelete = async (record: any) => {
        const deleteArray: any = [];
        setDeletePopUp(false);
        const id = selectedRows[0].key;
        deleteArray.push(id);
        handleDeleteDesignation(deleteDesignation, deleteArray, designationGetApi, '');
        setSelectedRows([])

    };
    const handelDesignationPopUp = () => {
        setDesignationPopup(true);
        setEditModal(false);
    };
    const handleClose = () => {
        setDesignationPopup(false);
        setViewQuill(false);
    };
    return (
        <div className='organization-table'>
            <div className='flex justify-between px-4 pb-5'>
                <div className='search-field '>
                    <Search placeholder='Search' onChange={e => onSearch(e.target.value)} enterButton />
                </div>
                <div className='flex gap-5'>
                    {selectedRows && selectedRows?.length > 0 && !isSingleDelete && (
                        <Tooltip placement="top" title="Delete Designation">
                            <Button
                                type="primary"
                                shape="circle"
                                className="flex items-center justify-center font-bold"
                                icon={<span className="material-symbols-outlined"> delete </span>}
                                size="large"
                                onClick={() => setDeletePopUp(true)}
                            >
                            </Button>
                        </Tooltip>
                    )}
                    <Tooltip placement="top" title="Create Designation">
                        <Button
                            type="primary"
                            shape="circle"
                            className="flex items-center justify-center font-bold"
                            icon={<span className="material-symbols-outlined"> add </span>}
                            size="large"
                            onClick={handelDesignationPopUp}
                            disabled={!!(selectedRows && selectedRows?.length > 0)}
                        >
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <DynamicTable columns={columns} data={designation} handleSelectedRows={handleSelectedRows} loading={loader} />
            <div className='pagination text-right px-6 pt-6'>

                <Pagination className='float-right flex flex-row ml-2' showSizeChanger onShowSizeChange={onShowSizeChange} defaultCurrent={1} current={currentPage} total={count} pageSize={pageSize} onChange={(page) => setCurrentPage(page)} />
            </div>
            {deletePopUp && <DeleteConfirmationModal open={deletePopUp} handleSingleSelectDelete={handleSingleSelectDelete} handleDeleteSelected={handleDeleteSelected} handleCloseDelete={handleCloseDelete} selectedRows={selectedRows} type='designation' isSingleDelete={isSingleDelete} deleteMessage={deleteMessage} />}
            {designationPopup && <CreateDesignationModal isOpen={designationPopup} handleClose={handleClose} handelDesignationPopUp={handelDesignationPopUp} designationGetApi={designationGetApi} editRecord={editRecord} editModal={editModal} />}
            {viewQuill && <ViewTextArea open={viewQuill} handleClose={handleClose} editRecord={editRecord} editModal={editModal} />}
            {/* {loader && <ModalLoader />} */}
        </div>
    );
};
export default Designation;
