export const processQuestions = (editedKey: any, currentQuestions: any[], initDataStore: any[], tempQuestionList: any[]): void => {
  if (currentQuestions && initDataStore) {
    // Initialize tempQuestionList for the editedKey
    tempQuestionList[editedKey] = tempQuestionList[editedKey] || [];

    // Use a Set to track processed question items
    const processedQuestionIds = new Set();

    currentQuestions.forEach((questionItem: any) => {
      const matchedPerformanceItem = initDataStore.find((performanceItem: any) => performanceItem.questionaire?.questionaireId === questionItem.questionaireId);

      if (matchedPerformanceItem) {
        // Process matching item
        const tempData = {
          ...questionItem,
          answer: matchedPerformanceItem?.otherText,
          range: matchedPerformanceItem?.performanceRatingScale?.performanceRatingScaleId,
          employeeReviewDetailId: matchedPerformanceItem?.employeeReviewDetailId,
          otherText: matchedPerformanceItem?.otherText,
        };
        if (editedKey === "appraiserfeedback") {
          let questionKey = questionItem?.question.split(" ").join("").toLowerCase();
          tempQuestionList[editedKey][questionKey] = tempData;
        } else {
          tempQuestionList[editedKey]?.push(tempData);
        }

        processedQuestionIds.add(questionItem.questionaireId); // Mark as processed
      } else if (!processedQuestionIds.has(questionItem.questionaireId)) {
        // Add fallback only if not already processed
        const fallbackData = {
          ...questionItem,
          additionalInfo: "No matching performance item found",
          answer: "",
          range: null,
        };
        tempQuestionList[editedKey]?.push(fallbackData);
        processedQuestionIds.add(questionItem.questionaireId); // Mark as processed
      }
    });
  }
};

export const processQuestionData = (response: any, item: any, editedKey: any, tempQuestionList: any[]): any[] => {
  const questions = response?.data?.data?.questions?.[item] || [];

  const processedQuestions = questions.map((data: any) => ({
    ...data,
    answer: "",
    range: null,
  }));

  tempQuestionList[editedKey] = tempQuestionList[editedKey] || [];
  if (!Array.isArray(tempQuestionList[editedKey])) {
    tempQuestionList[editedKey] = [];
  }
  if (editedKey === "appraiserfeedback") {
    let questionKey = item?.question?.split(" ").join("").toLowerCase();
    tempQuestionList[editedKey][questionKey] = processedQuestions;
  } else {
    tempQuestionList[editedKey]?.push(...processedQuestions);
  }

  return processedQuestions; // Return the processed questions
};
