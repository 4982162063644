import { MenuProps } from "antd";
import { EditorState, RichUtils, CompositeDecorator, ContentState, convertFromHTML, Modifier, ContentBlock } from "draft-js";

// Font Family Menu Items
export const fontItems = (setFontFamily: (family: string) => void): MenuProps["items"] => [
  {
    key: "1",
    label: "Arial",
    onClick: () => setFontFamily("Arial"),
  },
  {
    key: "2",
    label: "Courier New",
    onClick: () => setFontFamily("Courier New"),
  },
  {
    key: "3",
    label: "Georgia",
    onClick: () => setFontFamily("Georgia"),
  },
  {
    key: "4",
    label: "Times New Roman",
    onClick: () => setFontFamily("Times New Roman"),
  },
  {
    key: "5",
    label: "Verdana",
    onClick: () => setFontFamily("Verdana"),
  },
];

export const onBoldClick = (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => {
  setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
};

export const onItalicClick = (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => {
  setEditorState(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
};

export const onUnderlineClick = (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => {
  setEditorState(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
};

export const onAlignLeftClick = (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => {
  setEditorState(RichUtils.toggleBlockType(editorState, "left-aligned"));
};

export const onAlignCenterClick = (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => {
  setEditorState(RichUtils.toggleBlockType(editorState, "center-aligned"));
};

export const onAlignRightClick = (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => {
  setEditorState(RichUtils.toggleBlockType(editorState, "right-aligned"));
};

export const onBulletedListClick = (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => {
  setEditorState(RichUtils.toggleBlockType(editorState, "unordered-list-item"));
};

export const onNumberedListClick = (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => {
  setEditorState(RichUtils.toggleBlockType(editorState, "ordered-list-item"));
};

export const onUndo = (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => {
  const newState = EditorState.undo(editorState);
  setEditorState(newState);
};

export const onRedo = (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => {
  const newState = EditorState.redo(editorState);
  setEditorState(newState);
};

export const convertTo24HourFormat = (time12hr: string): string => {
  const [time, modifier] = time12hr.split(" "); // Split into time and AM/PM
  let [hours, minutes] = time.split(":").map(Number);

  if (modifier === "PM" && hours < 12) {
    hours += 12; // Convert PM hours
  } else if (modifier === "AM" && hours === 12) {
    hours = 0; // Convert 12 AM to 00:00
  }

  // Format the hours and minutes to two digits (e.g., "09", "05")
  const hours24 = hours.toString().padStart(2, "0");
  const minutesFormatted = minutes.toString().padStart(2, "0");

  return `${hours24}:${minutesFormatted}`;
};
export const addLinkRef = (linkURL: string, editorState: any) => {
  if (linkURL.trim()) {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const contentStateWithEntity = contentState.createEntity(
      "LINK", // Entity type
      "MUTABLE", // Entity mutation type (use IMMUTABLE if you don't need to update the URL)
      { url: linkURL }, // Entity data (URL)
    );
    // Get the entity key for the link
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const contentStateWithLink = Modifier.applyEntity(contentStateWithEntity, selectionState, entityKey);
    const newEditorState = EditorState.push(editorState, contentStateWithLink, "apply-entity");
    return newEditorState;
  }
};

export const blockStyleFn = (block: ContentBlock): string => {
  let alignment = "left"; // Default alignment

  block.findStyleRanges(
    (charMeta: any) => {
      // Check for specific styles: 'center' or 'right'
      return charMeta.hasStyle("center") || charMeta.hasStyle("right");
    },
    (start: number, end: number) => {
      const charMetaList = block.getCharacterList().slice(start, end);
      for (let i = 0; i < charMetaList.size; i++) {
        const charMeta = charMetaList.get(i);
        if (charMeta.hasStyle("center")) {
          alignment = "center";
          break; // Exit loop as soon as alignment is determined
        } else if (charMeta.hasStyle("right")) {
          alignment = "right";
          break;
        }
      }
    },
  );
  return `editor-alignment-${alignment}`;
};
// Utility function to extract alignment from HTML block styles
export const alignmentStyles = ["left", "right", "center"];
export const applyAlignment = (newStyle: any, editorState: any) => {
  let styleForRemove = alignmentStyles.filter((style) => style !== newStyle);
  let currentContent = editorState.getCurrentContent();
  let selection = editorState.getSelection();
  let focusBlock = currentContent.getBlockForKey(selection.getFocusKey());
  let anchorBlock = currentContent.getBlockForKey(selection.getAnchorKey());
  let isBackward = selection.getIsBackward();

  let selectionMerge = {
    anchorOffset: 0,
    focusOffset: focusBlock.getLength(),
  };

  if (isBackward) {
    selectionMerge.anchorOffset = anchorBlock.getLength();
  }
  let finalSelection = selection.merge(selectionMerge);
  let finalContent = styleForRemove.reduce((content, style) => Modifier.removeInlineStyle(content, finalSelection, style), currentContent);
  let modifiedContent = Modifier.applyInlineStyle(finalContent, finalSelection, newStyle);
  const nextEditorState = EditorState.push(editorState, modifiedContent, "change-inline-style");
  return nextEditorState;
};

export const blockStyleFn1 = (block: ContentBlock): { style?: { textAlign: string }; className?: string } => {
  let alignment = "left"; // Default alignment

  block.findStyleRanges(
    (charMeta: any) => {
      // Filter function: checks if the character has a specific style
      return charMeta.hasStyle("center") || charMeta.hasStyle("right");
    },
    (start: number, end: number) => {
      // Callback function: executes when a style range is found
      const charMeta = block.getCharacterList().slice(start, end);
      // Check each character in the range for the 'center' or 'right' styles
      charMeta.forEach((meta: any) => {
        if (meta.hasStyle("center")) {
          alignment = "center";
        }
        if (meta.hasStyle("right")) {
          alignment = "right";
        }
      });
    },
  );

  // Return an object with either a className or style property
  return { style: { textAlign: alignment } };
};
