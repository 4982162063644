import { useEffect, useState } from 'react';
import { Avatar, Pagination, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { useGetApiRequests } from '../../../common/CommonExport';
import ModalLoader from '../../../components/Loader/ModalLoader';
import ManageLeaveModal from './modal/ManageLeaveModal';
import { getBalancveLeaveList } from './helper/ManageLeaveHelper';

interface EmployeeRecord {
  employeeId: string;
  firstName: string;
  lastName: string;
  employeeName: string;
  sickLeaveBalance: number;
  casualLeaveBalance: number;
  earnedLeaveBalance: number;
  permissionLeaveBalance: number;
  totalLeaves: number;
}

interface ManageLeaveTableProps {
  searchQuery: string;
}

const ManageLeaveTable: React.FC<ManageLeaveTableProps> = ({ searchQuery }) => {
  const getBalanceLeaveApi = useGetApiRequests('getBalanceLeave', 'GET');
  const [loader, setLoader] = useState(false);
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [leaveDataList, setLeaveDataList] = useState<EmployeeRecord[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [editRecord, setEditRecord] = useState<EmployeeRecord | null>(null);
  const [count, setCount] = useState<number>(0);

  const onShowSizeChange = (current: number, newSize: number) => {
    setPageSize(newSize);
    setCurrentPage(1);
  };
  useEffect(() => {
    const tags = document.getElementsByClassName('ant-table-content')
    tags[1].scrollTop = 0
    getManageLeaveList(searchQuery)
  }, [currentPage, pageSize, searchQuery])

  const getManageLeaveList = (searchTerm = '') => {
    getBalancveLeaveList({ setLoader, currentPage, pageSize, orgId, searchQuery, searchTerm, getBalanceLeaveApi, setLeaveDataList, setCount })
  };

  const getLeaveType = (fullName: string): string => {
    const words = fullName?.split(' ');
    if (words?.length >= 2) {
      const firstNameInitial = words[0][0].toUpperCase();
      const lastNameInitial = words[words?.length - 1][0].toUpperCase();
      return `${firstNameInitial}${lastNameInitial}`;
    } else if (words?.length === 1) {
      return words[0][0].toUpperCase();
    } else {
      return '';
    }
  };
  const handleEditLeave = ((record: EmployeeRecord) => {
    setIsModalVisible(true)
    setEditRecord(record)

  })
  const columns: ColumnsType<EmployeeRecord> = [
    {
      title: 'Employee Id',
      dataIndex: 'employeeId',
      width: "30%",

    },
    {
      title: 'Employee Name',
      dataIndex: 'employeeName',
      width: "30%",
      render: (text) => (
        <div className='flex items-center leave-type  '>
          <Avatar.Group
            maxCount={3}
            maxPopoverTrigger="click"
            size="large"
            maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}
          >
            <Avatar className={"leave-type-" + text} >{getLeaveType(text)}</Avatar>
          </Avatar.Group>
          <p className='ml-2'>{text}</p>
        </div>
      ),
    },
    {
      title: 'Total Leaves ',
      dataIndex: 'totalLeaves',
      width: "30%",
    },
    {
      title: 'Action',
      dataIndex: 'editButton',
      render: (_, record) => (
        <div className='flex'>
          <Tooltip placement="top" title="Edit">
            <span className="material-symbols-outlined mr-4 cursor-pointer hover:text-primary" onClick={() => handleEditLeave(record)}>Edit</span>
          </Tooltip>
        </div>
      ),
    },
  ]

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className='leave-management user-table head'>
      <Table columns={columns} dataSource={leaveDataList} pagination={false} loading={loader} />
      <div className='pagination'>
        <Pagination
          className='float-right flex flex-row p-6'
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          current={currentPage}
          total={count}
          pageSize={pageSize}
          onChange={(page) => setCurrentPage(page)}
        />
      </div>
      {isModalVisible && <ManageLeaveModal open={isModalVisible} handelClose={handleCancel} editRecord={editRecord} getManageLeaveList={getManageLeaveList} />}
      {/* {loader && <ModalLoader />} */}
    </div>
  )
}

export default ManageLeaveTable;