import React from "react";
import Title from "../Title";
import AnnouncementSidebar from "../sidebar/AnnouncementSidebar";
import { Link } from 'react-router-dom';

interface Props {
  // Define any props that are passed to WrappedComponent
}

const AnnouncementHOC = <P extends object>(WrappedComponent: React.ComponentType<P>) => (props: any) => {


  return (
    <>

      <div className='page-container bg-customBg w-full active_ flex '>
        <div className="w-full">
          <div className='full-width-container announcement mt-5 active-history'>
            <div className=' bg-white p-5 w-full h-full __announcement'>
              <div className='' >
                <ul className='flex'>
                  <Link to='/home/dashboard'>
                    <li className='text-[#878787] text-[16px] cursor-pointer hover:text-pink-500' >Dashboard /</li>
                  </Link>
                  <li className='text-[16px] pl-1'>Announcement</li>
                </ul>
                <Title />
                <div className='announcement-mail flex'>
                  <div className='announcement-sidebar'>
                    <AnnouncementSidebar />
                  </div>
                  <div className='announcement-mailBody w-full overflow-auto'>
                    <WrappedComponent {...props as P} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnnouncementHOC;
