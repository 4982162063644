import React from 'react'
import ChapterCard from './ChapterCard'
import "./EmployeeProgress.scss"

export default function EmployeeListOfCourse() {
  return (
    <div className='employee-progress'>
      <div className='grid grid-cols-2'>
        <div className='col-span-2'>
          <div className="py-4 px-4 bg-[#FFF0F5] rounded-md mb-4  ">
            <p className='text-primary  font-rubik font-bold' >List of Course</p>
          </div>
          <div className=' '>
            <ChapterCard />
          </div>
        </div>

      </div>
    </div>

  )
}
