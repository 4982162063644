export const unitList = [
  { value: "days", label: "Days" },
  { value: "hours", label: "Hours" },
];

export const employeeType = [
  { value: "1", label: "Regular" },
  { value: "2", label: "Intern" },
];

export const yearsAndMonths = [
  { value: "year", label: "Year(s)" },
  { value: "month", label: "Month(s)" },
];
export const effectiveFromList = [
  { value: "DOJ", label: "Date of Joining" },
  { value: "DOC", label: "Date of Confirmation" },
];
export const accuralType = [
  { value: "yearly", label: "Yearly" },
  { value: "monthly", label: "Monthly" },
  { value: "weekly", label: "Weekly" },
];
export const leaveCount = [
  { label: "Count as leave", value: true },
  { label: "Don't count as leave", value: false },
];
export const leaveBalance = [
  { label: "Allow(Consider them as LOP)", value: true },
  { label: "Don't Allow", value: false },
];
