const EmployeeAppraisalCard = ({
  item,
  handleView,
  reviewType,
}: {
  item: any;
  handleView: (item: any, id: any) => void;
  reviewType: string | null;
}) => {

  const dateConvertion = (date: any) => {
    let spliteValue = date.split('T')[0];
    let value = spliteValue;
    let dateValue = value.split('-')
    return dateValue[2] + '-' + dateValue[1] + '-' + dateValue[0];
  }
  return (

    <div
      key={item?.employeeReviewId}
      className="employee-appraisal-card cursor-pointer"
      onClick={() => handleView(item, item?.employeeReviewId)}
    >
      <p className="employee-appraisal-title">{item?.reviewFormName}</p>
      <div className="grid-rows-4 grid-flow-col gap-4 flex asset-header pt-3">
        {item?.reviewedOn !== null ? (
          <div className="header-status header-border">
            Review date:
            <span>
              {item?.reviewedOn !== null ? dateConvertion(item?.reviewedOn) : "-"}
            </span>
          </div>
        ) : (
          <div className="header-status header-border">
            Probation Extension end date:
            <span>
              {item?.nextProbationEndDate !== null
                ? dateConvertion(item?.nextProbationEndDate)
                : "-"}
            </span>
          </div>
        )}
        <div className={`header-status  ${item?.reviewStatus !== "Completed" && "header-border"}`}>
          Review Status: <span>{item?.reviewStatus}</span>
        </div>
        {item?.type !== "Probation" &&
          item?.type !== "Probation Extension" &&
          item?.reviewStatus !== "Completed" &&
          reviewType !== null && (
            <div className="header-status">
              Eligible for Increment:
              <span>
                {item?.eligibleForIncrement === false ||
                  item?.eligibleForIncrement === null
                  ? "No"
                  : "Yes"}
              </span>
            </div>
          )}
      </div>
    </div>
  );
};
export default EmployeeAppraisalCard