import React, { useState, useEffect } from 'react';
import { notification, Upload, Modal } from 'antd';
import { UploadFile, RcFile } from 'antd/lib/upload/interface';
import "./ImageUploadElms.scss"
import { useSelector } from 'react-redux';
import DocumentPDFView from '../../../employee/employee-details/employee-profile/layout/documents/documentPDFView';

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
export const handlePreview = async (file: UploadFile, setPreviewImage: any, setPreviewOpen: any, setPreviewTitle: any, pdfUrlShow: any) => {
  if (!file.url && !file.preview) {
    file.preview = await getBase64(file.originFileObj as RcFile);
    setPreviewImage(file.url ?? (file.preview as string));
  } else {
    setPreviewImage(pdfUrlShow);
  }
  // setPreviewImage(file.url ?? (file.preview as string));
  setPreviewOpen(true);
  setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
};

const ImageUploadElms = ({
  imageUrl,
  onImageUpload,
  onImageDelete,
  maxFileSize, // Maximum file size in bytes
  allowedTypes, // An array of allowed file types, e.g., ['image/jpeg', 'image/png', 'application/pdf']
  pdfUrl,
  certificateName,
  ConsiderCertificateName,
}: {
  imageUrl?: string;
  onImageUpload: (file: File) => void;
  onImageDelete: (file: File) => void;
  maxFileSize: number;
  allowedTypes: string[];
  pdfUrl?: string
  certificateName?: string,
  ConsiderCertificateName?: boolean;
}) => {
  const previewRemoveLoad = useSelector((state: any) => state?.profileReload?.imagePreviewRemove);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false);
  const [pdfUrlShow, setPdfUrlShow] = useState<any>('')

  useEffect(() => {
    if (imageUrl) {
      setFileList([
        {
          uid: '-1',
          name: imageUrl?.substring(imageUrl?.lastIndexOf("/") + 1),
          status: 'done',
          url: imageUrl,
        },
      ]);
    } else setFileList([])
    if (pdfUrl) {
      setPdfUrlShow(pdfUrl)
    } else setPdfUrlShow('')
  }, [imageUrl, pdfUrl]);

  useEffect(() => {
    setFileList([])
  }, [previewRemoveLoad])

  const handleCancel = () => setPreviewOpen(false);

  const handleRemove = (file: UploadFile) => {
    const updatedFileList = fileList.filter(item => item.uid !== file.uid);
    setFileList(updatedFileList);
    onImageDelete(file.originFileObj as File);
  };

  const customRequest = ({ file, onSuccess }: any) => {
    if (fileList.length > 0)
      onImageUpload(fileList[0].originFileObj as File);
    onSuccess("OK");
  };
  const beforeUpload = (file: any) => {
    const fileSize: any = file?.size;
    if (fileSize === null) return false;
    if (fileSize > maxFileSize) {
      setFileSizeExceeded(true);
      return false;
    } else {
      setFileSizeExceeded(false);
    }
    if (!allowedTypes.includes(file?.type)) {
      const typeNames = allowedTypes.map((type) => type.split('/')[1].toUpperCase());
      const allowedFileTypesString = `Only ${typeNames.join(', ')} files are allowed.`;
      notification.error({
        message: 'Invalid File Type',
        description: allowedFileTypesString,
      });
      return false;
    }
    return true;
  };
  const handleChange = ({ fileList }: { fileList: any }) => {
    if (!allowedTypes.includes(fileList[0]?.type)) {
      return
    }
    setFileList(fileList);
  };
  const checkCertificateName = () => ConsiderCertificateName && !certificateName;

  return (
    <>
      <Upload
        disabled={checkCertificateName()}
        listType='picture-card'
        fileList={fileList}
        onPreview={(e) => handlePreview(e, setPreviewImage, setPreviewOpen, setPreviewTitle, pdfUrlShow)}
        // onChange={(e) => handleChange(e, maxFileSize, setFileSizeExceeded, allowedTypes, setFileList, onImageUpload)}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        onRemove={handleRemove}
        customRequest={customRequest}
        // onRemove={handleRemove} 
        className='image-upload-elms'
      >
        {fileList.length >= 1 ? null : (
          <div className='image-upload flex-col  flex justify-center items-center flex-wrap p-4'>
            <div className='upload-document-image'>
              <p className='flex items-center justify-center'><span className="material-symbols-outlined">
                upload
              </span></p>
              <p className='ml-2'> Upload Files</p>
            </div>
            <p> Only {`${allowedTypes.map(type => type.split('/')[1].toUpperCase())}`} files. {`${maxFileSize / 1048576}`} MB max file size</p>
          </div>
        )}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel} maskClosable={false}>
        {previewImage.endsWith('.pdf') ? (
          <DocumentPDFView currentPdfDetails={{ documentPath: pdfUrlShow }} certificate={false} mainPage={true} />
        ) : (
          <img alt='example' style={{ width: '100%' }} src={previewImage} />
        )}
      </Modal>
      {fileSizeExceeded && (
        <div className="file-size-warning">
          <p className='error-message'>File size exceeds the maximum allowed size of {maxFileSize / (1024 * 1024)} MB.</p>
        </div>
      )}
    </>
  )
}

export default ImageUploadElms