import React, { useEffect, useState } from 'react';
import AddEmployeeModal from '../AddEmployeeModal/AddEmployeeModal';
import { useNavigate } from '../../../common/CommonExport';
import { Button, Dropdown } from 'antd';
import { useSelector } from 'react-redux';
import type { MenuProps } from 'antd';
import AddAttenanceModal from '../../LeaveManagement/attenance/AddAttenanceModal';
import AddEmployeePopup from '../new-add-employee/AddEmployeePopup';
import { RolePermission } from './indexTypes';
import UserButton from './UserButton';

export default function DashboardHeader({ dashBoardCall }: any) {
    const rolePermissions = useSelector((state: any) => state?.rolePermissions?.rolePermissions);
    const [isOpen, setIsOpen] = useState(false);
    const reduxToken = useSelector((state: any) => state?.employeeTable?.data);
    const [expand, setExpand] = useState<boolean>(false);
    const [bulkUpload, setBulkUpload] = useState<boolean>(false);
    const [bulk_Upload, setBulk_Upload] = useState<any>('');
    const [sendMail, setSendMail] = useState<boolean>(false)
    const [dirty, setDirty] = useState(false);
    useEffect(() => {
        setExpand(reduxToken)
        setBulk_Upload("employee_bulk_upload")
    }, [reduxToken])
    const navigate = useNavigate();
    const handleClose = () => {
        setIsOpen(false);
        if (dirty) {
            dashBoardCall()
        }
    };
    const handelDesignationPopUp = () => {
        // setIsOpen(true);
        setSendMail(true)

    };
    const handelViewProjects = () => {
        navigate('/home/project-list');
    };
    const handelBulkUpload = () => {
        setBulkUpload(true)
    }
    const handleClosed = () => {
        setBulkUpload(false)
    }
    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <Button onClick={() => handelDesignationPopUp()} className='border-none font-bold '>
                    Add Employee
                </Button>
            ),
        },
        {
            key: '1',
            label: (
                <Button onClick={() => handelBulkUpload()} className='border-none font-bold '  >
                    Bulk Upload
                </Button>
            ),
        },
    ];
    const handelCancel = (isLoading: boolean = false) => {
        setSendMail(false)
        dashBoardCall()
    }
    const closeEmployeeModal = (isLoading: boolean = false) => {
        setSendMail(false)
    }
    const userPermission = rolePermissions?.find((permission: RolePermission) => {
        return permission?.component?.name === "User" && permission?.isCreate === true;
    });

    return (
        <div className='container mx-auto max-w-full md:px-14 px-4'>
            <div className='flex justify-between items-center pt-4 '>
                <h3 className='text-primary font-medium'>Dashboard</h3>
                <div className='flex md:justify-end md:mt-0 mt-3'>
                    {!expand &&
                        <Button className=' md:mr-4 mr-2 ' type="primary" shape="round" size={"large"} onClick={() => handelViewProjects()}>
                            View Projects
                        </Button>
                    }

                    {
                        userPermission?.isCreate && <Dropdown menu={{ items }} placement="bottomLeft" >
                            <Button type="primary" data-testid="addbutton" shape="circle" size={'large'} className='bulk-upload_dropdown'>
                                <span className="material-symbols-outlined">
                                    add
                                </span>
                            </Button>
                        </Dropdown>
                    }

                </div>

                {isOpen && <AddEmployeeModal isOpen={isOpen} handleClose={handleClose} handelDesignationPopUp={handelDesignationPopUp} setIsOpen={setIsOpen} />}
                {bulkUpload && <AddAttenanceModal open={bulkUpload} handleClose={handleClosed} bulk={bulk_Upload} />}
                {sendMail && <AddEmployeePopup open={sendMail} closeEmployeeModal={closeEmployeeModal} handelCancel={handelCancel} setDirty={setDirty} dashBoardCall={dashBoardCall} />}

            </div>
        </div>
    );
}
