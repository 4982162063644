import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface AuthState {
  profileLoad: boolean,
  historyLoad: boolean
  documentReload: boolean
  bonusReload: boolean
  leavePolicyReload: boolean
  imagePreviewRemove: boolean,
  chapterLoad: boolean,
  certificateLoad: boolean,
  courseListData: boolean,
  deprovisionLoad: boolean,
  certificateGeneratedReload: boolean
  appraisalPageNavigate: boolean,
  reLoader: boolean,

}

const initialState: AuthState = {
  profileLoad: false,
  historyLoad: false,
  documentReload: false,
  bonusReload: false,
  leavePolicyReload: false,
  imagePreviewRemove: false,
  chapterLoad: false,
  certificateLoad: false,
  courseListData: false,
  deprovisionLoad: false,
  certificateGeneratedReload: false,
  appraisalPageNavigate: false,
  reLoader: false,
};

const profileLoadSlice = createSlice({
  name: 'profileReload',
  initialState,
  reducers: {
    setProfileLoad: (state) => {
      state.profileLoad = !state.profileLoad;
    },
    setHistoryLoad: (state) => {
      state.historyLoad = !state.historyLoad;
    },
    setDocumentLoad: (state) => {
      state.documentReload = !state.documentReload;
    },
    setBonusLoad: (state) => {
      state.bonusReload = !state.bonusReload;
    },
    setLeavePolicyReload: (state) => {
      state.leavePolicyReload = !state.leavePolicyReload;
    },
    setImagePreviewRemove: (state) => {
      state.imagePreviewRemove = !state.imagePreviewRemove;
    },
    setChapterLoad: (state) => {
      state.chapterLoad = !state.chapterLoad;
    },
    setCertificateLoad: (state) => {
      state.certificateLoad = !state.certificateLoad;
    },
    setCourseListData: (state, action: PayloadAction<boolean>) => {
      state.courseListData = action.payload;
    },
    setDeprovision: (state, action: PayloadAction<boolean>) => {
      state.deprovisionLoad = action.payload;
    },
    setReloader: (state, action: PayloadAction<boolean>) => {
      state.reLoader = action.payload;
    },
    setCertificateGeneratedReload: (state, action: PayloadAction<boolean>) => {
      state.certificateGeneratedReload = action.payload;},
    setAppraisalTab: (state, action: PayloadAction<boolean>) => {
      state.appraisalPageNavigate = action.payload;
    },
  },
});

export const { setProfileLoad, setHistoryLoad, setDocumentLoad,
  setBonusLoad, setLeavePolicyReload, setImagePreviewRemove, setChapterLoad, setCertificateLoad, setCourseListData, setDeprovision,
   setCertificateGeneratedReload, setAppraisalTab } = profileLoadSlice.actions;
export default profileLoadSlice.reducer;
