import React, { useEffect, useState } from 'react'
import LearningPathAccordion from './LearningPathAccordion'
import './LearningPath.scss'
import LearningPathLeft from './learningPathRight'
import { Input, Switch, Tooltip } from 'antd';
import { triggerNotification, useGetApiRequests } from '../../common/CommonExport';
import Loader from '../../components/Loader/Loader';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
export default function NewLearningPath() {
  const { search } = useLocation();
  const elmsPathNameApi = useGetApiRequests('elmsPathName', 'POST');
  const learningPathCreation = useGetApiRequests('createNewLearningPath', 'POST');
  const getAllcertificateList = useGetApiRequests('getAllCertificate', 'GET');
  const getLearningPathApi = useGetApiRequests('getLearningPath', 'GET');
  const editLearningPathApi = useGetApiRequests('editLearningPathName', 'PUT');
  const editLearningPathLevelApi = useGetApiRequests('editLearningPathLevel', 'PUT');
  const navigate = useNavigate();
  const learningPathId: any = new URLSearchParams(search).get("learningPathId");
  const isEdit: any = new URLSearchParams(search).get("isEdit") === 'true';
  const [name, setName] = useState('');
  const [isInputActive, setIsInputActive] = useState(false);
  const [loader, setLoader] = useState(false);
  const [courseCompletionCertificate, setCourseCompletionCertificate] = useState(false)
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const [certificateList, setCertificateList] = useState([]);
  const [mainArray, setMainArray] = useState<any>([])
  const [elmsPathName, setElmsPathName] = useState<any>();
  const [certificateId, setCertificateId] = useState(0);
  const [selectedLevels, setSelectedLevels] = useState<any>([]);
  const handleGetAllCertificate = async () => {
    setLoader(true)
    try {
      const response: any = await getAllcertificateList('', { organisationId: orgId })
      setLoader(false)
      if (response?.data?.status === 200) {
        const courseCertificate = response?.data?.data;
        const learningLevelCO: any = courseCertificate.find((item: any) => item.certificateType === 'LEARNING_PATH_LEVEL_CO');
        setCertificateId(learningLevelCO?.certificateId)
        setCertificateList(courseCertificate)
      }
    } catch (err: any) {
      setLoader(false);
      triggerNotification('error', '', err?.response?.data?.data, 'topRight');
    }
  };
  useEffect(() => {
    handleGetAllCertificate()
    if (isEdit) getLearningPath()
  }, [])
  const getLearningPath = async () => {
    const pathParams: any = {
      id: parseInt(learningPathId)
    }
    setLoader(true)
    try {
      const response: any = await getLearningPathApi('', {}, pathParams)
      if (response?.data) {
        const finalResponse = response?.data?.data;
        setName(finalResponse?.[0]?.learningPath?.pathName);
        setElmsPathName(finalResponse?.[0]?.learningPath)
        setCourseCompletionCertificate(finalResponse?.[0]?.learningPath?.isPathCertificateRequired)
        setMainArray(finalResponse);
        setLoader(false)
      }
    } catch (err: any) {
      setLoader(false);
    }
  }
  const handleInputFocus = () => {
    setIsInputActive(true);
  };
  const handleInputBlur = () => {
    if (name === '')
      setIsInputActive(false);
  };

  const handleClose = () => {
    setIsInputActive(false);
  }
  const handleLearningPathName = (e: any) => {
    setName(e.target.value)
  }
  const onChangeSwitch = (checked: boolean) => {
    setCourseCompletionCertificate(checked);
    setIsInputActive(true);

  };
  const handleSubmit = () => {
    const payload: any = {
      "pathName": name,
      "isPathCertificateRequired": !!courseCompletionCertificate,
      "organisationId": orgId,
      "status": "draft",
    }
    if (courseCompletionCertificate) {
      const learningPathCO: any = certificateList.find((item: any) => item.certificateType === 'LEARNING_PATH_CO');
      payload.certificate = { certificateId: learningPathCO?.certificateId }
    }
    if (name !== '' && name !== undefined && !isEdit) {
      // setLoader(true);
      elmsPathNameApi(payload).then((res: any) => {
        if (res?.data?.data !== null) {
          setElmsPathName(res?.data?.data)
          setLoader(false);
          triggerNotification('success', '', res?.data?.message, 'topRight');
          handleClose();
        } else {
          setLoader(false);
          triggerNotification('success', '', res?.data?.message, 'topRight');
        }
      })
        .catch((err: any) => {
          setLoader(false);
          triggerNotification('error', '', err?.response?.data?.data, 'topRight');
        })
    } else if (name !== '' && name !== undefined && isEdit) {
      // setLoader(true);
      payload.learningPathId = learningPathId;
      const pathParams: any = {
        id: learningPathId
      }
      editLearningPathApi(payload, {}, pathParams).then((res: any) => {
        if (res?.data?.data !== null) {
          setElmsPathName(res?.data?.data)
          setLoader(false);
          triggerNotification('success', '', res?.data?.message, 'topRight');
          handleClose();
        } else {
          setLoader(false);
          triggerNotification('success', '', res?.data?.message, 'topRight');
        }
      })
        .catch((err: any) => {
          setLoader(false);
          triggerNotification('error', '', err?.response?.data?.data, 'topRight');
        })
    } else {
      triggerNotification('warning', '', 'Learning Pathname is required', 'topRight');
    }

  }
  const handleSave = () => {
    setLoader(true);
    const modifiedArray = mainArray.map((item: any) => {
      const modifiedCourses = item?.learningPathLevelCourse.map((item: any) => {
        const newItem: any = {
          course: {
            courseId: item?.course?.courseId
          },
          courseHierarchy: item?.courseHierarchy
        };

        if (item.learningPathLevelCourseId !== undefined) {
          newItem.learningPathLevelCourseId = item?.learningPathLevelCourseId;
        }

        return newItem;
      });
      return {
        ...item,
        learningPathLevelCourse: modifiedCourses
      };
    });
    if (!isEdit) {
      learningPathCreation(modifiedArray).then((res: any) => {
        if (res?.data?.data !== null) {
          setLoader(false);
          triggerNotification('success', '', res?.data?.message, 'topRight');
          handleClose();
          handleNavigate('/dashboard/learning-management?from=learningPathLevel')
        } else {
          setLoader(false);
          triggerNotification('error', '', res?.data?.message, 'topRight');
        }
      })
        .catch((err: any) => {
          setLoader(false);
          triggerNotification('error', '', err?.response?.data?.message, 'topRight');
        })
    } else {
      const pathParams: any = {
        id: parseInt(learningPathId)
      }
      editLearningPathLevelApi(modifiedArray, {}, pathParams).then((res: any) => {
        if (res?.data?.data !== null) {
          setLoader(false);
          triggerNotification('success', '', res?.data?.message, 'topRight');
          handleClose();
          handleNavigate('/dashboard/learning-management?from=learningPathLevel')
        } else {
          setLoader(false);
          triggerNotification('error', '', res?.data?.message, 'topRight');
        }
      })
        .catch((err: any) => {
          setLoader(false);
          triggerNotification('error', '', err?.response?.data?.message, 'topRight');
        })
    }
  }
  const deleteSelected = () => {
    const storeMainArray: any = mainArray.filter((_: any, idx: any) => !selectedLevels.includes(idx));
    setMainArray(storeMainArray)
    setSelectedLevels([]);
  }
  const handleNavigate = (pageName: string) => {
    navigate(`/home${pageName}`)
  }
  return (
    <div className='w-full flex bg-customBg px-[3.5rem] '>
      <div className='newLearningPath page-container h-full w-[30%] mr-3'>
        <div className="bredcum-container pt-5 ">
          <ul className="breadcrumb breadcrumb--classic">
            <li className='pl-0 text-[12px] 2xl:text-[15px]' onClick={() => handleNavigate('/dashboard')}> Dashboard</li>
            <li className=' text-[12px] 2xl:text-[15px]' onClick={() => handleNavigate('/dashboard/learning-management?from=learningPathLevel')}>Learning management</li>
            <li className='text-[12px] 2xl:text-[15px] pl-1 text-[#ff4081] font-normal active'>New Learning Path</li>
          </ul>
        </div>
        <div className='  mt-5'>
          <div className='lm-tab bg-white px-5 pt-0'>
            <div className='flex w-full'>
              <LearningPathLeft mainArray={mainArray} />
            </div>
          </div>
        </div>
      </div>
      <div className='newLearningPath  bg-customBg h-full w-[70%] pt-[110px] ml-3'>
        <div className='mt-5'>
          <div className='lm-tab bg-white p-5 level-tab overflow-y-auto'>
            <div className='flex w-full'>
              <div className='flex mb-4 '>
                <Input className='h-[40px]' placeholder='Enter Learning Path Name *' value={name} type='text' onChange={(e: any) => handleLearningPathName(e)} onFocus={handleInputFocus}
                  onBlur={handleInputBlur} />
              </div>
              <div className="flex pl-10 pt-[5px]">
                <Switch className="mb-2" checked={courseCompletionCertificate} onChange={onChangeSwitch} />
                <h6 className="px-2 text-[#737373] text-[18px]">Enable Learning path Completion Certificate</h6>
              </div>
              {isInputActive ? (
                <span className='flex pt-[5px] pl-10'>
                  <span className="material-symbols-outlined cursor-pointer" onClick={handleSubmit}>done</span>
                  <span className="material-symbols-outlined cursor-pointer" onClick={handleClose}>close</span>
                </span>) : null}
              {selectedLevels.length > 0 && (
                <Tooltip title='Delete Level'>
                  <button className="ml-44 flex w-[170px] h-[35px] bg-[#D34A7C] text-[#ffffff] justify-center items-center rounded-lg" onClick={deleteSelected}>
                    Delete Level
                  </button>
                </Tooltip>)}
            </div>
            <div className='flex w-full flex-col'>
              <div><LearningPathAccordion mainArray={mainArray} setMainArray={setMainArray} elmsPathDetails={elmsPathName} certificateId={certificateId} pathName={name} setSelectedLevels={setSelectedLevels} selectedLevels={selectedLevels} /></div>
            </div>
          </div>
        </div>
        <div className="flex w-full justify-end pt-5">
          <button type='submit' className='addButton mr-4 primary px-5 cancelElms' onClick={() => handleNavigate('/dashboard/learning-management?from=learningPathLevel')}>Back</button>
          <button type='submit' className='addButton mr-4 primary px-5 confirmElms' onClick={handleSave}>{isEdit ? 'Update' : 'Save'}</button>
        </div>
      </div>
      {loader && <Loader />}
    </div >
  )
}