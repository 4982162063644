import React from 'react';
import Starred from './Starred';
import MailListContent from './MailListContent';
import MailSelect from './MailSelect';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, triggerNotification } from '../../../../common/CommonExport';
import { setAnnouncementlist } from '../../../../redux/slices/announcementSlice';
import MailAction from './MailAction';
import moment from 'moment';

export default function MailItem({ object, status, markAll, setLoader, loader }: any) {

  const location = useLocation(); // Get the current location (URL)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRedirectToSingleAnnouncement = () => {
    try {
      dispatch(setAnnouncementlist(object)); // Dispatch action
      const statusFromURL = location.pathname.split('/').pop(); // Extract status from URL
      if (!statusFromURL) {
        throw new Error("Failed to extract status from URL."); // Custom error for invalid URL
      }
      navigate(`/home/announcements/${statusFromURL}/single-announcements`); // Navigate to the target route
    } catch (error) {
      const errorMessage = 'An error occurred while redirecting to the single announcement page';
      triggerNotification('error', '', errorMessage, 'topRight');
    }
  };

  const adjustTime = (time: string): Date | null => {
    try {
      const cleanTime = time.split('.')[0]; // Get only the date-time part (before the dot)
      const date = new Date(cleanTime);

      if (isNaN(date.getTime())) {
        throw new Error("Invalid date format");
      }

      date.setHours(date.getHours() + 5); // Add 5 hours
      date.setMinutes(date.getMinutes() + 30); // Add 30 minutes
      return date; // Return the updated Date object
    } catch (error) {
      triggerNotification('error', '', 'An error occurred while adjusting the time', 'topRight');
      return null; // Return null if there's an error
    }
  };

  const nonAdjustTime = (time: string): Date | null => {
    try {
      const cleanTime = time.split('.')[0]; // Get only the date-time part (before the dot)
      const date = new Date(cleanTime);

      if (isNaN(date.getTime())) {
        throw new Error("Invalid date format");
      }

      return date; // Return the updated Date object
    } catch (error) {
      triggerNotification('error', '', 'An error occurred while parsing the time', 'topRight');
      return null; // Return null if there's an error
    }
  };

  const formatDate = (date: Date): string => {
    try {
      if (!(date instanceof Date) || isNaN(date.getTime())) {
        throw new Error("Invalid Date object");
      }

      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      const isToday =
        date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate();

      const isYesterday =
        date.getFullYear() === yesterday.getFullYear() &&
        date.getMonth() === yesterday.getMonth() &&
        date.getDate() === yesterday.getDate();

      let hours = date.getHours();
      const minutes = ("0" + date.getMinutes()).slice(-2); // Add leading zero if minutes < 10
      const ampm = hours >= 12 ? "PM" : "AM";

      hours = hours % 12;
      hours = hours ? hours : 12; // Handle 0 hour as 12

      const timeString = `${hours}:${minutes} ${ampm}`; // Format time

      if (isToday) return `Today ${timeString}`;
      if (isYesterday) return `Yesterday ${timeString}`;

      // Fallback to standard formatted date
      const day = ("0" + date.getDate()).slice(-2); // Add leading zero if day < 10
      const month = ("0" + (date.getMonth() + 1)).slice(-2); // Add leading zero if month < 10
      const year = date.getFullYear();

      return `${day}-${month}-${year} ${timeString}`;
    } catch (error) {
      triggerNotification('error', '', 'An error occurred while formatting the date', 'topRight');
      return ''; // Return a fallback value in case of an error
    }
  };


  const updatedDateTime = (status: string, time: string): string => {
    try {
      const updatedTime = status === 'SCHEDULED' ? adjustTime(time) : adjustTime(time);

      // Check if the updatedTime is valid
      if (updatedTime === null || isNaN(updatedTime.getTime())) {
        triggerNotification('error', '', 'Invalid date/time format', 'topRight');
        return ''; // Return a fallback value (empty string, or you can choose another fallback)
      }

      return formatDate(updatedTime); // Proceed with formatting if the date is valid
    } catch (error) {
      triggerNotification('error', '', 'An error occurred while updating the date/time', 'topRight');
      return ''; // Return a fallback value in case of an error
    }
  };

  const displayTime = status === 'SCHEDULED' ? updatedDateTime(status, object.scheduledTime) : updatedDateTime(status, object.updatedOn);


  return (
    <div className="flex items-center mb-4 w-full">
      <div className="mail-select mr-3">
        <MailSelect data={object} status={status} markAll={markAll} />
      </div>

      <div className="mail-item flex items-center justify-between w-full" onClick={handleRedirectToSingleAnnouncement}>
        <div className="mail-starred">
          <div onClick={(e) => {
            e.stopPropagation();
          }}> <Starred data={object} setLoader={setLoader} loader={loader} /></div>
        </div>
        <div className="mail-list--content flex justify-between">
          <div className="w-full">
            <MailListContent data={object} />
          </div>
          <div className="mail-action pl-[20px]">
            <div className="mail-action--container">
              <div className="all-announcement--date">
                <div className="flex flex-row-reverse">
                  <MailAction data={object} />
                </div>

                <div className="flex">
                  <div className="pr-[3px]">
                    <p className='text-[#434343]'>
                      {displayTime}
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
